import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// GET All dispatches by processed_at, All DTRACK by processed_at
import {
    getAllDispatchesByProcessedDate,
    getAllDTRACKdataByProcessedDate,
} from "../../actions/ordersActions";

const DispatchesTracker = () => {
    const auth = useSelector((state) => state.auth);
    const [dispatches, setDispatches] = useState([]);
    const [dispatchTrackingData, setDispatchTrackingData] = useState([]);
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        if (!auth) {
            return;
        }
        if (orders.length === 0) {
            // FETCH ALL BY DEFAULT TODAY
            const todayDate = new Date().toISOString().split("T")[0];
            getDataFromDB(todayDate);
        }
    }, [auth]);

    useEffect(() => {
        // MAKE ORDERS DATA
        setOrders([]);
    }, [dispatches, dispatchTrackingData]);

    //FETCH function
    const getDataFromDB = async (date) => {
        let dispatchedOrders = [];
        let trackingData = [];
        const res = await getAllDispatchesByProcessedDate(date);
        if (res.statusCode === 200) {
            dispatchedOrders = res.body;
        }
        const res2 = await getAllDTRACKdataByProcessedDate(date);
        if (res2.statusCode === 200) {
            trackingData = res2.body;
        }
        const merged = dispatchedOrders.reduce((acc, i) => {
            const tracking = trackingData.find(
                (t) => t.fulfillment_id === i.fulfillment_id
            );
            if (!tracking) {
                console.log("No tracking found for ", i.fulfillment_id);
                acc.push(i);
            } else {
                const mergedOrder = {
                    ...i,
                    lifecycle: tracking,
                };
                acc.push(mergedOrder);
            }
            return acc;
        }, []);
        console.log("Today's data: ", date, merged);
        setOrders(merged);
    };

    return (
        <div>
            DispatchesTracker
            <p>Total: {orders?.length}</p>
        </div>
    );
};

export default DispatchesTracker;
