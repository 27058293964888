import { lambdaFetch } from "../utils/awsUtils";
import { hideFullScreenLoader } from "./loaderActions";
import {
  updateMoveToGiftcard,
  updateMoveToReplacement,
  updateMoveToVoucher,
  updateReverseOrder,
} from "./reverseDeliveryActions";

const productImageList = (data) => {
  return {
    type: "SHOPIFY_IMAGE_LIST",
    data,
  };
};

const productInventoryList = (data) => {
  return {
    type: "SHOPIFY_PRODUCT_VARIANTS",
    data,
  };
};

const productVariantList = (data) => {
  return {
    type: "PRODUCT_VARIANTS",
    data,
  };
};
export const allProducts = () => {
  console.log("Called, All Products");
  const getAllProducts = async () => {
    const URL =
      "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/shopifyproducts/products";
    let fetching = true;
    let products = [];
    let nextUrl = null;
    while (fetching) {
      try {
        const response = await lambdaFetch(URL, {
          method: "POST",
          body: JSON.stringify({
            currentUrl: nextUrl,
            pageLength: 250,
          }),
        });
        const data = await response.json();
        if (data.statusCode === 200) {
          products.push(...data?.body);
          nextUrl = data.next;
          if (!nextUrl) {
            fetching = false;
          }
        } else {
          products = [];
          fetching = false;
        }
      } catch (error) {
        // TODO: HANDLE THIS
        console.log(error);
      }
    }
    return products;
  };
  return (dispatch) => {
    getAllProducts()
      .then((data) => {
        if (data.length > 0) {
          dispatch({
            type: "ALL_PRODUCTS",
            data,
          });
          const imagesData = data
            .map((p) => ({ image: p.image, type: p.product_type }))
            .filter((item) => item.image !== null)
            .map((item) => ({
              id: item.image.product_id,
              src: item.image.src,
              type: item.type,
            }));
          let variantArr = data
            // .filter((x) => x.status !== "draft")
            .reduce(
              (arr, i) => [
                ...arr,
                ...i.variants.map((v) => ({
                  ...v,
                  tags: i.tags,
                })),
              ],
              []
            );
          const imageVariants = data
            .filter((x) => x.status !== "draft")
            .map((p) => variantArr.push(...p.variants));
          dispatch(productImageList(imagesData));
          console.log("variant arr", variantArr);
          dispatch(productInventoryList(variantArr));
          dispatch(productVariantList(variantArr));
        } else {
          alert("Shopify Products Not Found");
        }
      })
      .catch((error) => {
        // dispatch(showModal());
        console.log(error);
      });
  };
};

export const createSingleOrderShopify = (orderBody, id) => async (dispatch) => {
  const URL =
    "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/orders/createshopifyorder";
  try {
    const response = await lambdaFetch(URL, {
      method: "POST",
      body: JSON.stringify(orderBody),
    });
    const data = await response.json();
    if (data.statusCode === 200) {
      const responseData = data.data;
      const objBody = {
        id: id,
        fulfillment_id: responseData.order_number,
        orderId: responseData.id,
      };
      dispatch(updateReverseOrder(objBody));
      console.log("Order Created In Shopify Successfully");
    } else {
      alert("Error in Creating New Order");
      dispatch(hideFullScreenLoader());
    }
  } catch (err) {
    console.log(err);
    dispatch(hideFullScreenLoader());
  }
};

export const createSingleDraftOrderShopify =
  (orderBody, id) => async (dispatch) => {
    const URL =
      "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/ShopifycreateDraftOrder";
    try {
      const response = await lambdaFetch(URL, {
        method: "POST",

        body: JSON.stringify(orderBody),
      });
      const data = await response.json();
      if (data.statusCode === 200) {
        const responseData = data.data;
        const objBody = {
          id: id,
          fulfillment_id: responseData.name,
          orderId: responseData.id,
          draft: true,
        };
        dispatch(updateReverseOrder(objBody));
        console.log("Draft Order Created In Shopify Successfully");
      } else {
        alert("Error in Creating New Draft Order");
        console.log(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

export const sendDraftOrderInvoice = (orderBody) => async (dispatch) => {
  const URL =
    "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/sendDraftOrderInvoice";
  try {
    const response = await lambdaFetch(URL, {
      method: "POST",
      body: JSON.stringify(orderBody),
    });
    const data = await response.json();
    if (data.statusCode === 200) {
      console.log("Draft Order Invoice Sent Successfully");
      dispatch(hideFullScreenLoader());
    } else {
      alert("Error in Sending Draft Order Invoice");
      console.log(data);
      dispatch(hideFullScreenLoader());
    }
  } catch (err) {
    console.log(err);
    dispatch(hideFullScreenLoader());
  }
};

export const convertDraftToActiveOrderShopify =
  (orderBody, id) => async (dispatch) => {
    const URL =
      "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/convertDraftOrderActive";
    try {
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify(orderBody),
      });
      const data = await response.json();
      // console.log(data)
      if (data.statusCode === 200) {
        const responseData = data.body;
        const objBody = {
          id: id,
          fulfillment_id: responseData.customer.last_order_name.replace(
            "#",
            ""
          ),
          orderId: responseData.customer.last_order_id,
        };
        dispatch(updateReverseOrder(objBody));
        alert("Order Created In Shopify Successfully");
      } else {
        alert("Error in Creating New Order");
        console.log(data);
        dispatch(hideFullScreenLoader());
      }
    } catch (err) {
      console.log(err);
      dispatch(hideFullScreenLoader());
    }
  };

export const createExchangeProductsAndOrderShopify =
  (products, orderBody, id) => async (dispatch) => {
    const URL =
      "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/orders/createshopifyorder";
    const CREATE_PRODUCT_URL =
      "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/exchange/createexchangeproducts";
    try {
      const createExchangeProductresponse = await lambdaFetch(
        CREATE_PRODUCT_URL,
        {
          method: "POST",
          body: JSON.stringify({
            Action: "create_exchange_product",
            products,
            fulfillment_id: id,
          }),
        }
      );
      const exchangeData = await createExchangeProductresponse.json();
      console.log("exchangeData", exchangeData);
      if (exchangeData.statusCode !== 200) {
        alert("Error in Creating New Order");
        dispatch(hideFullScreenLoader());
      }
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify(orderBody),
      });
      const data = await response.json();
      if (data.statusCode === 200) {
        const responseData = data.data;
        const objBody = {
          id: id,
          fulfillment_id: responseData.order_number,
          orderId: responseData.id,
        };
        dispatch(updateReverseOrder(objBody));
        console.log("Order Created In Shopify Successfully");
      } else {
        alert("Error in Creating New Order");
        dispatch(hideFullScreenLoader());
      }
    } catch (err) {
      console.log(err);
      dispatch(hideFullScreenLoader());
    }
  };

export const changeReplacementToVoucher = (id) => async (dispatch) => {
  const URL =
    "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/exchange/createexchangeproducts";
  try {
    const response = await lambdaFetch(URL, {
      method: "POST",
      body: JSON.stringify({
        Action: "move_to_giftcard",
        fulfillment_id: id,
      }),
    });
    const data = await response.json();
    if (data.statusCode === 200) {
      dispatch(updateMoveToVoucher(id));
      console.log("Moved to voucher Successfully");
    } else {
      alert("Error in moving to voucher");
      dispatch(hideFullScreenLoader());
    }
  } catch (err) {
    console.log(err);
    dispatch(hideFullScreenLoader());
  }
};

export const changeReplacementToGiftcard = (id) => async (dispatch) => {
  console.log("working");
  const URL =
    "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/exchange/createexchangeproducts";
  try {
    const response = await lambdaFetch(URL, {
      method: "POST",
      body: JSON.stringify({
        Action: "replacement_to_voucher",
        fulfillment_id: id,
      }),
    });
    const data = await response.json();
    console.log(data);
    if (data.statusCode === 200) {
      dispatch(updateMoveToGiftcard(id));
      console.log("Moved to Giftcard Successfully");
    } else {
      alert("Error in moving to voucher");
      dispatch(hideFullScreenLoader());
    }
  } catch (err) {
    console.log(err);
    dispatch(hideFullScreenLoader());
  }
};

export const changeGiftcardToReplacement = (id) => async (dispatch) => {
  const URL =
    "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/exchange/createexchangeproducts";
  try {
    const response = await lambdaFetch(URL, {
      method: "POST",
      body: JSON.stringify({
        Action: "voucher_to_replacement",
        fulfillment_id: id,
      }),
    });
    const data = await response.json();
    if (data.statusCode === 200) {
      dispatch(updateMoveToReplacement(id));
      console.log("Moved to Replacement Successfully");
    } else {
      alert("Error in moving to voucher");
      dispatch(hideFullScreenLoader());
    }
  } catch (err) {
    console.log(err);
    dispatch(hideFullScreenLoader());
  }
};
