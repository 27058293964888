import { Box, Stack, Typography } from "@mui/material";
import React from "react";

const OrderReport = ({ orders }) => {
  return (
    <Stack my={2} direction={"row"} gap={2} sx={{ justifyContent: "center" }}>
      <Box
        py={1}
        px={3}
        style={{
          background: "#c2e7c7",
          width: "max-content",
          borderRadius: "15px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Total Orders Dispatched</Typography>
        <Typography style={{ color: "black" }} variant="h5">
          {orders.length}
        </Typography>
      </Box>
      <Box
        py={1}
        px={3}
        style={{
          background: "#f8d7da",
          width: "max-content",
          borderRadius: "15px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Orders Not Yet Processed </Typography>
        <Typography style={{ color: "black" }} variant="h5">
          {orders.filter((o) => !o.isProcessed).length}
        </Typography>
      </Box>
      <Box
        py={1}
        px={3}
        style={{
          background: "#f8d7da",
          width: "max-content",
          borderRadius: "15px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">PNR </Typography>
        <Typography style={{ color: "black" }} variant="h5">
          {orders.filter((o) => o.package_status === "PNR_CANCELLED").length}
        </Typography>
      </Box>
      <Box
        py={1}
        px={3}
        style={{
          background: "#f8d7da",
          width: "max-content",
          borderRadius: "15px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Reverted </Typography>
        <Typography style={{ color: "black" }} variant="h5">
          {orders.filter((o) => o.package_status === "R_CANCELLED").length}
        </Typography>
      </Box>
    </Stack>
  );
};

export default OrderReport;
