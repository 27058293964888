import React, { useEffect, useState } from 'react'
import Filter from "./Filter"
import InventoryTable from './InventoryTable'

// Create DispatchedOrder LineItems || check per order per line_items
const createDispatchedOrderLineItems = (acc, row) => {
  const orderItems = row.order_items
  orderItems.forEach((order_item) => {
    //per order item , sku , variant_title  and units
    const sku = order_item.sku.split(".")[0]

    const found = acc.find((ac) => acc.sku === sku && acc.size === order_item.variant_title)
    if (found) {
      found.count = found.count + order_item.units
    } else {
      const newObj = {
        sku,
        size: order_item.variant_title,
        count: order_item.units
      }
      acc.push(newObj)
    }
  })
  return acc
}


//// Create reconOrder LineItems || check per order per line_items
const createReconLineItems = (acc, row) => {
  const orderItems = row.line_items
  orderItems.forEach((order_item) => {
    //per order item , sku , variant_title  and units
    const [sku, variant_title] = order_item.sku.split(".") 

    const found = acc.find((ac) => acc.sku === sku && acc.size === variant_title)
    if (found) {
      found.count = found.count + order_item.units
    } else {
      const newObj = {
        sku,
        size:  variant_title,
        count: order_item.units
      }
      acc.push(newObj)
    }
  })
  return acc
}

//check if per order per line_items is damaged
const createReconDamagedLineItems = (acc, row) => {
  const orderItems = row.line_items
  orderItems.forEach((order_item) => {
    if ("isDamaged" in order_item) {
      //per order item , sku , variant_title  and units
      const [sku, variant_title] = order_item.sku.split(".")

      const found = acc.find((ac) => acc.sku === sku && acc.size === variant_title)
      if (found) {
        found.count = found.count + order_item.units
      } else {
        const newObj = {
          sku,
          size: variant_title,
          count: order_item.units
        }
        acc.push(newObj)
      }
    }

  })
  return acc
}

const InventoryManagement = ({
  inventory,
  dispatchedOrders,
  reconOrders,
  openingStock,
  poStockHistory,
  stockHistory
}) => {
  const [inventoryManagementDetails, setInventoryManagementDetails] = useState([])
  const [filteredData, setFilteredData] = useState([])
  useEffect(() => {
    //dispatched order line items 
    const dispatchedOrderItems = dispatchedOrders.filter((o) => true).reduce(createDispatchedOrderLineItems, [])
    //recon rto line items
    const reconOrderItemsForRTO = reconOrders.filter((o) => o.processed_at && o.type === "RTO").reduce(createReconLineItems, [])

    console.log("reconOrderItemsForRTO", reconOrders.filter((o) => o.processed_at && o.type === "RTO"))
    //recon R/E line items
    const reconOrderItemsForRE = reconOrders.filter((o) => o.processed_at && o.type === "R/E").reduce(createReconLineItems, [])
    // po
    // const poHistoryItems = dispatchedOrders.filter((o) => true).reduce(createPoHistoryItems, [])
    //recon damaged line items
    const damagedReconOrderItems = reconOrders.filter((o) => o.processed_at && o.actionDone).reduce(createReconDamagedLineItems, [])
    // inventory with all details (opening , dispatched , rto , r/e , po)
    const inventoryWithDetails = inventory.filter(o => "SKU" in o && "count" in o && "size" in o).map((row) => {
      const sku = row.SKU
      const inventoryAmt = row.count
      const size = row.size
      //dispatch order amt for this sku and size
      const dispatchedAmt = dispatchedOrderItems.find(o => o.sku === sku && o.size === size)?.count || 0
      // po
      const poAmt = poStockHistory.find(o => o.barcode === row.barcode )?.count || 0
        // stock added today amt
      const stockAdded = stockHistory.find(o => o.barcode === row.barcode )?.count || 0
      //recon order amt for this sku and size
      const RTOAmt = reconOrderItemsForRTO.find(o => o.sku === sku && o.size === size)?.count || 0
      
      const REAmt = reconOrderItemsForRE.find(o => o.sku === sku && o.size === size)?.count || 0
      //opening stock amt for this sku and size
      const openingStockAmt = openingStock.find((o) => o.SKU === sku && o.size === size)?.count || 0
      //Damaged amt for this sku and size
      const DamagedAmt = damagedReconOrderItems.find((o) => o.SKU === sku && o.size === size)?.count || 0
      //closing
      const closing = openingStockAmt - dispatchedAmt + RTOAmt + REAmt + poAmt - DamagedAmt + stockAdded
      //later portion

      //
      return {
        sku,
        size,
        type: row.type,
        dispatchedAmt,
        RTOAmt,
        REAmt,
        openingStockAmt,
        inventoryAmt,
        closing,
        poAmt,
        DamagedAmt,
        stockAdded,
        isMatched : inventoryAmt - closing === 0 ? true : false
      }
    })
    setInventoryManagementDetails(inventoryWithDetails)
    // setFilteredData(inventoryWithDetails)
  }, [dispatchedOrders, reconOrders, inventory, openingStock, poStockHistory])
  console.log("table data", filteredData)
  return (
    <div>
      {/* filter component */}
      <Filter inventoryManagementDetails={inventoryManagementDetails} setFilteredData={setFilteredData} filteredData = {filteredData} />
      {/* table data component */}
      <InventoryTable filteredData={filteredData} setFilteredData={setFilteredData} />
    </div>
  )
}

export default InventoryManagement