import React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TablePagination,
  Button,
  Popover,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import ProductViewModal from "./ReverseProductViewModal";
import { useDispatch, useSelector } from "react-redux";
import {
  createSingleOrderShopify,
  convertDraftToActiveOrderShopify,
  createSingleDraftOrderShopify,
  sendDraftOrderInvoice,
  createExchangeProductsAndOrderShopify,
  changeReplacementToVoucher,
} from "../../actions/shopifyActions";
import {
  updateReverseOrder,
  // changeFilteredOrdersArray,
} from "../../actions/reverseDeliveryActions";
import { showFullScreenLoader } from "../../actions/loaderActions";

import { TableSkeletonComponent } from "../dashboard/helperComponents";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function Row({
  row,
  index,
  productIdArray,
  setSearchValue,
  returningStock,
  stockData,
}) {
  const [productModal, setProductModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [voucherCode, setVoucherCode] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);

  const dispatch = useDispatch();

  const handleCreateExchangeOrder = (products, order) => {
    dispatch(showFullScreenLoader());
    const selectedRow = order.products
      .map((x) => {
        const requiredProductId = productIdArray.filter(
          (item) => item.size === x.size && item.sku === x.sku
        );
        if (requiredProductId.length > 0)
          return {
            productId: requiredProductId[0].product_id.toString(),
            size: x.size,
            quantity: "1",
          };
        else
          return {
            productId: "delete",
          };
      })
      .filter((i) => i.productId !== "delete");

    const finalOrderObj = {
      fulfillment_id: order.fulfillment_id,
      order_items: selectedRow,
      customerId: order.customerId,
      address: order.address,
    };
    console.log(finalOrderObj, order.fulfillment_id);
    dispatch(
      createExchangeProductsAndOrderShopify(
        products,
        finalOrderObj,
        order.fulfillment_id
      )
    );
    setSearchValue("");
  };

  const handleMoveToVoucher = () => {
    const fulfillment_id = row.fulfillment_id;
    dispatch(changeReplacementToVoucher(fulfillment_id));
    setSearchValue("");
  };

  const handleExchangeButton = () => {
    // console.log("row", row);
    // console.log("stock", stockData);
    // console.log("returning", returningStock);
    const products = row.products;
    const availableProductsForExchange = products.filter((p) => {
      const sku = p.sku;
      const size = p.size;
      // first level check if finite or infinite
      const findSku = stockData.find((sd) => sd.sku === sku);
      if (!findSku) {
        console.log("not found", sku);
        return false;
      }
      const totalShopifyStock = findSku.stock[0].reduce(
        (acc, s) => acc + s.stock,
        0
      );
      console.log("shopify here check", findSku.stock[0]);
      const shopifyStock = findSku.stock[0].find((s) => s.size === size);
      const shopifySize = !!shopifyStock ? shopifyStock.stock : 0;
      if (shopifySize >= 1) {
        return true;
      }
      // if (totalShopifyStock >= 600) {
      //   return true;
      // } else {
      //   // if finite check inventory stock
      //   const inventoryStockAmt = findSku.inventoryStock[size]
      //     ? findSku.inventoryStock[size]
      //     : 0;
      //   if (inventoryStockAmt >= 1) {
      //     return true;
      //   } else {
      //     // check returning stock
      //     const findReturningSku = returningStock.find((rs) => rs.sku === sku);
      //     if (findReturningSku) {
      //       const amt = findReturningSku.sizes[size]
      //         ? findReturningSku.sizes[size]
      //         : 0;
      //       return amt >= 1;
      //     } else {
      //       return false;
      //     }
      //   }
      // }
    });
    const availableProductsQnty = availableProductsForExchange.length;
    const totalProductsQnty = products.length;
    const newRow = {
      ...row,
      products: availableProductsForExchange,
    };
    const availableProductsFormatted = availableProductsForExchange.map(
      (p) => ({
        size: p.size,
        sku: p.sku,
      })
    );
    return (
      <React.Fragment>
        {/* move to voucher */}
        {"awb_code" in row &&
        row.awb_code &&
        "exchange_products" in row &&
        row.products.length !== row.exchange_products.length ? (
          <Button
            variant="contained"
            color="error"
            onClick={handleMoveToVoucher}
          >
            {"(partial exchange)"}
            <br />
            Move to voucher
          </Button>
        ) : availableProductsQnty === 0 ? (
          <Button
            variant="contained"
            color="error"
            onClick={handleMoveToVoucher}
          >
            {`${availableProductsQnty} AV | `}
            <br />
            Move to voucher
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={() =>
              handleCreateExchangeOrder(availableProductsFormatted, newRow)
            }
          >
            {availableProductsQnty === totalProductsQnty
              ? "All AV |"
              : `${availableProductsQnty} AV |`}{" "}
            Exchange
          </Button>
        )}
      </React.Fragment>
    );
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setVoucherCode("");
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleDraftClick = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleDraftClose = () => {
    setAnchorEl1(null);
  };

  const openDraft = Boolean(anchorEl1);
  const draftId = openDraft ? "simple-popover" : undefined;

  const handleData = () => {
    let tempArr = [];
    tempArr.push(row);
    setModalData(tempArr);
  };

  const handleCreateNewOrder = () => {
    dispatch(showFullScreenLoader());
    const selectedRow = row.products
      .map((x) => {
        const requiredProductId = productIdArray.filter(
          (item) => item.size === x.size && item.sku === x.sku
        );
        if (requiredProductId.length > 0)
          return {
            productId: requiredProductId[0].product_id.toString(),
            size: x.size,
            quantity: "1",
          };
        else
          return {
            productId: "delete",
          };
      })
      .filter((i) => i.productId !== "delete");

    const finalOrderObj = {
      fulfillment_id: row.fulfillment_id,
      order_items: selectedRow,
      customerId: row.customerId,
      address: row.address,
    };
    console.log(finalOrderObj, row.fulfillment_id);
    dispatch(createSingleOrderShopify(finalOrderObj, row.fulfillment_id));
    setSearchValue("");
  };

  const handleVoucherSubmit = () => {
    console.log("row", row);
    const phone = row.alternatePhone.replace("+91", "");
    dispatch(showFullScreenLoader());
    handleClose();
    const objBody = {
      voucher: true,
      id: row.fulfillment_id,
      voucher_code: voucherCode,
      phone,
    };
    console.log(objBody);
    dispatch(updateReverseOrder(objBody));
    setSearchValue("");
  };

  const handleCreateNewDraftOrder = () => {
    dispatch(showFullScreenLoader());
    const selectedRow = row.products
      .map((x) => {
        const requiredProductId = productIdArray.filter(
          (item) => item.size === x.size && item.sku === x.sku
        );
        if (requiredProductId.length > 0)
          return {
            productId: requiredProductId[0].product_id.toString(),
            size: x.size,
            quantity: "1",
          };
        else
          return {
            productId: "delete",
          };
      })
      .filter((i) => i.productId !== "delete");

    const finalOrderObj = {
      fulfillment_id: row.fulfillment_id,
      order_items: selectedRow,
      customerId: row.customerId,
      address: row.address,
    };
    console.log(finalOrderObj, row.fulfillment_id, row.package_status);
    dispatch(createSingleDraftOrderShopify(finalOrderObj, row.fulfillment_id));
    setSearchValue("");
  };

  const handleInvoiceSend = () => {
    dispatch(showFullScreenLoader());
    console.log({ id: row.new_draftOrder_id.toString() });
    dispatch(sendDraftOrderInvoice({ id: row.new_draftOrder_id.toString() }));
    setSearchValue("");
  };

  const handlePrepaidOrder = () => {
    dispatch(showFullScreenLoader());
    console.log({ id: row.new_draftOrder_id.toString() }, row.fulfillment_id);
    dispatch(
      convertDraftToActiveOrderShopify(
        { id: row.new_draftOrder_id.toString() },
        row.fulfillment_id
      )
    );
    setSearchValue("");
  };

  const handleCodPendingOrder = () => {
    dispatch(showFullScreenLoader());
    console.log(
      { id: row.new_draftOrder_id.toString(), pending: true },
      row.fulfillment_id
    );
    dispatch(
      convertDraftToActiveOrderShopify(
        { id: row.new_draftOrder_id.toString(), pending: true },
        row.fulfillment_id
      )
    );
    setSearchValue("");
  };

  return (
    <>
      <TableRow
        component={Paper}
        sx={{
          "&:hover": {
            backgroundColor: "rgb(220,220,220)",
          },
        }}
      >
        <TableCell
          component="th"
          scope="row"
          style={{
            color: row.tags?.includes("replacement") ? "red" : "blue",
          }}
        >
          <Typography>{row.fulfillment_id}</Typography>
          {row.tags?.includes("replacement") ? (
            <Typography
              style={{
                fontSize: "x-small",
                borderBottom: "2px dotted",
                width: "max-content",
              }}
            >
              2nd Exchange
            </Typography>
          ) : (
            ""
          )}
        </TableCell>
        <TableCell>
          {new Date(row.sync_date).toLocaleDateString("en-in")}
        </TableCell>
        <TableCell style={{ maxWidth: "200px" }}>
          <Typography style={{ color: "blue" }}>
            {row.address ? row.address.name : ""}
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ fontSize: "small" }}
          >
            {row.address ? row.address.address1 : ""},
            {row.address ? `${row.address.city},${row.address.province}` : ""},
            {row.address ? row.address.zip : ""}
            <br />
            Ph : {row.address ? row.address.phone : ""}
            <br />
            Alt : {row.alternatePhone ? row.alternatePhone : ""}
            <br />
            Email : {row.email ? row.email : ""}
          </Typography>
        </TableCell>
        <TableCell>
          <Box>
            <Typography>{row.awb_code ? row.awb_code : "N/A"}</Typography>
            <Typography
              onClick={() => {
                setProductModal(true);
                handleData();
              }}
              style={{
                fontSize: "x-small",
                color: "blue",
                cursor: "pointer",
                borderBottom: "2px dotted",
                width: "max-content",
              }}
            >
              View Products
            </Typography>
            {productModal ? (
              <ProductViewModal
                productModal={productModal}
                tableData={modalData}
                setProductModal={setProductModal}
              />
            ) : (
              ""
            )}
          </Box>
        </TableCell>
        <TableCell>
          {row.exchangeAction ? row.exchangeAction : "Not Provided"}
        </TableCell>
        <TableCell>
          {row.exchangeAction === "Giftcard" ||
          row.exchangeAction === "Replacement&Giftcard" ? (
            <>
              <Button
                aria-describedby={id}
                variant="contained"
                color="error"
                onClick={handleClick}
              >
                Voucher
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "20px",
                  }}
                >
                  <Typography variant="h6" style={{ color: "blue" }}>
                    Voucher Details
                  </Typography>
                  <Typography variant="body1">
                    CustomerId : {row.customerId}
                    <br />
                    Email : {row.email}
                  </Typography>
                  <br />
                  <TextField
                    label="Code"
                    value={voucherCode}
                    onChange={(e) => setVoucherCode(e.target.value)}
                    size="small"
                  />
                  <br />
                  <Button
                    disabled={voucherCode === ""}
                    variant="contained"
                    color="secondary"
                    onClick={handleVoucherSubmit}
                  >
                    Submit
                  </Button>
                </Box>
              </Popover>
            </>
          ) : row.exchangeAction === "Replacement" &&
            row.tags.includes("replacement") &&
            !row.new_draftOrder ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCreateNewDraftOrder}
            >
              Draft
            </Button>
          ) : row.exchangeAction === "Replacement" &&
            row.tags.includes("replacement") &&
            row.new_draftOrder ? (
            <>
              <Button
                aria-describedby={draftId}
                variant="contained"
                color="warning"
                onClick={handleDraftClick}
              >
                PAID/COD
              </Button>
              <Popover
                id={draftId}
                open={openDraft}
                // anchorEl1={anchorEl1}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 200, left: 600 }}
                onClose={handleDraftClose}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "20px",
                  }}
                >
                  <Typography variant="h6" style={{ color: "blue" }}>
                    Draft Order Details
                  </Typography>
                  <Typography variant="body1">
                    Order : {row.new_draftOrder}
                    <br />
                    Order Id : {row.new_draftOrder_id}
                    <br />
                    Email : {row.email}
                  </Typography>
                  <br />
                  <Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        handleInvoiceSend();
                        handleDraftClose();
                      }}
                      size="small"
                      sx={{ right: "10px" }}
                    >
                      Send Invoice
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        handlePrepaidOrder();
                        handleDraftClose();
                      }}
                      size="small"
                      sx={{ right: "5px" }}
                    >
                      Place Prepaid
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        handleCodPendingOrder();
                        handleDraftClose();
                      }}
                      size="small"
                    >
                      Place COD
                    </Button>
                  </Box>
                </Box>
              </Popover>
            </>
          ) : row.exchangeAction === "Replacement" ? (
            <div>
              {handleExchangeButton()}
              {/* <Button variant="contained" onClick={handleCreateNewOrder}>
                Exchange
              </Button> */}
            </div>
          ) : (
            <Button variant="contained" onClick={handleCreateNewOrder}>
              Exchange
            </Button>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}

const ReverseInTransitOrdersTable = ({ tableData, setSearchValue }) => {
  // const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [productIdArray, setProductIdArray] = useState([]);

  const { shopify_products } = useSelector((state) => state.shopifyDetails);
  const { returningProductsData, shopifyInventory, loading } = useSelector(
    (state) => state.orderDetails
  );
  const { products, productVariants } = useSelector(
    (state) => state.productDetails
  );
  const inventoryStock = useSelector((state) => state.inventoryDetails.stock);

  const [productVariantList, setProductVariantList] = useState([]);
  const [stockData, setStockData] = useState([]);

  useEffect(() => {
    //Create the Tabledata and send to reducer
    if (inventoryStock.length > 0 && productVariantList.length > 0) {
      const data = inventoryStock.reduce((arr, product) => {
        const found = arr.find((ar) => ar.sku === product.SKU);
        if (found) {
          found.inventoryStock[product.size] = product.count;
        } else {
          let variantArr = [];
          const variantObj = productVariantList.filter(
            (item) => item.sku === product.SKU
          );
          variantArr.push(
            variantObj.map((x) => ({
              size: x.title,
              stock: x.stock ? x.stock : 0,
            }))
          );
          const newObj = {
            sku: product.SKU,
            stock: variantArr,
            inventoryStock: {
              [product.size]: product.count,
            },
          };
          arr.push(newObj);
        }
        return arr;
      }, []);
      setStockData(data);
    }
  }, [productVariantList, products, inventoryStock]);

  useEffect(() => {
    if (shopifyInventory.length > 0 && productVariants.length > 0) {
      const temp = productVariants.map((x) => {
        const variant = shopifyInventory.find(
          (p) => p.inventory_item_id === x.inventory_item_id
        );
        let stock = 0;
        if (variant) {
          stock = variant.available;
        } else {
          stock = 0;
        }
        return {
          ...x,
          stock,
        };
      });
      setProductVariantList(temp);
    }
  }, [shopifyInventory, productVariants]);

  useEffect(() => {
    if (shopify_products.length > 0) {
      const tempArray = [];
      shopify_products.map((x) =>
        x.variants.map((item) =>
          tempArray.push({
            product_id: item.product_id,
            sku: item.sku,
            size: item.title,
          })
        )
      );
      setProductIdArray(tempArray);
    }
  }, [shopify_products]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData?.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  console.log(
    products.length,
    inventoryStock.length,
    productVariants.length,
    stockData.length
  );
  return (
    <>
      {loading ||
      !products.length > 0 ||
      !inventoryStock.length > 0 ||
      !productVariants.length > 0 ||
      !stockData.length > 0 ||
      !Object.keys(returningProductsData).length > 0 ? (
        <TableSkeletonComponent />
      ) : (
        <div>
          <TableContainer>
            <Table aria-label="collapsible table">
              <TableHead component={Paper}>
                <TableRow>
                  <TableCell align="left">Order Number</TableCell>
                  <TableCell align="left">Created On </TableCell>
                  <TableCell align="left">Customer Details</TableCell>
                  <TableCell align="left">AWB Code</TableCell>
                  <TableCell align="left">Requested</TableCell>
                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>
              <br />
              <TableBody>
                {(rowsPerPage > 0
                  ? tableData?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : tableData
                )?.map((row, index) => (
                  <Row
                    key={index}
                    row={row}
                    returningStock={returningProductsData.sales_data}
                    index={index}
                    stockData={stockData}
                    productIdArray={productIdArray}
                    setSearchValue={setSearchValue}
                  />
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <TablePagination
            style={{
              overflow: "inherit",
              display: "flex",
              justifyContent: "center",
              background: "white",
              borderRadius: "10px",
            }}
            rowsPerPageOptions={[5, 10, 25, 100]}
            colSpan={3}
            count={tableData?.length > 0 ? tableData?.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: {
                "aria-label": "rows per page",
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </div>
      )}
    </>
  );
};

export default ReverseInTransitOrdersTable;
