import React, { useEffect, useState } from 'react'
import { categoryTypes, productTypes } from "../../utils/helperArrays";
import { Box, InputAdornment, MenuItem, Paper, TextField } from '@mui/material';

const SearchBar = ({ state, setState }) => {
  return (<TextField
    sx={{
      width: "300px"
    }}
    value={state}
    onChange={(e) => setState(e.target.value)}
    variant="outlined"
    className="select"
    size="small"
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <p
            style={{
              fontWeight: 800,
              borderRight: "1px solid black",
              paddingRight: "4px",
            }}
          >
            Search
          </p>
        </InputAdornment>
      ),
    }}
  />)
}

const SelectField = ({ state, setState, options, placeholder, isActive }) => {
  return (
    <TextField
      sx={{
        width: "300px"
      }}
      select
      disabled={options.length === 0}
      value={state}
      onChange={(e) => setState(e.target.value)}
      variant="outlined"
      className="select"
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <p
              style={{
                fontWeight: 800,
                borderRight: "1px solid black",
                paddingRight: "4px",
              }}
            >
              {placeholder}
            </p>
          </InputAdornment>
        ),
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

const Filter = ({ inventoryManagementDetails, setFilteredData, filteredData }) => {
  const [productType, setProductType] = useState("");
  const [categoryType, setCategoryType] = useState("");
  const [categoryTypesArray, setCategoryTypesArray] = useState([]);
  const [searchSku, setSearchSku] = useState("")

  console.log("categories", categoryTypesArray)

  //if productType is already selected getcategoryType and 
  useEffect(() => {
    if(inventoryManagementDetails.length === 0) return
    if (productType) {
      // create category options for the product type
      const getCategories = categoryTypes(productType);
      setCategoryTypesArray(getCategories);
      // --
      const filteredByAllInputs = inventoryManagementDetails.filter((p) => p.sku && 
      p.type === productType && 
      p.sku.includes(categoryType) && 
      String(p.sku).startsWith(searchSku));

      setFilteredData(filteredByAllInputs);
    } else {
      const filteredBySearchInput = inventoryManagementDetails.filter((p) =>
        p.sku
      );
      setFilteredData(filteredBySearchInput)
    }
  }, [inventoryManagementDetails, productType, categoryType, searchSku])


  return (
    <Box>
      <Paper sx={{ p: 2 }}>
        <div style={{ display: "flex", marginBottom: 3, gap: 2 }}>
          <SearchBar state={searchSku} setState={setSearchSku} />
          <SelectField placeholder={"Product Type"} options={productTypes} state={productType} setState={setProductType} />
          <SelectField placeholder={"Category"} options={categoryTypesArray} state={categoryType} setState={setCategoryType} />
        </div>

      </Paper>
    </Box>
  )
}

export default Filter