import { lambdaFetch } from "../utils/awsUtils";

export const getInvalidEmailList = () => {
    console.log("Called Email List");
    async function getEmailList() {
        const Url =
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/orders/listemailbystatus";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify({ status: "permanent_bounce" }),
        });
        const data = await response.json();
        return data;
    }
    return (dispatch) => {
        getEmailList()
            .then((data) => {
                if (data.statusCode === 200) {
                    console.log("Invalid Emails Fetched Successfully");
                    dispatch({
                        type: "INVALID_EMAIL",
                        payload: data.body.email_list,
                    });
                } else {
                    console.log("email list couldn't be fetched");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
};

export const getUnsuccessfulEmailList = () => {
    console.log("Called unsuccessfull email");
    async function getEmailList() {
        const Url =
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/orders/listemailbystatus";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify({ status: "soft_bounce" }),
        });
        const data = await response.json();
        return data;
    }
    return (dispatch) => {
        getEmailList()
            .then((data) => {
                if (data.statusCode === 200) {
                    console.log("Invalid Emails fetched successfully");
                    dispatch({
                        type: "UNSUCCESSFUL_EMAIL",
                        payload: data.body.email_list,
                    });
                } else {
                    console.log("email list couldn't be fetched");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
};
