import {
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxHeight: "90vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

const Last48hourssReconModal = ({ open, setOpen, data }) => {
  const [tableData, setTableData] = useState([]);
  console.log("Tb", tableData);

  const findDifference = (createdAt, pickedUp_date) => {
    const differenceInMilli = new Date(createdAt) - new Date(pickedUp_date);
    const differenceInDays = differenceInMilli / (24 * 60 * 60 * 1000);
    return differenceInDays;
  };
  // .sort((a,b)=>{
  //   const min = 2
  //   const difference1 = a.days
  //   const difference2 = b.days
  //   console.log("a and b", difference1, difference2)
  //   if(difference1 <=min && difference2 <=min){
  //     return difference2 - difference1
  // }
  //  else if(difference1 <= min){
  //       return -1
  //   }else if(difference2 <=min){
  //       return 1
  //   }else if(difference1 === difference2){
  //       return 0
  //   }
  //   else{
  //       return new Date(b.pickedup_date)- new Date(a.pickedup_date)
  //   }
  //   })
  useEffect(() => {
    const dataWithDays = [...data].map((td) => {
      if (td.created_at && td.pickedup_date) {
        const difference = findDifference(td.created_at, td.pickedup_date);
        return {
          ...td,
          days: difference,
        };
      } else {
        return td;
      }
    });
    const dataWithMinimumDays = dataWithDays
      .filter((d) => d.days <= 2)
      .sort((a, b) => b - a);
    const otherData = dataWithDays
      .filter((d) => !d.days || d.days > 2)
      .sort((a, b) => new Date(b.pickedup_date) - new Date(a.pickedup_date));
    console.log(
      "checking length",
      [...dataWithMinimumDays, ...otherData].length,
      data.length
    );
    setTableData([...dataWithMinimumDays, ...otherData]);
  }, [data]);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="order details table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#f0f0f0" }}>
                <TableCell>Sl No</TableCell>
                <TableCell>Fullfillment ID</TableCell>
                <TableCell>RTO Declared At</TableCell>
                <TableCell>Dispatch Date</TableCell>
                <TableCell>Address</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {tableData.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: item.days <= 2 ? "#feffdb" : "inherit",
                    border: "1px solid #a8a8a7",
                  }}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item?.fulfillment_id}</TableCell>
                  <TableCell>
                    <span>
                      {new Date(item?.created_at).toLocaleDateString("en-IN")}
                    </span>
                    <br />
                    <span>
                      {new Date(item?.created_at).toLocaleTimeString("en-IN")}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span>
                      {new Date(item?.pickedup_date).toLocaleDateString(
                        "en-IN"
                      )}
                    </span>
                    <br />
                    <span>
                      {new Date(item?.pickedup_date).toLocaleTimeString(
                        "en-IN"
                      )}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span>
                      {(item?.shipping_state || "state") +
                        " , " +
                        (item?.shipping_city || "city") +
                        " , " +
                        (item?.shipping_pincode || "pincode")}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

export default Last48hourssReconModal;
