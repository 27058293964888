import { lambdaFetch } from "../utils/awsUtils";
import { showModal, showUnauthorizedModal } from "./errorModalActions";
import { toast } from "react-toastify";
import { hideLoading, showLoading } from "./loaderActions";

export const allCustomers = (min, max) => async (dispatch) => {
    try {
        dispatch({
            type: "SHOPIFY_CUSTOMER_LOADER",
            data: true,
        });
        const URL =
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/shopifycustomer/getcustomerslist";
        let fetching = true;
        let orders = [];
        let nextUrl = null;

        while (fetching) {
            const response = await lambdaFetch(URL, {
                method: "POST",
                body: JSON.stringify({
                    currentUrl: nextUrl,
                    pageLength: 250,
                    min,
                    max,
                }),
            });
            if (response.status === 403) {
                fetching = false;
                dispatch(showUnauthorizedModal());
            }
            const data = await response.json();
            console.log(data);
            if (data.statusCode === 200) {
                orders.push(...data.data);
                nextUrl = data.next;
                if (!nextUrl) {
                    fetching = false;
                }
                dispatch({
                    type: "SHOPIFY_CUSTOMER_COUNT",
                    data: orders.length,
                });
            } else {
                console.log("here stopping");
                orders = [];
                fetching = false;

                toast.error("Service Error");
            }
        }
        dispatch({
            type: "SHOPIFY_CUSTOMER",
            data: orders,
        });
        dispatch({
            type: "SHOPIFY_CUSTOMER_LOADER",
            data: false,
        });
    } catch (error) {
        dispatch(showModal());
        console.log("error", error);
        toast.error(
            "Something Went Wrong With Fetching CustomersFrom Shopify",
            {
                theme: "colored",
            }
        );
    }
};

export const getShopifyOrders = (min, max) => async (dispatch) => {
    try {
        dispatch({
            type: "SHOPIFY_CUSTOMER_LOADER",
            data: true,
        });
        const URL =
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/orders/shopifyordersbydate";
        let fetching = true;
        let orders = [];
        let nextUrl = null;

        while (fetching) {
            const response = await lambdaFetch(URL, {
                method: "POST",
                body: JSON.stringify({
                    currentUrl: nextUrl,
                    pageLength: 250,
                    min,
                    max,
                }),
            });
            if (response.status === 403) {
                fetching = false;
                dispatch(showUnauthorizedModal());
            }
            const data = await response.json();

            if (data.statusCode === 200) {
                orders.push(...data.data);
                nextUrl = data.next;
                if (!nextUrl) {
                    fetching = false;
                }
                dispatch({
                    type: "ORDERS_COUNT",
                    data: orders.length,
                });
            } else {
                orders = [];
                fetching = false;

                toast.error("Service Error");
            }
        }
        dispatch({
            type: "SHOPIFY_ORDERS",
            data: orders,
        });
        dispatch({
            type: "SHOPIFY_CUSTOMER_LOADER",
            data: false,
        });
    } catch (error) {
        dispatch(showModal());
        console.log("error", error);
        toast.error(
            "Something Went Wrong With Fetching CustomersFrom Shopify",
            {
                theme: "colored",
            }
        );
    }
};

export const getCustomerDetails = (id) => {
    async function getOrders() {
        const Url =
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/shopifycustomer/customerdetails";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify({ customerId: id }),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();

        return data;
    }
    return (dispatch) => {
        dispatch({
            type: "CUSTOMER_ORDER_LOADER",
            data: true,
        });
        getOrders()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }

                if (data.statusCode === 200) {
                    toast.success("Customer Details fetched successfully");
                    dispatch({
                        type: "CUSTOMER_DETAILS",
                        data: data.data,
                    });
                } else {
                    toast.error("Customer couldn't be fetched");
                }
                dispatch({
                    type: "CUSTOMER_ORDER_LOADER",
                    data: false,
                });
            })
            .catch((error) => {
                dispatch({
                    type: "CUSTOMER_ORDER_LOADER",
                    data: false,
                });
                toast.error(
                    "Something went wrong with connecting to database",
                    {
                        theme: "colored",
                    }
                );
            });
    };
};
export const removeCustomerDetails = () => (dispatch) => {
    dispatch({
        type: "CUSTOMER_DETAILS",
        data: {},
    });
};
export const removeCustomerOrders = () => (dispatch) => {
    dispatch({
        type: "CUSTOMER_ORDERS",
        data: [],
    });
};

export const getShopifyCustomerOrders = (id) => {
    async function getOrders() {
        const Url =
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/shopifycustomer/orderdetails";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify({ customerId: id }),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();

        return data;
    }
    return (dispatch) => {
        dispatch({
            type: "CUSTOMER_ORDER_LOADER",
            data: true,
        });
        getOrders()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }

                if (data.statusCode === 200) {
                    toast.success("Orders fetched successfully");
                    dispatch({
                        type: "CUSTOMER_ORDERS",
                        data: data.data,
                    });
                } else {
                    toast.error("orders couldn't be fetched");
                }
                dispatch({
                    type: "CUSTOMER_ORDER_LOADER",
                    data: false,
                });
            })
            .catch((error) => {
                dispatch({
                    type: "CUSTOMER_ORDER_LOADER",
                    data: false,
                });
                toast.error(
                    "Something went wrong with connecting to database",
                    {
                        theme: "colored",
                    }
                );
            });
    };
};

export const getShopifyCustomerOrdersAsync = async (id) => {
    const Url =
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/shopifycustomer/orderdetails";

    try {
        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify({ customerId: id }),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();

        if (data.statusCode === 200) {
            return data.data;
        } else {
            toast.error("error fetching orders");
            return 500;
        }
    } catch (error) {
        toast.error("error fetching orders");
        return 500;
    }
};

export const getCustomerCount = (min, max) => {
    async function getCount() {
        const Url =
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/shopifycustomer/customercount";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify({ min, max }),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();

        return data;
    }
    return (dispatch) => {
        dispatch(showLoading());
        getCount()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }

                if (data.statusCode === 200) {
                    //   toast.success("Invalid Emails fetched successfully");
                    dispatch({
                        type: "CUSTOMER_COUNT",
                        data: data.data,
                    });
                } else {
                    toast.error("customer count couldn't be fetched");
                }
                dispatch(hideLoading());
            })
            .catch((error) => {
                dispatch(hideLoading());
                console.log("error", error);
                toast.error(
                    "Something went wrong with connecting to database",
                    {
                        theme: "colored",
                    }
                );
            });
    };
};
export const getOrdersCount = (min, max) => {
    console.log({ min, max });
    async function getCount() {
        const Url =
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/orders/shopifyorderscount";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify({ min, max }),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();

        return data;
    }
    return (dispatch) => {
        dispatch(showLoading());
        getCount()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }

                if (data.statusCode === 200) {
                    //   toast.success("Invalid Emails fetched successfully");
                    dispatch({
                        type: "TOTAL_ORDERS_COUNT",
                        data: data.data,
                    });
                } else {
                    toast.error("customer count couldn't be fetched");
                }
                dispatch(hideLoading());
            })
            .catch((error) => {
                dispatch(hideLoading());
                console.log("error", error);
                toast.error(
                    "Something went wrong with connecting to database",
                    {
                        theme: "colored",
                    }
                );
            });
    };
};

export const getDBOrders = async (id) => {
    const Url =
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/shopifycustomer/dynamodborders";

    try {
        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify({ orderId: String(id) }),
        });

        const data = await response.json();

        if (data.statusCode === 200) {
            // toast.success("Fetched successfully");
            // dispatch({
            //   type: "CUSTOMER_DB_ORDERS",
            //   data: data.data,
            // });
        } else {
            toast.error("Database Service Error");
        }
        return {
            statusCode: data.statusCode,
            data: data.data,
            vouchers: data.vouchers,
        };
    } catch (error) {
        toast.error("database error");
        return {
            statusCode: 500,
        };
    }
    // dispatch(hideLoading());
};

export const openOrder = async (orderId) => {
    try {
        const Url =
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/orders/openorcloseorder";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify({ orderId, status: "open" }),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();

        return data;
    } catch (error) {
        return 500;
    }
};

export const closeOrder = async (orderId) => {
    try {
        const Url =
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/orders/openorcloseorder";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify({ orderId, status: "close" }),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();

        return data;
    } catch (error) {
        return 500;
    }
};

export const changeTag = async (orderId, tags) => {
    try {
        const Url =
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/orders/updatetag";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify({ orderId, tags }),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();

        return data;
    } catch (error) {
        return 500;
    }
};

export const holdOrderFulfillment = async (orderId, type, reason) => {
    try {
        const Url =
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/orders/holdoreleasefulfillment";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify({ orderId, status: "hold", type, reason }),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();

        return data;
    } catch (error) {
        return 500;
    }
};

export const releaseOrderFulfillment = async (orderId, reason) => {
    try {
        const Url =
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/orders/holdoreleasefulfillment";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify({ orderId, status: "release_hold" }),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();

        return data;
    } catch (error) {
        return 500;
    }
};

export const showOrderFulfillment = async (orderId) => {
    console.log("order id", orderId);
    try {
        const Url =
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/orders/showfulfillment";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify({ orderId }),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();

        if (data.statusCode === 200) {
            return data.data;
        } else {
            return 500;
        }
    } catch (error) {
        return 500;
    }
};

export const showFirstOrderOfTheDay = async (min, max) => {
    try {
        const Url =
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/orders/firstorderofday";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify({ min, max }),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();

        if (data.statusCode === 200) {
            console.log("data", data.data);
            return data.data;
        } else {
            return 500;
        }
    } catch (error) {
        return 500;
    }
};

export const getCustomerInformation = async (id) => {
    const Url =
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/shopifycustomer/customerdetails";

    try {
        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify({ customerId: id }),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();
        if (data.statusCode === 200) {
            return data;
        } else {
            return 500;
        }
    } catch (error) {
        return 500;
    }
};

export const fulfillOrder = async (orderId) => {
    try {
        const Url =
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/orders/fulfillallproducts";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify({ orderId }),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();
        console.log("data fulfill all", data);
        return data;
    } catch (error) {
        return 500;
    }
};

export const bulkOrderMailing = (payload) => async (dispatch, getState) => {
    const URL =
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/orders/bulkordermail/sendmail";

    dispatch({
        type: "SHOW_BULK_ORDER_MAIL_LOADER",
    });
    const state = getState();
    const { shopify_orders_bulk_mail } = state.shopifyDetails;
    for (let i = 0; i < payload.orders.length; i++) {
        try {
            const response = await lambdaFetch(URL, {
                method: "POST",

                body: JSON.stringify({
                    order: payload.orders[i],
                    message: payload.message,
                }),
            });

            console.log({
                order: payload.orders[i],
                message: payload.message,
            });

            if (response.status === 403) {
                dispatch({
                    type: "HIDE_BULK_ORDER_MAIL_LOADER",
                });
                dispatch(showUnauthorizedModal());
                return;
            }
            const data = await response.json();

            if (data.statusCode !== 200) {
                dispatch({
                    type: "HIDE_BULK_ORDER_MAIL_LOADER",
                });
                toast.error(
                    `task failed for an order ${payload.orders[i].order_number}, operation stopped`
                );
                return;
            }
            await changeTag(payload.orders[i].id, payload.orders[i].tags);
            dispatch({
                type: "BULK_ORDER_MAIL_PROGRESS",
                data: `${i + 1}/${payload.orders.length}`,
            });
            // dispatch(getAllTickets("All"));
        } catch (err) {
            dispatch({
                type: "HIDE_BULK_ORDER_MAIL_LOADER",
            });
            toast.error("Something Went Wrong With Connecting To Database", {
                theme: "colored",
            });
        }
    }
    const newOrders = shopify_orders_bulk_mail.map((row) => {
        const found = payload.orders.find((o) => o.id === row.id);
        if (found) {
            row.tags = found.tags;
        }
        return row;
    });
    toast.success("task completed");
    dispatch({
        type: "SHOPIFY_ORDER_LIST_FOR_MAIL",
        data: newOrders,
    });
};

// ------------------------------------------------
export const getOrderDetails = async (idName, idNum) => {
    const Url =
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/orderDetailsByF_id";

    try {
        const response = await lambdaFetch(Url, {
            method: "POST",
            body: JSON.stringify({ ID: idName, value: idNum }),
        });

        if (response.status === 403) {
            console.log("response", response);
            return 403;
        }
        const data = await response.json();
        if (data.statusCode === 200) {
            return data.data;
        } else {
            toast.error("error fetching orders");
            return 500;
        }
    } catch (error) {
        toast.error("error fetching orders");
        return 500;
    }
};
