import { getOrderWeight } from "./weightCalculator";
const footwearInitials = ["FL", "HL", "PL", "FF", "DMU", "BT", "MS"];
const clothingInitials = [
  "TP",
  "DR",
  "TR",
  "ST",
  "TS",
  "JN",
  "SK",
  "HD",
  "MJ",
  "WJ",
  "MT",
  "MH",
  "LG",
  "AT",
  "MB",
  "MO",
  "CT",
  "WC",
  "MC",
  "PCDR",
  "PDR",
  "PTP",
  "PTR",
  "PSK",
  "PST",
];

const typeRegex = /^.*?(?=\d)/;

const isOfCategory = (p, category) => {
  const type = p.sku.match(typeRegex)[0];
  const found = category.find((i) => i === type);
  return !!found;
};

export const calculateLBHBySKU = (sku) => {
  // Regex to split first x characters

  const regex = /^.*?(?=\d)/;
  //   console.log("sku regex", sku, sku.match(regex));
  const type = sku.match(regex)[0];
  // Use a standard to comapre and put lbh
  switch (type) {
    case "FL":
      return {
        length: 20,
        breadth: 14,
        height: 12,
      };
    case "PL":
      return {
        length: 31,
        breadth: 20,
        height: 12,
      };
    case "DMU":
      return {
        length: 29,
        breadth: 18,
        height: 10,
      };
    case "HL":
      return {
        length: 28,
        breadth: 18,
        height: 10,
      };
    case "FF":
      return {
        length: 32,
        breadth: 14,
        height: 11,
      };
    case "BT":
      return {
        length: 32,
        breadth: 26,
        height: 13,
      };
    case "MS":
      return {
        length: 32,
        breadth: 21,
        height: 11,
      };
    case "TP":
      return {
        length: 35,
        breadth: 30,
        height: 1,
      };
    case "DR":
      return {
        length: 35,
        breadth: 30,
        height: 2,
      };
    case "TR":
      return {
        length: 35,
        breadth: 30,
        height: 2,
      };
    case "ST":
      return {
        length: 35,
        breadth: 30,
        height: 3,
      };
    case "TS":
      return {
        length: 35,
        breadth: 30,
        height: 1,
      };
    case "CT":
      return {
        length: 35,
        breadth: 30,
        height: 2,
      };
    case "HD":
      return {
        length: 35,
        breadth: 30,
        height: 4,
      };
    default:
      return {
        length: 35,
        breadth: 30,
        height: 1,
      };
  }
};

export const getLBHBySKU = (sku, stock) => {
  const find = stock.find((s) => s.SKU === sku.split(".")[0]);
  // console.log("stock", sku, find, stock);
  return {
    length: find.length,
    breadth: find.breadth,
    height: find.height,
  };
};

export const populateLBHforOrders = (orders) => {
  const newOrderList = orders.map((o) => {
    //Some error
    const products = o?.order_items;
    if (products === null || products.length === 0) {
      console.log(products);
      return o;
    }
    //Single item in order
    if (products.length === 1 && products[0].units === 1) {
      return {
        ...o,
        length: products[0]?.lbh?.length,
        breadth: products[0]?.lbh?.breadth,
        height: products[0]?.lbh?.height,
      };
    }
    //Multiple item in order
    //If special order with more than 3 items,
    // if (products.reduce((sum, i) => sum + i.units, 0) > 3) {
    //   return o;
    // }

    const clothingItems = products.filter((p) =>
      isOfCategory(p, clothingInitials)
    );
    const footwearItems = products.filter((p) =>
      isOfCategory(p, footwearInitials)
    );

    //for clothing
    if (footwearItems.length === 0) {
      // Only clothing in the order
      const length = clothingItems
        .map((c) => c.lbh.length)
        .reduce((max, num) => (num > max ? num : max), 0);
      const breadth = clothingItems
        .map((c) => c.lbh.breadth)
        .reduce((max, num) => (num > max ? num : max), 0);
      const height = clothingItems.reduce(
        (h, i) => h + i.units * i?.lbh?.height,
        0
      );
      return {
        ...o,
        length,
        breadth,
        height,
      };
    }
    //for footwear
    else if (clothingItems.length === 0) {
      // Only footwear in the order
      const totalUnits = footwearItems.reduce((sum, i) => sum + i.units, 0);

      if (totalUnits === 1) {
        //BOX size same as footwear box
        return {
          ...o,
          length: footwearItems[0]?.lbh?.length,
          breadth: footwearItems[0]?.lbh?.breadth,
          height: footwearItems[0]?.lbh?.height,
        };
      } else {
        // More than 1 footwear
        const length = footwearItems
          .map((c) => c.lbh.length)
          .reduce((max, num) => (num > max ? num : max), 0);
        const breadth = footwearItems
          .map((c) => c.lbh.breadth)
          .reduce((max, num) => (num > max ? num : max), 0);
        const height = footwearItems.reduce(
          (h, i) => h + i.units * i?.lbh?.height,
          0
        );
        return {
          ...o,
          length,
          breadth,
          height,
        };
      }
    } else {
      // Both clothing and Footwear in the order
      const totalFootwear = footwearItems.reduce((sum, i) => sum + i.units, 0);
      if (totalFootwear === 1) {
        //BOX size same as footwear box
        const footwearHeight = footwearItems.reduce(
          (h, i) => h + i.units * i?.lbh?.height,
          0
        );
        const clothingHeight = clothingItems.reduce(
          (h, i) => h + i.units * i?.lbh?.height,
          0
        );
        const height = footwearHeight + clothingHeight;
        return {
          ...o,
          length: footwearItems[0]?.lbh?.length,
          breadth: footwearItems[0]?.lbh?.breadth,
          height,
        };
      } else {
        const length = footwearItems
          .map((c) => c.lbh.length)
          .reduce((max, num) => (num > max ? num : max), 0);
        const breadth = footwearItems
          .map((c) => c.lbh.breadth)
          .reduce((max, num) => (num > max ? num : max), 0);
        const footwearHeight = footwearItems.reduce(
          (h, i) => h + i.units * i?.lbh?.height,
          0
        );
        const clothingHeight = clothingItems.reduce(
          (h, i) => h + i.units * i?.lbh?.height,
          0
        );
        const height = footwearHeight + clothingHeight;
        return {
          ...o,
          length,
          breadth,
          height,
        };
      }
    }
  });

  return newOrderList;
};

export const getLBHWforReverse = (products, stock) => {
  const weight = getOrderWeight(
    products.map((p) => ({
      ...p,
      units: 1,
    }))
  );
  if (products === null || products.length === 0) {
    console.log(products);
    return {
      length: 0,
      breadth: 0,
      height: 0,
      weight: 0,
    };
  }
  //Single item in order
  if (products.length === 1) {
    const { length, breadth, height } = getLBHBySKU(products[0].sku, stock);
    return {
      length: length,
      breadth: breadth,
      height: height,
      weight: weight,
    };
  }
  //Multiple item in order

  const clothingItems = products.filter((p) =>
    isOfCategory(p, clothingInitials)
  );
  const footwearItems = products.filter((p) =>
    isOfCategory(p, footwearInitials)
  );

  if (footwearItems.length === 0) {
    // Only clothing in the order
    const length = 35; //All cloths same length
    const breadth = 30; //All cloths same breadth
    const height = clothingItems.reduce((h, i) => {
      const dimensions = getLBHBySKU(i.sku, stock);
      return h + dimensions.height;
    }, 0);
    return {
      length,
      breadth,
      height,
      weight,
    };
  } else if (clothingItems.length === 0) {
    // Only footwear in the order
    const totalUnits = footwearItems.reduce((sum, i) => sum + 1, 0);

    if (totalUnits === 0) {
      //EARLY EXIT
      return {
        length: 0,
        breadth: 0,
        height: 0,
        weight: 0,
      };
    }

    const dimensions = getLBHBySKU(footwearItems[0].sku, stock);

    if (totalUnits === 1) {
      //BOX size same as footwear box
      return {
        length: dimensions.length,
        breadth: dimensions.breadth,
        height: dimensions.height,
        weight: weight,
      };
    } else {
      // More than 1 footwear, multiply height
      return {
        length: dimensions.length,
        breadth: dimensions.breadth,
        height: dimensions.height * totalUnits,
        weight: weight,
      };
    }
  } else {
    // Both clothing and Footwear in the order
    const totalFootwear = footwearItems.reduce((sum, i) => sum + 1, 0);
    if (totalFootwear === 1) {
      const dimensions = getLBHBySKU(footwearItems[0].sku, stock);
      //BOX size same as footwear box
      return {
        length: dimensions.length,
        breadth: dimensions.breadth,
        height: dimensions.height,
        weight: weight,
      };
    } else {
      const dimensions = getLBHBySKU(footwearItems[0].sku, stock);
      //BOX size same as footwear box
      return {
        length: dimensions.length,
        breadth: dimensions.breadth,
        height: dimensions.height * totalFootwear,
        weight: weight,
      };
    }
  }
};
