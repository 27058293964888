import React, { useState, useEffect } from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePaginationActions from "../../utils/TablePaginationActions";
import { deleteOrder, packetNotReady } from "../../actions/ordersActions";
import { createShActionHistory } from "../../actions/shiprocketActions";

function OrderActionsSelector({
  orders,
  reset,
  removeFromTable,
  updateTable,
  orderTakeoutDate,
}) {
  const handleChange = async (e) => {
    if (e.target.value === 1) {
      // packet not ready
      for (let i = 0; i < orders.length; i++) {
        // const shOrder = await createShActionHistory(
        //   orders[i],
        //   orderTakeoutDate
        // );
        // if (shOrder.status === 200) {
        //   // set
        // }
        // continue;
        const data = await packetNotReady(orders[i]);
        if (data && data.statusCode === 200) {
          removeFromTable(orders[i]);
        }
        reset([]);
      }
    } else {
      // delete
      for (let i = 0; i < orders.length; i++) {
        const data = await deleteOrder(orders[i]);
        if (data && data.statusCode === 200) {
          removeFromTable(orders[i]);
        }
        reset([]);
      }
    }
  };
  return (
    <FormControl variant="outlined" size="small" sx={{ m: 2, minWidth: 120 }}>
      <InputLabel id="demo-simple-select-label">Actions</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={""}
        label="Age"
        onChange={handleChange}
      >
        <MenuItem value={1}>Packet Not Ready</MenuItem>
        <MenuItem value={2}>Delete</MenuItem>
      </Select>
    </FormControl>
  );
}

export default function ShiprocketOrderTable({ tableData, orderTakeoutDate }) {
  const [data, setData] = useState(
    tableData?.sort(
      (a, b) => Number(a.original_order_id) - Number(b.original_order_id)
    )
  );
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [open, setOpen] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setSelectedOrders([]);
    if (tableData.length > 0) {
      setOpen(Array(tableData.length).fill(false));
    }
    setData(
      [...tableData]
        ?.sort(
          (a, b) => Number(a.original_order_id) - Number(b.original_order_id)
        )
        .reverse()
    );
  }, [tableData]);

  const removeFromTable = (order) => {
    const filtered = data.filter(
      (d) => d.fulfillment_id !== order.fulfillment_id
    );
    setData(filtered);
  };

  const updateTable = (order) => {
    console.log("updating", order);
    const filtered = data.map((o) => {
      if (o.fulfillment_id === order.fulfillment_id) {
        return order;
      } else {
        return o;
      }
    });
    setData(filtered);
  };
  // console.log(
  //   "data",
  //   data
  //     .filter(
  //       (d) =>
  //         d.fulfillment_id === "900039.1" ||
  //         d.fulfillment_id === "899985.1" ||
  //         d.fulfillment_id === "900028.1"
  //     )
  //     .map((d) => ({
  //       ...d,
  //       order_id: d.fulfillment_id,
  //       length: String(d.length),
  //       breadth: String(d.breadth),
  //       height: String(d.height),
  //     }))
  // );
  console.log(
    data.map((d) => ({
      order_id: String(d.fulfillment_id),
      billing_address: d.billing_address,
      billing_address_2: d.billing_address_2,
      billing_city: d.billing_city,
      billing_country: d.billing_country,
      billing_customer_name: d.billing_customer_name,
      billing_email: d.billing_email,
      billing_last_name: d.billing_last_name,
      billing_phone: d.billing_phone,
      billing_pincode: d.billing_pincode,
      billing_state: d.billing_state,
      breadth: d.breadth,
      height: d.height,
      length: d.length,
      order_date: d.order_date,
      order_items: d.order_items.map((od) => ({
        selling_price: String(od.selling_price),
        hsn: "",
        name: "Turndown Collar Blazer & Bustier Two Piece Dress In Off-White - S",
        discount: 169.9,
        tax: parseFloat(od.tax),
        units: 1,
        sku: "ST1734.S",
      })),
      payment_method: d.payment_method,
      pickup_location: d.pickup_location,
      shipping_address: d.shipping_address,
      shipping_address_2: d.shipping_address_2,
      shipping_charges: d.shipping_charges,
      shipping_city: d.shipping_city,
      shipping_country: d.shipping_country,
      shipping_customer_name: d.shipping_customer_name,
      shipping_email: d.shipping_email,
      shipping_is_billing: d.shipping_is_billing,
      shipping_last_name: d.shipping_last_name,
      shipping_phone: d.shipping_phone,
      shipping_pincode: d.shipping_pincode,
      shipping_state: d.shipping_state,
      sub_total: d.sub_total,
      total: d.total,
      weight: String(d.weight),
    }))
  );
  return (
    <>
      {selectedOrders.length > 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <h4>Selected Action : </h4>
          <OrderActionsSelector
            orderTakeoutDate={orderTakeoutDate}
            orders={selectedOrders}
            reset={setSelectedOrders}
            removeFromTable={removeFromTable}
            updateTable={updateTable}
          />
        </div>
      )}
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  color="primary"
                  checked={selectedOrders.length === data.length}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedOrders(data.filter((d) => d.processed_at));
                    } else {
                      setSelectedOrders([]);
                    }
                  }}
                />
              </TableCell>
              <TableCell align="left">Order Number</TableCell>
              <TableCell align="left">Courier</TableCell>
              <TableCell align="left">AWB Code</TableCell>
              <TableCell align="left">Error</TableCell>
              <TableCell align="left">Comment</TableCell>
              <TableCell align="left">Date</TableCell>
              <TableCell align="left">Package Status</TableCell>
              <TableCell align="left">Shipping Cost</TableCell>
              <TableCell align="left">Sub Total</TableCell>
              <TableCell align="left">Total</TableCell>
              <TableCell align="left">Product</TableCell>
              <TableCell align="left">List</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row, index) => (
              <React.Fragment key={index}>
                <TableRow
                  key={index}
                  sx={{
                    bgcolor:
                      row.status === "fulfilled"
                        ? "rgba(145,145,145, 0.2)"
                        : index % 2 === 0
                        ? "#afd275"
                        : "#edf5e1",

                    "& > *": {
                      borderBottom: "unset",
                    },
                  }}
                >
                  {row.processed_at && (
                    <Checkbox
                      color="primary"
                      checked={
                        selectedOrders.filter(
                          (i) => i.fulfillment_id === row.fulfillment_id
                        ).length > 0
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedOrders([...selectedOrders, row]);
                        } else {
                          setSelectedOrders(
                            selectedOrders.filter(
                              (item) =>
                                item.fulfillment_id !== row.fulfillment_id
                            )
                          );
                        }
                      }}
                    />
                  )}
                  <TableCell component="th" scope="row">
                    {row.original_order_id} (#
                    {row.fulfillment_id})
                  </TableCell>
                  <TableCell align="left">{row.courier_name}</TableCell>
                  <TableCell>{row.awb_code}</TableCell>
                  <TableCell>{row.error_msg}</TableCell>
                  <TableCell>{row.shiprocket_msg}</TableCell>
                  <TableCell align="left">
                    {new Date(row.order_date).toLocaleDateString()}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "blue",
                    }}
                    align="left"
                  >
                    {row.package_status}
                  </TableCell>
                  <TableCell align="left">{row.shipping_charges}</TableCell>
                  <TableCell align="left">{row.sub_total}</TableCell>
                  <TableCell align="left">{row.total}</TableCell>
                  <TableCell align="left">
                    {row.order_items?.length || 0} Items
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => {
                        const temp = open.map((i, openIndex) => {
                          if (index !== openIndex) {
                            return i;
                          } else {
                            return !i;
                          }
                        });
                        setOpen(temp);
                      }}
                    >
                      {open[index] ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      paddingBottom: 0,
                      paddingTop: 0,
                    }}
                    colSpan={6}
                  ></TableCell>
                </TableRow>
              </React.Fragment>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                style={{ overflow: "inherit" }}
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
