import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Stack } from "@mui/system";

const UnfulfilledPOSection = ({
  setStockSize,
  setPage,
  sectionType,
  setSectionType,
}) => {
  return (
    <Stack
      direction={"row"}
      sx={{
        margin: 0,
        padding: 0,
        overflowX: "hidden",
        width: "100%",
      }}
    >
      {["Finite", "Semi-Finite", "Infinite", "Active", "Draft"].map(
        (types, i) => (
          <Box
            key={i}
            onClick={(e) => {
              setSectionType(types);
            }}
            sx={{
              float: "left",
              margin: "0 .5em 0 0",
              "& p": {
                margin: 0,
                position: "relative",
                background: sectionType === types ? "#fff" : "#ddd",
                backgroundImage:
                  !sectionType === types
                    ? "linear-gradient(to bottom, #fff, #ddd)"
                    : "",
                padding: ".7em 2.5em",
                float: "left",
                textDecoration: "none",
                color: "#444",
                textShadow: "0 1px 0 rgba(255,255,255,.8)",
                borderRadius: "5px 0 0 0",
                boxShadow: "0 2px 2px rgba(0,0,0,.4)",
              },
              "& p:focus,p:hover,p:hover::after,p:focus,p:focus::after": {
                background: "#fff",
                cursor: "pointer",
              },
              "& p:focus": {
                outline: 0,
              },
              "& p::after": {
                content: '""',
                position: "absolute",
                zIndex: sectionType === types ? 4 : 1,
                top: 0,
                right: "-.5em",
                bottom: 0,
                width: "1em",
                background: sectionType === types ? "#fff" : "#ddd",
                backgroundImage:
                  !sectionType === types
                    ? "linear-gradient(to bottom, #fff, #ddd)"
                    : "",
                boxShadow: "2px 2px 2px rgba(0,0,0,.4)",
                transform: "skew(10deg)",
                borderRadius: "0 5px 0 0",
              },
            }}
          >
            <p style={{ fontWeight: sectionType === types ? 800 : 400 }}>
              {types}
            </p>
          </Box>
        )
      )}
    </Stack>
  );
};

export default UnfulfilledPOSection;
