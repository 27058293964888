import {
  Button,
  Chip,
  CircularProgress,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeOrder,
  getDBOrders,
  getShopifyCustomerOrders,
  openOrder,
} from "../../actions/customerActions";
import { allInventory } from "../../actions/inventoryActions";
import { getDelhiveryShipmentDetails } from "../../actions/ordersActions";
import { cancelOrder } from "../../actions/ticketActions";
import { DelhiveryOrderHistory } from "../delhivery/tracking/DelhiveryOrderHistory";

const CustomerOrderList = ({ orders }) => {
  const [order, setOrder] = useState({});
  const [orderList, setOrderList] = useState([]);
  const [isOrderListEmpty, setIsOrderListEmpty] = useState(false);
  const [ordersWithImages, setOrdersWithImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [dbOrders, setDbOrders] = useState([]);
  const [open, setOpen] = useState(false);
  const [openCancelLoading, setOpenCancelLoading] = useState(false);
  const [orderHistoryModal, setOrderHistoryModal] = useState(false);
  const [errorCancel, setErrorCancel] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const openPriorityMenu = Boolean(anchorEl);
  const handlePriorityMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePriorityMenuClose = (e) => {
    setAnchorEl(null);
  };
  const { stock } = useSelector((state) => state.inventoryDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (!orderId || !ordersWithImages.length > 0) return;
      setLoading(true);
      const selected = ordersWithImages.filter(
        (o) => o.order_number === orderId
      )[0];
      const dbOrders = await getDBOrders(orderId);
      selected.vouchers = dbOrders.vouchers;
      if (dbOrders.statusCode === 200 && dbOrders.data.length > 0) {
        const dbOrderItems = dbOrders.data.reduce((acc, item) => {
          const order_items = item.order_items.map((o) => ({
            ...o,
            fulfillment_id: item.fulfillment_id,
            awb_code: item.awb_code,
            package_status: item.package_status,
            processed_at: item.processed_at,
            ndr_processedAt:
              "ndr_processedAt" in item ? item.ndr_processedAt : "not found",
            rto_declared_on:
              "rto_declared_on" in item ? item.rto_declared_on : "not found",
            rto_delivery_date:
              "rto_delivery_date" in item
                ? item.rto_delivery_date
                : "not found",
            delivery_date:
              "delivery_date" in item ? item.delivery_date : "not found",
          }));
          acc.push(...order_items);
          return acc;
        }, []);
        const formatted = {
          ...selected,
          line_items: selected.line_items.map((oi) => {
            const found = dbOrderItems.find((i) => i.id === oi.id);
            if (!!found) {
              return {
                ...oi,
                fulfillment_id: found.fulfillment_id,
                awb_code: found.awb_code,
                package_status: found.package_status,
                processed_at: found.processed_at,
                ndr_processedAt: found.ndr_processedAt,
                rto_declared_on: found.rto_declared_on,
                rto_delivery_date: found.rto_delivery_date,
                delivery_date: found.delivery_date,
              };
            }
            return {
              ...oi,
              fulfillment_id: "not shipped yet/unfulfilled",
              package_status: "not updated yet",
              processed_at: "not found",
              ndr_processedAt: "not found",
              rto_declared_on: "not found",
              rto_delivery_date: "not found",
              delivery_date: "not found",
            };
          }),
        };
        const formattedFulfillmentIdwise = {
          ...formatted,
          order_items: formatted.line_items.reduce((acc, p) => {
            const found = acc.find(
              (i) => i.fulfillment_id === p.fulfillment_id
            );
            if (!!found) {
              found.line_items = [
                ...found.line_items,
                {
                  ...p,
                },
              ];
            } else {
              const newFulfillmentId = {
                fulfillment_id: p.fulfillment_id,
                line_items: [
                  {
                    ...p,
                  },
                ],
                package_status: p.package_status,
                awb_code: p.awb_code || "Not updated",
                processed_at: p.processed_at,
                ndr_processedAt: p.ndr_processedAt,
                rto_declared_on: p.rto_declared_on,
                rto_delivery_date: p.rto_delivery_date,
                delivery_date: p.delivery_date,
              };
              acc.push(newFulfillmentId);
            }
            return acc;
          }, []),
        };

        setOrder(formattedFulfillmentIdwise);
      } else if (dbOrders.statusCode === 200 && dbOrders.data.length === 0) {
        const formatted = {
          ...selected,
          order_items: [
            {
              fulfillment_id: "not shipped yet/unfulfilled",
              line_items: selected.line_items,
              package_status: "not shipped yet",
              awb_code: "Not updated",
              processed_at: "not found in database",
              ndr_processedAt: "not found in database",
              rto_declared_on: "not found in database",
              rto_delivery_date: "not found in database",
              delivery_date: "not found in database",
            },
          ],
        };
        setOrder(formatted);
      } else {
        const formatted = {
          ...selected,
          order_items: [
            {
              fulfillment_id: "Database error",
              line_items: selected.line_items,
              package_status: "database connection error",
              processed_at: "database connection error",
              ndr_processedAt: "database connection error",
              rto_declared_on: "database connection error",
              rto_delivery_date: "database connection error",
              delivery_date: "database connection error",
            },
          ],
        };
        setOrder(formatted);
      }
      setLoading(false);
    })();
  }, [orderId, ordersWithImages]);

  useEffect(() => {
    if (stock.length > 0) {
      const mappedOrders = orders.map((o) => {
        const productsWithImages = o.line_items.map((p) => {
          const found = stock.find((i) => i.SKU === p.sku);
          const url =
            found?.imageUrl ||
            "https://media.istockphoto.com/vectors/error-page-or-file-not-found-icon-vector-id924949200";
          return { ...p, url };
        });

        return {
          ...o,
          line_items: productsWithImages,
        };
      });
      setOrdersWithImages(mappedOrders);
    } else {
      dispatch(allInventory());
    }
  }, [stock, orders]);

  const handleOpenHistory = (awb) => {
    dispatch(getDelhiveryShipmentDetails(awb));
    setOpen(true);
    setOrderHistoryModal(true);
  };

  const handleClose = async (reason) => {
    setOpenCancelLoading(true);
    await cancelOrder(order.id, reason);
    dispatch(getShopifyCustomerOrders(order.customer.id));
    setOpenCancelLoading(false);
  };

  useEffect(
    (o) => {
      if (!orders.length > 0) return;
      const filtered = orders.filter((o) => !o.cancelled_at);
      setOrderList(filtered);
      if (filtered.length === 0) {
        setIsOrderListEmpty(true);
      }
    },
    [orders]
  );
  return (
    <div>
      <Stack direction={"row"} gap={2} sx={{ flexWrap: "wrap" }}>
        {orders.map((o) => (
          <Chip
            label={o.order_number}
            onClick={() => setOrderId(o.order_number)}
            sx={{
              color: o.tags.includes("replacement") ? "blue" : "",
              "& : hover": {
                cursor: "pointer",
              },
            }}
          />
        ))}
        {isOrderListEmpty && <h3>No orders found/all orders cancelled</h3>}
      </Stack>
      {(!orders.length > 0 || loading) && <CircularProgress />}
      <br />
      {Object.keys(order).length > 0 ? (
        <Box
          sx={{
            "& h1": {
              margin: 0,
              padding: 0,
            },
            "& h3": {
              margin: 0,
              padding: 0,
            },
            "& h5": {
              margin: 0,
              padding: 0,
            },
          }}
        >
          <h1>{order.name}</h1>
          <h3>
            fufillment status :{" "}
            <span style={{ fontWeight: 400 }}>
              {order.fulfillment_status || "Not fulfilled"}
            </span>{" "}
            financial status :{" "}
            <span style={{ fontWeight: 400 }}>{order.financial_status}</span>
          </h3>
          <h3>
            Order Date :{" "}
            <span style={{ fontWeight: 400 }}>{order.created_at}</span>{" "}
          </h3>
          <h3>
            Tags :{" "}
            <span style={{ fontWeight: 400 }}>{order.tags || "No Tags"}</span>{" "}
          </h3>
          {order.financial_status === "paid" &&
            (order.fulfillment_status === "partial" ||
              order.fulfillment_status === "fulfilled") && (
              <h3 style={{ fontWeight: 400 }}>
                Cannot cancel a paid and partial/fulfilled order
              </h3>
            )}
          {order.cancelled_at ? (
            <Stack direction={"row"} gap={2}>
              <h3>
                Cancelled at :{" "}
                <span style={{ fontWeight: 400 }}>{order.cancelled_at}</span>
              </h3>
              <h3>
                Cancelled Reason :{" "}
                <span style={{ fontWeight: 400 }}>{order.cancel_reason}</span>
              </h3>
            </Stack>
          ) : (
            <div>
              {openCancelLoading ? (
                <CircularProgress size={20} />
              ) : (
                <Button
                  sx={{
                    my: 1,
                    display:
                      (order.financial_status === "paid" &&
                        (order.fulfillment_status === "partial" ||
                          order.fulfillment_status === "fulfilled")) ||
                      order.tags.includes("manual_cancel")
                        ? "none"
                        : "block",
                  }}
                  variant="outlined"
                  color="error"
                  onClick={(e) => handlePriorityMenuClick(e)}
                >
                  Cancel
                </Button>
              )}
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openPriorityMenu}
                onClose={(e) => {
                  handlePriorityMenuClose(e);
                }}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {["customer", "inventory", "fraud", "declined", "other"].map(
                  (p, index) => (
                    <MenuItem
                      onClick={(e) => {
                        handleClose(p);

                        handlePriorityMenuClose(e);
                      }}
                    >
                      {p}
                    </MenuItem>
                  )
                )}
              </Menu>
            </div>
          )}
          <h3>Vouchers : {order.vouchers.length === 0 && "None"}</h3>
          {order.vouchers.map((v, i) => (
            <Stack mx={3} gap={1} direction={"column"}>
              <h3 style={{ fontWeight: 400 }}>
                {i + 1}. {v}
              </h3>
            </Stack>
          ))}
          <Stack direction={"row"}>
            <Box>
              <TableContainer component={Paper}>
                <Table aria-label="support table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Fulfillment Id</TableCell>
                      <TableCell align="left">package status</TableCell>
                      <TableCell align="left">AWB</TableCell>
                      <TableCell align="center">Items</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order.order_items.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{row.fulfillment_id}</TableCell>
                        <TableCell>{row.package_status}</TableCell>
                        <TableCell
                          onClick={() => handleOpenHistory(row.awb_code)}
                        >
                          <Box
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                            {row.awb_code || "Not given"}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Table aria-label="support table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left">name</TableCell>
                                <TableCell align="left">sku</TableCell>
                                <TableCell align="left">size</TableCell>
                                <TableCell align="left">quantity</TableCell>
                                <TableCell align="left">
                                  fulfillment status
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {row.line_items.map((row) => (
                                <TableRow
                                  key={row.id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell>
                                    <img
                                      style={{
                                        width: "100px",
                                        objectFit: "contain",
                                      }}
                                      src={row.url}
                                      alt=""
                                    />
                                  </TableCell>
                                  <TableCell>{row.name}</TableCell>
                                  <TableCell>{row.sku}</TableCell>
                                  <TableCell>{row.variant_title}</TableCell>
                                  <TableCell>{row.quantity}</TableCell>
                                  <TableCell>
                                    {row.fulfillment_status || "Not Fulfilled"}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            {/* s */}
          </Stack>
        </Box>
      ) : (
        ""
      )}
      {orderHistoryModal ? (
        <DelhiveryOrderHistory
          open={orderHistoryModal}
          setOrderHistoryModal={setOrderHistoryModal}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default CustomerOrderList;
