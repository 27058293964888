import { toast } from "react-toastify";
import { ClientId } from "../utils/UserPool";
import { showModal, showUnauthorizedModal } from "./errorModalActions";
import { lambdaFetch } from "../utils/awsUtils";
import {
    hideFullScreenLoader,
    hideLoader,
    showFullScreenLoader,
    showLoader,
} from "./loaderActions";

const lastAuthUser = localStorage.getItem(
    "CognitoIdentityServiceProvider." + ClientId + ".LastAuthUser"
);
const tokenName =
    "CognitoIdentityServiceProvider." +
    ClientId +
    "." +
    lastAuthUser +
    ".idToken";
const IDtoken = localStorage.getItem(tokenName);

export const showLoading = () => {
    return {
        type: "SHOW_LOADER",
    };
};

export const hideLoading = () => {
    return {
        type: "HIDE_LOADER",
    };
};

export const productUploadSuccess = (data) => {
    return {
        type: "UPLOADED_PRODUCT",
        data,
    };
};

const updateStock = (data) => {
    return {
        type: "UPDATED_INVENTORY_STOCK",
        data,
    };
};

// export const allInventory = () => {
//   async function getAllInventory(IDtoken) {
//     const URL =
//       "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/inventory/readproducts";

//     const response = await lambdaFetch(URL, {
//       method: "POST",
//       body: JSON.stringify({ company: "LB" }),
//     });
//     if (response.status === 403) {
//       return 403;
//     }
//     return await response.json();
//   }
//   return (dispatch) => {
//     dispatch(showLoading());
//     getAllInventory(IDtoken)
//       .then((data) => {
//         if (data === 403) {
//           dispatch(hideLoading());
//           dispatch(showUnauthorizedModal());
//           return;
//         }
//         if (data.statusCode === 200) {
//           dispatch({
//             type: "ALL_INVENTORY_STOCK",
//             data,
//           });
//         } else {
//           dispatch(showModal());
//           toast.error("Error In Loading Inventory Stock");
//         }
//         dispatch(hideLoading());
//       })
//       .catch((error) => {
//         console.log("Error in getting inventory", error);
//         dispatch(hideLoading());
//         dispatch(showModal());
//         toast.error("Something Went Wrong With Connecting To Server", {
//           theme: "colored",
//         });
//       });
//   };
// };

export const allInventory = () => {
    async function getData() {
        const URL =
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/inventory/readproducts";

        let fetching = true;
        let orders = [];
        let key = null;
        while (fetching) {
            const response = await lambdaFetch(URL, {
                method: "POST",

                body: JSON.stringify({ key }),
            });
            if (response.status === 403) {
                fetching = false;
                return 403;
            }
            const data = await response.json();
            if (data.statusCode === 200) {
                const processingData = JSON.parse(data.body);
                orders.push(...processingData);
                key = data.key;
                if (!key) {
                    fetching = false;
                }
            } else {
                orders = [];
                fetching = false;
            }
        }
        return orders;
    }
    return (dispatch) => {
        dispatch(showLoader());
        getData()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.length > 0) {
                    dispatch({
                        type: "ALL_INVENTORY_STOCK",
                        data: data.filter((d) => d.size !== "FS"),
                    });
                    dispatch(hideLoader());
                } else {
                    toast.error("Error In Loading Inventory Stock");
                    dispatch(hideLoader());
                }
            })
            .catch((error) => {
                toast.error("Something Went Wrong", {
                    theme: "colored",
                });
                dispatch(hideLoader());
            });
    };
};

export const addInventoryStock = (products) => {
    const addStock = async (products, IDtoken) => {
        const addStockUrl =
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/inventory/updatestockininventory";

        const response = await lambdaFetch(addStockUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${IDtoken}`,
            },
            body: JSON.stringify(products),
        });
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };

    return (dispatch, getState) => {
        dispatch(showLoading());
        addStock(products, IDtoken)
            .then((data) => {
                if (data === 403) {
                    dispatch(hideLoading());
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    const updatedData = JSON.parse(data.body);
                    const presentInventoryStock =
                        getState().inventoryDetails.stock;
                    const updatedInventoryStock = presentInventoryStock.map(
                        (item) => {
                            const row = updatedData.find(
                                (p) => p.barcode === item.barcode
                            );
                            if (row) {
                                const newItem = {
                                    ...item,
                                    count: row.count,
                                };
                                return newItem;
                            } else {
                                return item;
                            }
                        }
                    );
                    dispatch(updateStock(updatedInventoryStock));
                    dispatch(hideLoading());
                    toast.success("Stock Added Successfully");
                } else {
                    dispatch(hideLoading());
                    // dispatch(showModal());
                    toast.error("Error In Adding Stock");
                }
            })
            .catch((error) => {
                dispatch(hideLoading());
                dispatch(showModal());
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const resetInventoryStock = (products) => {
    const resetStock = async (products, IDtoken) => {
        const updateStockUrl =
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/inventory/resetstockinventory";

        const response = await lambdaFetch(updateStockUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${IDtoken}`,
            },
            body: JSON.stringify(products),
        });
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };

    return (dispatch, getState) => {
        dispatch(showLoading());
        resetStock(products, IDtoken)
            .then((data) => {
                if (data === 403) {
                    dispatch(hideLoading());
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    const updatedData = JSON.parse(data.body);
                    const presentInventoryStock =
                        getState().inventoryDetails.stock;
                    const updatedInventoryStock = presentInventoryStock.map(
                        (item) => {
                            const row = updatedData.find(
                                (p) => p.barcode === item.barcode
                            );
                            if (row) {
                                const newItem = {
                                    ...item,
                                    count: row.count,
                                };
                                return newItem;
                            } else {
                                return item;
                            }
                        }
                    );
                    dispatch(updateStock(updatedInventoryStock));
                    toast.success("Stock Updated Successfully");
                } else {
                    dispatch(showModal());
                    console.log("ERROR", data);
                    toast.error("Error In Updating Stock");
                }
                dispatch(hideLoading());
            })
            .catch((error) => {
                dispatch(hideLoading());
                dispatch(showModal());
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const reduceInventoryStock = (products) => {
    const reduceStock = async () => {
        const Url =
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/reduceStockInventory";

        const response = await lambdaFetch(Url, {
            method: "POST",
            body: JSON.stringify(products),
        });
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };

    return (dispatch, getState) => {
        dispatch(showLoading());
        reduceStock()
            .then((data) => {
                if (data === 403) {
                    dispatch(hideLoading());
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    const updatedData = JSON.parse(data.body);
                    const presentInventoryStock =
                        getState().inventoryDetails.stock;
                    const updatedInventoryStock = presentInventoryStock.map(
                        (item) => {
                            const row = updatedData.find(
                                (p) => p.barcode === item.barcode
                            );
                            if (row) {
                                const newItem = {
                                    ...item,
                                    count: row.count,
                                };
                                return newItem;
                            } else {
                                return item;
                            }
                        }
                    );
                    dispatch(updateStock(updatedInventoryStock));
                    toast.success("Stock Updated Successfully");
                } else {
                    dispatch(showModal());
                    console.log("ERROR", data);
                    toast.error("Error In Updating Stock");
                }
                dispatch(hideLoading());
            })
            .catch((error) => {
                dispatch(hideLoading());
                dispatch(showModal());
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const setImagesinInventory = (imageBody) => {
    const imageUpdate = async () => {
        const Url =
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/updateImageInInventory";

        const response = await lambdaFetch(Url, {
            method: "POST",
            body: JSON.stringify(imageBody),
        });
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };
    return (dispatch) => {
        dispatch(showLoading());
        imageUpdate()
            .then((data) => {
                if (data === 403) {
                    dispatch(hideLoading());
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    toast.success("Image Updated Successfully in Dynamo");
                } else {
                    dispatch(showModal());
                    console.log("ERROR", data);
                    toast.error("Error In Saving Images to Dynamo");
                }
                dispatch(hideLoading());
            })
            .catch((error) => {
                dispatch(hideLoading());
                dispatch(showModal());
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const editInventoryProducts = (orderBody) => {
    console.log("order body", orderBody);
    const reduceStock = async () => {
        const Url =
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/inventory/editproducts";

        const response = await lambdaFetch(Url, {
            method: "POST",
            body: JSON.stringify(orderBody),
        });
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };

    return (dispatch) => {
        dispatch(showFullScreenLoader());
        reduceStock()
            .then((data) => {
                if (data.statusCode === 200) {
                    toast.success("Product Updated Successfully");
                } else {
                    toast.error("Error In Updating Product");
                }
                dispatch(hideFullScreenLoader());
            })
            .catch((error) => {
                console.log(error);
                dispatch(hideFullScreenLoader());
                toast.error("Something Went Wrong", {
                    theme: "colored",
                });
            });
    };
};

export const getDesignList = async () => {
    const URL =
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/slider";
    let res = {};
    try {
        const response = await fetch(URL, {
            method: "GET",
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();
        if (data.statusCode === 200) {
            console.log("Data", data);
            res = {
                statusCode: 200,
                data: data.data,
            };
        } else {
            res = { statusCode: 500 };
        }
    } catch (error) {
        res = { statusCode: 500 };
        toast.error("something went wrong connecting to the database");
    }
    return res;
};
export const getAmzDate = (dateStr) => {
    const chars = [":", "-"];
    for (let i = 0; i < chars.length; i++) {
        while (dateStr.indexOf(chars[i]) !== -1) {
            dateStr = dateStr.replace(chars[i], "");
        }
    }
    dateStr = dateStr.split(".")[0] + "Z";
    return dateStr;
};

export const uploadDesignImages = async (images, designName) => {
    const URL =
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/slider/images";
    let res = {};
    let imgArray = [];
    for (let i = 0; i < images.length; i++) {
        console.log({
            dataUrl: images[i].imgB64,
            extension:
                String(images[i].type).split("/")[1] === "jpeg"
                    ? "jpg"
                    : String(images[i].type).split("/")[1],
            productId:
                getAmzDate(new Date().toISOString()) +
                "-" +
                String(designName).split(" ").join("-") +
                "-" +
                (i + 1),
        });
        try {
            const response = await fetch(URL, {
                method: "POST",
                body: JSON.stringify({
                    dataUrl: images[i].imgB64,
                    type: images[i].type,
                    extension:
                        String(images[i].type).split("/")[1] === "jpeg"
                            ? "jpg"
                            : String(images[i].type).split("/")[1],
                    productId:
                        getAmzDate(new Date().toISOString()) +
                        "-" +
                        String(designName).replace(" ", "-") +
                        "-" +
                        (i + 1),
                }),
            });
            const data = await response.json();
            console.log("data img", data);
            if (data.statusCode === 200) {
                imgArray.push(data.url);
            } else {
                toast.error("images couldn't be uploaded");
            }
        } catch (error) {
            toast.error("something went wrong connecting to the database");
        }
    }
    if (imgArray.length === 4) {
        res = { statusCode: 200, data: imgArray };
    } else {
        res = { statusCode: 500 };
    }
    return res;
};

export const uploadDesign = async (payload) => {
    const URL =
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/slider";
    let res = {};
    try {
        const response = await fetch(URL, {
            method: "PUT",
            body: JSON.stringify({ data: payload }),
        });

        const data = await response.json();
        if (data.statusCode === 200) {
            res = {
                statusCode: 200,
                data: data.body,
            };
        } else {
            res = { statusCode: 500 };
            toast.error("error uploading");
        }
    } catch (error) {
        res = { statusCode: 500 };
        toast.error("something went wrong connecting to the database");
    }
    return res;
};

export const getDesignLikedCustomers = async () => {
    const URL =
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/slider/customers";

    let fetching = true;
    let customers = [];
    const dateSplit = new Date().toISOString().split("T")[0].split("-");
    const date = dateSplit[0] + "-" + dateSplit[1];
    console.log("date", date);
    let LastEvaluatedKey = null;
    while (fetching) {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify({ date, LastEvaluatedKey }),
        });
        const data = await response.json();
        console.log("data", data);
        if (data.statusCode === 200) {
            const processingData = data.body;
            customers.push(...processingData);
            LastEvaluatedKey = data.LastEvaluatedKey;
            if (!LastEvaluatedKey) {
                fetching = false;
            }
        } else {
            customers = [];
            fetching = false;
        }
    }
    return customers;
};

export const deleteProductFromDb = (sku) => async (dispatch, getState) => {
    const URL =
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/inventory/deletedbproduct";
    const state = getState();
    const inventory = state.inventoryDetails.stock;
    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify({ sku }),
        });
        const data = await response.json();
        if (data.statusCode === 200) {
            toast.success("Product deleted from database");
            const newInventory = inventory.filter((i) => i.SKU !== sku);
            dispatch({
                type: "ALL_INVENTORY_STOCK",
                data: newInventory,
            });
        } else {
            toast.error("failed to delete product from database");
        }
    } catch (e) {
        console.log("ERROR lambda", e);
        toast.error("Something Went Wrong With Connecting To Server", {
            theme: "colored",
        });
    }
};
export const deleteProductFromShopifyAndDb = (sku, id) => async (dispatch) => {
    // first delete from shopify
    const URL =
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/inventory/deleteshopifyproduct";

    try {
        if (id) {
            const response = await lambdaFetch(URL, {
                method: "POST",
                body: JSON.stringify({ id }),
            });
            if (response.status === 403) {
                return 403;
            }
            const data = await response.json();
            console.log("Data", data);
            if (data.statusCode === 200) {
                toast.success("Product deleted from Shopify");
                dispatch(deleteProductFromDb(sku));
            } else {
                toast.error("Failed to delete product");
            }
        } else {
            toast.error("product does not exist in shopify, trying Database");
            dispatch(deleteProductFromDb(sku));
        }
    } catch (e) {
        console.log("ERROR lambda", e);
        toast.error("Something Went Wrong With Connecting To Server", {
            theme: "colored",
        });
    }
};

export const addInventoryStockFromVendorList = async (products) => {
    const addStockUrl =
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/inventory/updatestockininventory";

    const response = await lambdaFetch(addStockUrl, {
        method: "POST",
        body: JSON.stringify(products),
    });
    const data = await response.json();
    if (data.statusCode === 200) {
        toast.success("Stock Added Successfully");
        return { statusCode: 200 };
    } else {
        toast.success("Something went wrong");
        return { statusCode: 500 };
    }
};


//GET Opening stock details (last day closing is opening for today)

export const getTodayStockOpeningDetails = async (filepath) => {
    try {
      const todayDate = new Date('2025-01-24')
      // const todayDate = new Date();
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = todayDate.getMonth();
      const monthName = months[month];
      const year = todayDate.getFullYear();
      const folderPath = `${monthName}, ${year}`;
      const fileName = todayDate.getDate();
  
      const generatedFilePath = `${folderPath}/${fileName}`;
    //   console.log("Generated folder path and file name:", folderPath, fileName);
  
      const filePathToUse = filepath || generatedFilePath;
    //   console.log("Using file path:", filePathToUse);
  
    //   console.log("Fetching stock opening details...");
      const response = await lambdaFetch(
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/recon/inventorydetails",
        {
          method: "POST",
          body: JSON.stringify({
            filepath: filePathToUse,
          }),
        }
      );
  
      const data = await response.json();
    //   console.log("Stock Opening Fetched Data:", data);
  
      if (data.statusCode === 200) {
        return {
          statusCode: 200,
          body: data.body,
        };
      } else {
        toast.error("Failed to fetch stock opening details.");
        return {
          statusCode: 500,
        };
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong while fetching stock opening details.");
      return {
        statusCode: 500,
      };
    }
  };
