import { lambdaFetch } from "../utils/awsUtils";
import { showUnauthorizedModal } from "./errorModalActions";
import { toast } from "react-toastify";
import { hideLoading, showLoading } from "./loaderActions";
import { sendTicketResponseToMail } from "./SESActions";

export const getAllTickets = (status) => async (dispatch) => {
  const URL =
    "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/tickets/getallticketsbystatus";
  dispatch(showLoading());
  let payload = {
    status,
    LastEvaluatedKey: null,
  };
  let fetching = true;
  let allTickets = [];
  try {
    while (fetching) {
      console.log("ticket payload", payload);
      const response = await lambdaFetch(URL, {
        method: "POST",

        body: JSON.stringify(payload),
      });
      if (response.status === 403) {
        fetching = false;
        dispatch(hideLoading());
        dispatch(showUnauthorizedModal());
        return;
      }
      const data = await response.json();

      dispatch(hideLoading());
      if (data.statusCode === 200) {
        allTickets.push(...data.data);
        payload.LastEvaluatedKey = data.LastEvaluatedKey;
        if (!payload.LastEvaluatedKey) {
          fetching = false;
        }

        toast.success("Fetched tickets Successfully.");
      } else {
        console.log("Data", data);
        fetching = false;
        allTickets = [];
        toast.error(`Server Error ${data.statusCode}`, {
          theme: "colored",
        });
      }
    }
    dispatch({
      type: "ALL_TICKETS",
      data: allTickets,
    });
  } catch (err) {
    dispatch(hideLoading());
    console.log("here", err);
    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};

export const getTicketsByExecutiveId = (name) => async (dispatch, getState) => {
  const URL =
    "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/tickets/assignedtickets";
  dispatch(showLoading());
  const state = getState();
  const user = state.auth.user;
  let payload = {
    company: "LBI_TICKETS",
    name: user + "#active",
    LastEvaluatedKey: null,
  };

  let fetching = true;
  let allTickets = [];
  try {
    while (fetching) {
      const response = await lambdaFetch(URL, {
        method: "POST",

        body: JSON.stringify(payload),
      });
      if (response.status === 403) {
        fetching = false;
        dispatch(hideLoading());
        dispatch(showUnauthorizedModal());
        return;
      }
      const data = await response.json();

      dispatch(hideLoading());
      if (data.statusCode === 200) {
        allTickets.push(...data.data);
        payload.LastEvaluatedKey = data.LastEvaluatedKey;
        if (!payload.LastEvaluatedKey) {
          fetching = false;
        }

        toast.success("Fetched tickets Successfully.");
      } else {
        fetching = false;
        allTickets = [];
        toast.error(`Server Error ${data.statusCode}`, {
          theme: "colored",
        });
      }
    }
    dispatch({
      type: "EXECUTIVE_TICKETS",
      data: allTickets,
    });
  } catch (err) {
    dispatch(hideLoading());
    console.log("here", err);
    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};

export const getSingleTicket = (ticket) => (dispatch) => {
  dispatch({
    type: "SINGLE_TICKET",
    data: ticket,
  });
};

export const startRetry = () => (dispatch) => {
  dispatch({
    type: "TICKET_RESPONSE_RETRY",
    data: true,
  });
};

export const endRetry = () => (dispatch) => {
  dispatch({
    type: "TICKET_RESPONSE_RETRY",
    data: false,
  });
};

export const openRetryBox = () => (dispatch) => {
  dispatch({
    type: "OPEN_RETRY_BOX",
    data: true,
  });
};

export const closeRetryBox = () => (dispatch) => {
  dispatch({
    type: "OPEN_RETRY_BOX",
    data: false,
  });
};

export const showPriorityLoading = () => {
  return (dispatch) => {
    dispatch({
      type: "SHOW_PRIORITY_LOADER",
    });
  };
};
export const hidePriorityLoading = () => {
  return (dispatch) => {
    dispatch({
      type: "HIDE_PRIORITY_LOADER",
    });
  };
};

export const retryTicketResponse =
  (ticketId, message, email) => async (dispatch) => {
    const URL =
      "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/tickets/sendmessage";
    dispatch(startRetry());

    try {
      const payload = {
        ticketId: ticketId,
        message: {
          sendedBy: "customercare",
          time: new Date().toLocaleString(),
          message,
        },
      };
      const response = await lambdaFetch(URL, {
        method: "POST",

        body: JSON.stringify(payload),
      });
      if (response.status === 403) {
        dispatch(endRetry());
        dispatch(showUnauthorizedModal());
        return;
      }
      const data = await response.json();

      dispatch(endRetry());
      if (data.statusCode === 200) {
        dispatch({
          type: "SINGLE_TICKET",
          data: data.data,
        });
        dispatch(closeRetryBox());
        if (email) {
          dispatch(sendTicketResponseToMail(email, ticketId));
        } else {
          toast.error("Email Invalid/Unsucessful , Not Sent");
        }
        toast.success("Response sended Successfully.");
      } else {
        toast.error(`Server Error ${data.statusCode}`, {
          theme: "colored",
        });
      }
    } catch (err) {
      dispatch(endRetry());
      console.log("here", err);
      toast.error("Something Went Wrong With Connecting To Database", {
        theme: "colored",
      });
    }
  };

export const openTicket =
  (ticketId, ticketNumber, customerId, executiveId) =>
  async (dispatch, getState) => {
    const state = getState();
    const { tickets, executiveTickets } = state.tickets;

    const URL =
      "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/tickets/changeticketstatus";
    dispatch(showLoading());
    try {
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify({
          ticketId,
          ticketNumber,
          status: "open",
          customerId,
          executive_id: executiveId ? executiveId.split("#")[0] : "None",
        }),
      });
      if (response.status === 403) {
        dispatch(hideLoading());
        dispatch(showUnauthorizedModal());
        return;
      }
      const data = await response.json();

      dispatch(hideLoading());
      if (data.statusCode === 200) {
        dispatch({
          type: "SINGLE_TICKET",
          data: data.data,
        });
        const newTickets = tickets.map((t) => {
          if (t.fulfillment_id === data.data.fulfillment_id) {
            t.package_status = "active#open";
          }
          return t;
        });
        const newExecutiveTickets = executiveTickets.map((t) => {
          if (t.fulfillment_id === data.data.fulfillment_id) {
            t.package_status = "active#open";
          }
          return t;
        });

        dispatch({
          type: "ALL_TICKETS",
          data: newTickets,
        });
        dispatch({
          type: "EXECUTIVE_TICKETS",
          data: newExecutiveTickets,
        });
        // toast.success("Fetched tickets Successfully.");
      } else {
        toast.error(`Server Error ${data.statusCode}`, {
          theme: "colored",
        });
      }
    } catch (err) {
      dispatch(hideLoading());
      console.log("here", err);
      toast.error("Something Went Wrong With Connecting To Database", {
        theme: "colored",
      });
    }
  };

export const closeTicket =
  (ticketNumber, ticketId, customerId, executiveId) =>
  async (dispatch, getState) => {
    const state = getState();
    const { tickets, executiveTickets } = state.tickets;
    const URL =
      "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/tickets/changeticketstatus";
    dispatch(showLoading());
    try {
      const response = await lambdaFetch(URL, {
        method: "POST",

        body: JSON.stringify({
          ticketNumber,
          ticketId,
          status: "closed",
          closedBy: "customercare",
          customerId,
          executive_id: executiveId ? executiveId.split("#")[0] : "None",
        }),
      });
      if (response.status === 403) {
        dispatch(hideLoading());
        dispatch(showUnauthorizedModal());
        return;
      }
      const data = await response.json();

      dispatch(hideLoading());
      if (data.statusCode === 200) {
        dispatch({
          type: "SINGLE_TICKET",
          data: data.data,
        });
        const newTickets = tickets.filter(
          (t) => t.fulfillment_id !== data.data.fulfillment_id
        );
        const newExecutiveTickets = executiveTickets.filter(
          (t) => t.fulfillment_id !== data.data.fulfillment_id
        );
        // const newTickets = tickets.map((t) => {
        //   if (t.fulfillment_id === data.data.fulfillment_id) {
        //     t.package_status = "closed";
        //   }
        //   return t;
        // });
        dispatch({
          type: "ALL_TICKETS",
          data: newTickets,
        });
        dispatch({
          type: "EXECUTIVE_TICKETS",
          data: newExecutiveTickets,
        });
        // toast.success("Fetched tickets Successfully.");
      } else {
        toast.error(`Server Error ${data.statusCode}`, {
          theme: "colored",
        });
      }
    } catch (err) {
      dispatch(hideLoading());
      console.log("here", err);
      toast.error("Something Went Wrong With Connecting To Database", {
        theme: "colored",
      });
    }
  };
export const sendTicketUpdateToShopify = (customerId) => async (dispatch) => {
  const URL =
    "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/tickets/customermetafield";
  dispatch(showLoading());
  try {
    const response = await lambdaFetch(URL, {
      method: "POST",
      body: JSON.stringify({ customerId, value: true }),
    });
    if (response.status === 403) {
      dispatch(hideLoading());
      dispatch(showUnauthorizedModal());
      return;
    }
    const data = await response.json();

    dispatch(hideLoading());
    if (data.statusCode === 200) {
      toast.success("Shopify Updated Successfully.");
    } else {
      toast.error(`Server Error, Couldn't Update Shopify ${data.statusCode}`, {
        theme: "colored",
      });
    }
  } catch (err) {
    dispatch(hideLoading());
    console.log("here", err);
    toast.error("Something Went Wrong With Connecting To Shopify", {
      theme: "colored",
    });
  }
};
export const uploadToDrive = async (files) => {
  const file_array = files;
  const promise = file_array.map((file) => {
    return fetch(
      "https://script.google.com/macros/s/AKfycbwETNKQXgeAyyskw35Ec7KBtW0hc95h3IZhUK8lA7hgOA2lt0RROR0LbDtgVhS_1H5u/exec", //your AppsScript URL
      { method: "POST", body: JSON.stringify(file) }
    );
  });
  try {
    const response = await Promise.all(promise).then(async (res) =>
      Promise.all(res.map(async (data) => await data.json()))
    );

    return {
      status: 200,
      data: response,
    };
  } catch (error) {
    console.log("error", error);
    return {
      status: 500,
      error: error,
    };
  }
};
export const sendTicketResponse =
  (ticketId, message, email, files, originalTicketId) =>
  async (dispatch, getState) => {
    const URL =
      "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/tickets/sendmessage";
    dispatch(showLoading());
    try {
      const state = getState();
      const user = state.auth.name;
      const username = state.auth.user;
      const { tickets, executiveTickets } = state.tickets;
      let payloadFiles = [];
      if (files.length > 0) {
        const uploadResponse = await uploadToDrive(files);
        if (uploadResponse.status === 200) {
          payloadFiles.push(...uploadResponse.data);
        } else {
          dispatch(hideLoading());
          dispatch(openRetryBox());
          return;
        }
      }
      const payload = {
        ticketId: ticketId,
        customercareLogs: {
          sendedBy:
            user === "customercare" || user === "cc_supervisor"
              ? username
              : "admin account",
          date: new Date().toLocaleDateString(),
        },
        message: {
          sendedBy: "customercare",
          time: new Date().toLocaleString("en-us"),
          files: payloadFiles,
          message,
        },
      };

      const response = await lambdaFetch(URL, {
        method: "POST",

        body: JSON.stringify(payload),
      });
      if (response.status === 403) {
        dispatch(hideLoading());
        dispatch(showUnauthorizedModal());
        return;
      }
      const data = await response.json();

      dispatch(hideLoading());

      if (data.statusCode === 200) {
        dispatch({
          type: "SINGLE_TICKET",
          data: data.data,
        });
        if (user === "customercare" || user === "cc_supervisor") {
          const filtered = executiveTickets.map((t) => {
            if (t.fulfillment_id === data.data.fulfillment_id) {
              t.messages = data.data.messages;
            }
            return t;
          });
          dispatch({
            type: "EXECUTIVE_TICKETS",
            data: filtered,
          });
        } else {
          const filtered = tickets.map((t) => {
            if (t.fulfillment_id === data.data.fulfillment_id) {
              t.messages = data.data.messages;
            }
            return t;
          });
          dispatch({
            type: "ALL_TICKETS",
            data: filtered,
          });
        }
        if (email) {
          dispatch(
            sendTicketResponseToMail(
              email,
              originalTicketId,
              data.data.customer_id
            )
          );
        } else {
          toast.error("Email Invalid/Unsucessful , Not Sent");
        }
        dispatch(sendTicketUpdateToShopify(data.data.customer_id));
        toast.success("Response sended Successfully.");
      } else {
        dispatch(openRetryBox());
        toast.error(`Server Error ${data.statusCode}`, {
          theme: "colored",
        });
      }
    } catch (err) {
      dispatch(openRetryBox());
      dispatch(hideLoading());
      console.log("here", err);
      toast.error("Something Went Wrong With Connecting To Database", {
        theme: "colored",
      });
    }
  };

export const changeExecutiveIdTickets = (payload) => async (dispatch) => {
  const URL =
    "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/orders/changeexecutiveid";

  dispatch(showLoading());
  console.log("payload", payload);
  try {
    const response = await lambdaFetch(URL, {
      method: "POST",

      body: JSON.stringify(payload),
    });
    if (response.status === 403) {
      dispatch(hideLoading());
      dispatch(showUnauthorizedModal());
      return;
    }
    const data = await response.json();
    console.log(data);
    if (data.statusCode === 200) {
      toast.success("Task Successful");
      dispatch(getAllTickets("All"));
    } else {
      toast.error("Task Failed");
    }

    dispatch(hideLoading());
  } catch (err) {
    dispatch(hideLoading());
    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};

export const searchTicket = async (ticketId) => {
  let res;
  try {
    const response = await fetch(
      `https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/tickets/getsingleticket`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ ticketId }),
      }
    );

    const data = await response.json();
    if (data.statusCode === 200) {
      res = {
        status: 200,
        data: data.data,
      };
    } else {
      res = {
        status: 500,
      };
    }
  } catch (err) {
    res = { status: 500 };
  }

  return res;
};

export const changePriority =
  (ticketId, priority) => async (dispatch, getState) => {
    const state = getState();
    const { tickets, executiveTickets } = state.tickets;

    const URL =
      "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/tickets/changepriority";
    dispatch(showPriorityLoading());
    try {
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify({
          ticketId,
          priority,
        }),
      });
      if (response.status === 403) {
        dispatch(hidePriorityLoading());
        dispatch(showUnauthorizedModal());
        return;
      }
      const data = await response.json();

      dispatch(hidePriorityLoading());
      if (data.statusCode === 200) {
        const newTickets = tickets.map((t) => {
          if (t.fulfillment_id === data.data.fulfillment_id) {
            t.priority = data.data.priority;
          }
          return t;
        });
        const newExecutiveTickets = executiveTickets.map((t) => {
          if (t.fulfillment_id === data.data.fulfillment_id) {
            t.priority = data.data.priority;
          }
          return t;
        });

        dispatch({
          type: "ALL_TICKETS",
          data: newTickets,
        });
        dispatch({
          type: "EXECUTIVE_TICKETS",
          data: newExecutiveTickets,
        });
        // toast.success("Fetched tickets Successfully.");
      } else {
        toast.error(`Server Error ${data.statusCode}`, {
          theme: "colored",
        });
      }
    } catch (err) {
      dispatch(hidePriorityLoading());
      console.log("here", err);
      toast.error("Something Went Wrong With Connecting To Database", {
        theme: "colored",
      });
    }
  };

export const cancelOrder = async (orderId, reason) => {
  let res;
  try {
    const response = await fetch(
      `https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/tickets/cancelorder`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ orderId, reason }),
      }
    );

    const data = await response.json();
    console.log("customer ", data);
    if (data.statusCode === 422) {
      toast.error("Fulfill from shopify");
    }
    if (data.statusCode === 200 || data.statusCode === 201) {
      res = {
        status: 200,
        data: data.data,
      };
    } else {
      res = {
        status: 500,
      };
    }
  } catch (err) {
    res = { status: 500 };
  }

  return res;
};

export const get15daysticket = () => async (dispatch) => {
  const URL =
    "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/tickets/getticketsbycreatedat";
  dispatch(showLoading());
  const status = ["active#open", "active#new", "closed"];
  let allTickets = [];

  try {
    for (let i = 0; i <= 2; i++) {
      let fetching = true;
      console.log("status", status[i]);
      let payload = {
        status: status[i],
        LastEvaluatedKey: null,
      };
      console.log("payload", payload);
      while (fetching) {
        const response = await lambdaFetch(URL, {
          method: "POST",

          body: JSON.stringify(payload),
        });

        if (response.status === 403) {
          fetching = false;
          dispatch(hideLoading());
          dispatch(showUnauthorizedModal());
          return;
        }
        const data = await response.json();
        console.log("data", data);
        if (data.statusCode === 200) {
          allTickets.push(...data.data);
          console.log("all tickets", allTickets);
          payload.LastEvaluatedKey = data.LastEvaluatedKey;
          if (!payload.LastEvaluatedKey) {
            fetching = false;
          }

          toast.success("Fetched tickets Successfully.");
        } else {
          fetching = false;
          allTickets = [];
          console.log(data);
          toast.error(`Server Error ${data.statusCode}`, {
            theme: "colored",
          });
        }
      }
    }

    dispatch({
      type: "OLD_TICKETS",
      data: allTickets,
    });
    dispatch(hideLoading());
  } catch (err) {
    dispatch(hideLoading());
    console.log("here", err);
    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};

export const getAutomaticAssignActiveUsers = async () => {
  let res;
  try {
    const response = await fetch(
      `https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/tickets/automaticassign`,
      {
        method: "POST",
        // headers: {
        //   "content-type": "application/json",
        // },
        body: JSON.stringify({
          Action: "GET_ACTIVE_USERS",
        }),
      }
    );

    const data = await response.json();
    if (data.statusCode === 200) {
      res = {
        status: 200,
        data: data.body,
      };
    } else {
      res = {
        status: 500,
      };
    }
  } catch (err) {
    res = { status: 500 };
  }

  return res;
};

export const updateAutomaticAssignActiveUsers = async (payload) => {
  let res;
  try {
    const response = await fetch(
      `https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/tickets/automaticassign`,
      {
        method: "POST",
        // headers: {
        //   "content-type": "application/json",
        // },
        body: JSON.stringify({
          Action: "UPDATE_ACTIVE_USERS",
          payload: payload,
        }),
      }
    );

    const data = await response.json();
    console.log("data", {
      Action: "UPDATE_ACTIVE_USERS",
      payload: payload,
    });
    if (data.statusCode === 200) {
      res = {
        status: 200,
        data: data.body,
      };
    } else {
      toast.error("Error Changing Status");
      res = {
        status: 500,
      };
    }
  } catch (err) {
    toast.error("Server Error");
    res = { status: 500 };
  }

  return res;
};

export const get7daysticketByStatus = async (status) => {
  try {
    let fetching = true;
    let LastEvaluatedKey = null;
    let all7daystickets = [];

    while (fetching) {
      const response = await lambdaFetch(
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/ticketreport/sevendays",
        {
          method: "POST",
          body: JSON.stringify({
            status: status,
            LastEvaluatedKey,
          }),
        }
      );
      // console.log(response);
      const data = await response.json();
      // console.log("data",data);
      if (data.statusCode === 200) {
        LastEvaluatedKey = data.LastEvaluatedKey;

        if (!LastEvaluatedKey) {
          fetching = false;
        }
        all7daystickets = [...all7daystickets, ...data.data];
      } else {
        toast.error("Something went wrong");
        return {
          statusCode: 500,
        };
      }
    }
    return {
      statusCode: 200,
      body: all7daystickets,
    };
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong");
    return {
      statusCode: 500,
    };
  }
};

export const getAll7daysticket = async () => {
  try {
    const statusList = [
      // "active#new",
      "active#open",
      // "active#reopen",
      "closed",
    ];
    let allData = [];
    for (let i = 0; i < statusList.length; i++) {
      const data = await get7daysticketByStatus(statusList[i]);
      console.log(statusList[i], data);
      if (data.statusCode === 200) {
        allData = [...allData, ...data.body];
        // console.log("allData",allData);
      } else {
        toast.error("Something went wrong");
        return { statusCode: 500 };
      }
    }
    toast.success("Fetched Successfully");
    return {
      statusCode: 200,
      body: allData,
    };
  } catch (error) {
    toast.error("Something went wrong");
    console.log(error);
    return {
      statusCode: 500,
    };
  }
};

export const reopenTicket = (ticket) => async (dispatch, getState) => {
  const state = getState();
  const { tickets, executiveTickets } = state.tickets;
  const URL =
    "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/tickets/changeticketstatus";
  try {
    dispatch(showLoading());
    const payload = {
      ticketId: ticket.original_order_id,
      ticketNumber: ticket.fulfillment_id,
      status: "reopen",
      customerId: ticket.customer_id,
      executive_id: ticket.executive_id
        ? ticket.executive_id.split("#")[0]
        : "None",
    };
    const response = await fetch(URL, {
      method: "POST",
      body: JSON.stringify(payload),
    });
    const data = await response.json();

    dispatch(hideLoading());
    if (data.statusCode === 200) {
      dispatch({
        type: "SINGLE_TICKET",
        data: data.data,
      });
      const newTickets = [...tickets, data.data];
      const newExecutiveTickets = [...executiveTickets, data.data];
      dispatch({
        type: "ALL_TICKETS",
        data: newTickets,
      });
      dispatch({
        type: "EXECUTIVE_TICKETS",
        data: newExecutiveTickets,
      });
      // toast.success("Fetched tickets Successfully.");
    } else {
      toast.error(`Server Error ${data.statusCode}`, {
        theme: "colored",
      });
    }
  } catch (err) {
    dispatch(hideLoading());
    console.log("here", err);
    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};
