import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  IconButton,
  Box,
  Stack,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ConfirmPopup from "../ordersWidgets/ConfirmPopup";
import { useDispatch } from "react-redux";
import {
  addNoteShopify,
  generateShipRocketOrder,
  getShiprocketToken,
  markStatusDynamo,
  revertFulfillment,
  revertProcessing,
  updateOrderForShopify,
  xpressbessForwardShipment,
} from "../../actions/ordersActions";
import { toast } from "react-toastify";
import { useEffect } from "react";

function OrderDetailBox({ row, save }) {
  const [openEdit, setOpenEdit] = useState(false);
  const [orderDetails, setOrderDetails] = useState(row);
  useEffect(() => {
    setOrderDetails(row);
  }, [row, openEdit]);

  const handleSave = () => {
    // Check if weight is a number
    const weight = parseFloat(orderDetails.weight);
    console.log("WEIGHT", weight);
    if (isNaN(weight)) {
      toast.error("Weight must be a number");
      return;
    } else {
      orderDetails.weight = weight;
      save(orderDetails);
      setOrderDetails((prev) => ({
        ...prev,
        weight,
      }));
    }
  };

  return (
    <Box sx={{ margin: 1 }}>
      <Typography variant="h6" gutterBottom component="div">
        Product Details
      </Typography>
      <Table size="small" aria-label="purchases">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>SKU</TableCell>
            <TableCell align="right">Size</TableCell>
            <TableCell align="right">Quantity</TableCell>
            <TableCell align="right">M.R.P</TableCell>
            <TableCell align="right">Discount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {row.order_items.map((productRow) => (
            <TableRow key={productRow.sku}>
              <TableCell component="th" scope="row">
                {productRow.name}
              </TableCell>
              <TableCell>{productRow.sku}</TableCell>
              <TableCell align="right">{productRow.variant_title}</TableCell>
              <TableCell align="right">{productRow.units}</TableCell>
              <TableCell align="right">{productRow.selling_price}</TableCell>
              <TableCell align="right">{productRow.discount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="flexRow">
        <h4>{openEdit ? "Edit Details" : "Shipping Address:"}</h4>
        <Button
          variant={openEdit ? "outlined" : "contained"}
          color={openEdit ? "error" : "success"}
          onClick={() => {
            setOpenEdit((prev) => !prev);
          }}
        >
          {openEdit ? "Cancel" : "Edit details"}
        </Button>
        {/* <Button
        variant="contained"
        color="success"
        onClick={() => {
          handleEdit();
        }}
      >
        Edit Address for Shiprocket
      </Button> */}
      </div>
      {openEdit ? (
        <Stack direction={"column"} gap={2}>
          <Stack direction={"row"} gap={3}>
            <TextField
              value={orderDetails.shipping_customer_name}
              onChange={(e) => {
                setOrderDetails((prev) => ({
                  ...prev,
                  shipping_customer_name: e.target.value,
                  billing_customer_name: e.target.value,
                }));
              }}
              label="First Name"
              variant="standard"
            />
            <TextField
              value={orderDetails.shipping_last_name}
              onChange={(e) => {
                setOrderDetails((prev) => ({
                  ...prev,
                  shipping_last_name: e.target.value,
                  billing_last_name: e.target.value,
                }));
              }}
              label="Last Name"
              variant="standard"
            />
          </Stack>
          <TextField
            value={orderDetails.shipping_address}
            onChange={(e) => {
              setOrderDetails((prev) => ({
                ...prev,
                shipping_address: e.target.value,
                billing_address: e.target.value,
              }));
            }}
            label="Address"
            variant="standard"
          />
          <TextField
            value={orderDetails.shipping_address_2}
            onChange={(e) => {
              setOrderDetails((prev) => ({
                ...prev,
                shipping_address_2: e.target.value,
                billing_address_2: e.target.value,
              }));
            }}
            label="Address 2"
            variant="standard"
          />
          <Stack direction={"row"} gap={3}>
            <TextField
              value={orderDetails.shipping_phone}
              onChange={(e) => {
                setOrderDetails((prev) => ({
                  ...prev,
                  shipping_phone: e.target.value,
                  billing_phone: e.target.value,
                }));
              }}
              label="Phone"
              variant="standard"
            />
            <TextField
              value={orderDetails.shipping_city}
              onChange={(e) => {
                setOrderDetails((prev) => ({
                  ...prev,
                  shipping_city: e.target.value,
                  billing_city: e.target.value,
                }));
              }}
              label="City"
              variant="standard"
            />
            <TextField
              value={orderDetails.shipping_state}
              onChange={(e) => {
                setOrderDetails((prev) => ({
                  ...prev,
                  shipping_state: e.target.value,
                  billing_state: e.target.value,
                }));
              }}
              label="State"
              variant="standard"
            />
            <TextField
              value={orderDetails.shipping_pincode}
              onChange={(e) => {
                setOrderDetails((prev) => ({
                  ...prev,
                  shipping_pincode: e.target.value,
                  billing_pincode: e.target.value,
                }));
              }}
              label="Pincode"
              variant="standard"
            />
          </Stack>
          <Stack direction={"row"} gap={3}>
            <TextField
              value={orderDetails.length}
              onChange={(e) => {
                setOrderDetails((prev) => ({
                  ...prev,
                  length: e.target.value,
                }));
              }}
              label="Length"
              variant="standard"
            />
            <TextField
              value={orderDetails.breadth}
              onChange={(e) => {
                setOrderDetails((prev) => ({
                  ...prev,
                  breadth: e.target.value,
                }));
              }}
              label="Breadth"
              variant="standard"
            />
            <TextField
              value={orderDetails.height}
              onChange={(e) => {
                setOrderDetails((prev) => ({
                  ...prev,
                  height: e.target.value,
                }));
              }}
              label="Height"
              variant="standard"
            />
            <TextField
              value={orderDetails.weight}
              onChange={(e) => {
                setOrderDetails((prev) => ({
                  ...prev,
                  weight: e.target.value,
                }));
              }}
              label="Weight"
              variant="standard"
            />
          </Stack>
          <Button
            sx={{ margin: "15px 0" }}
            variant="contained"
            onClick={handleSave}
          >
            Save
          </Button>
        </Stack>
      ) : (
        <p>
          Name: {row.billing_customer_name + " " + row.billing_last_name}
          <br />
          Mobile: {row.shipping_phone}
          <br />
          Address: {row.shipping_address}
          <br />
          {row.shipping_address_2}
          <br />
          {row.shipping_city}
          <br />
          {row.shipping_country}
          <br />
          {row.shipping_pincode}
        </p>
      )}
    </Box>
  );
}

function OrderActionsSelector({ allOrders, orders, reset }) {
  const [menuArr, setMenuArr] = useState([]);
  const [popupVisibleRevertFulfillment, setPopupVisibleRevertFulfillment] =
    useState(false);
  const [popupVisibleCancelProcessing, setPopupVisibleCancelProcessing] =
    useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("ShiprocketToken"))
      dispatch(getShiprocketToken());
  }, []);
  const revertFulfillmentAndDelete = () => {
    // revert fulfillment
    dispatch(revertFulfillment(orders[0], false));
    setPopupVisibleRevertFulfillment(false);
    reset([]);
  };
  const deleteProcessing = () => {
    // delete processing
    dispatch(revertProcessing(orders[0]));
    setPopupVisibleCancelProcessing(false);
    reset([]);
  };

  const handleChange = (e) => {
    console.log("option", e.target.value);
    if (e.target.value === 1) {
      // xb dispatch all
      dispatch(xpressbessForwardShipment(allOrders));
    } else if (e.target.value === 2) {
      //xb dispatch
      dispatch(xpressbessForwardShipment(orders));
    } else if (e.target.value === 3) {
      //shiprocket dispatch
      const payload = {
        ...orders[0],
        order_id: String(orders[0].fulfillment_id),
      };
      dispatch(generateShipRocketOrder(payload));
    } else if (e.target.value === 4) {
      // PACKET NOT READY
      dispatch(revertFulfillment(orders[0], true));
      reset([]);
    } else if (e.target.value === 5) {
      // mark delivered
      dispatch(markStatusDynamo(orders[0].fulfillment_id, "Delivered"));
      reset([]);
    } else if (e.target.value === 6) {
      // revert fulfillment
      setPopupVisibleRevertFulfillment(true);
    } else if (e.target.value === 7) {
      //manually mark close fulfillment
      dispatch(addNoteShopify(orders[0]));
      reset([]);
    } else {
      // cancel processing
      setPopupVisibleCancelProcessing(true);
    }
  };

  useEffect(() => {
    // [
    //   { value: 1, name: "XB Dispatch All" },
    //   { value: 2, name: "XB Dispatch" },
    //   { value: 3, name: "Shiprocket dispatch" },
    //   { value: 4, name: "Packet not ready" },
    //   { value: 5, name: "Mark delivered" },
    //   { value: 6, name: "Revert Fulfillment" },
    //   { value: 7, name: "Manually Mark Close Fulfillment" },
    //   { value: 8, name: "Cancel Processing" },
    // ]
    if (orders.length === 0) {
      const menu = [{ value: 1, name: "XB Dispatch All" }];
      setMenuArr(menu);
    } else if (orders.length === 1) {
      const menu = [
        { value: 2, name: "XB Dispatch" },
        { value: 3, name: "Shiprocket dispatch" },
        { value: 4, name: "Packet not ready" },
        { value: 5, name: "Mark delivered" },
        { value: 6, name: "Revert Fulfillment" },
        { value: 7, name: "Manually Mark Close Fulfillment" },
        { value: 8, name: "Cancel Processing" },
      ];
      setMenuArr(menu);
    } else {
      const menu = [{ value: 2, name: "XB Dispatch" }];
      setMenuArr(menu);
    }
  }, [orders]);

  return (
    <FormControl variant="outlined" size="small" sx={{ m: 2, minWidth: 120 }}>
      <InputLabel id="demo-simple-select-label">Actions</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={""}
        label="Age"
        onChange={handleChange}
      >
        {menuArr.map((mr) => (
          <MenuItem value={mr.value}>{mr.name}</MenuItem>
        ))}
      </Select>
      <ConfirmPopup
        visible={popupVisibleRevertFulfillment}
        cancel={() => setPopupVisibleRevertFulfillment(false)}
        proceed={revertFulfillmentAndDelete}
      />
      <ConfirmPopup
        visible={popupVisibleCancelProcessing}
        cancel={() => setPopupVisibleCancelProcessing(false)}
        proceed={deleteProcessing}
      />
    </FormControl>
  );
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function Row({ row, selectedOrders, setSelectedOrders, index }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleEdits = (order) => {
    dispatch(updateOrderForShopify(order));
  };
  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
          bgcolor:
            row.status === "fulfilled"
              ? "rgba(145,145,145, 0.2)"
              : index % 2 === 0
              ? "#afd275"
              : "#edf5e1",
        }}
      >
        <Checkbox
          color="primary"
          checked={
            selectedOrders.filter(
              (i) => i.fulfillment_id === row.fulfillment_id
            ).length > 0
              ? true
              : false
          }
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedOrders([...selectedOrders, row]);
            } else {
              setSelectedOrders(
                selectedOrders.filter(
                  (item) => item.fulfillment_id !== row.fulfillment_id
                )
              );
            }
          }}
        />
        <TableCell>{row.awb_code ? row.awb_code : ""}</TableCell>
        <TableCell>{row.fulfillment_id}</TableCell>
        <TableCell>{String(row.package_status).replace("sync", "")}</TableCell>
        <TableCell>{row.payment_method}</TableCell>
        <TableCell>{row.billing_customer_name}</TableCell>
        <TableCell>{row.billing_address}</TableCell>
        <TableCell>{row.billing_state}</TableCell>
        <TableCell>{row.billing_pincode}</TableCell>
        <TableCell>{row.billing_phone}</TableCell>
        <TableCell>
          <Stack direction={"row"} sx={{ flexWrap: 1 }}>
            {row.order_items.map((ot) => (
              <p>{`${ot.sku} -Size: ${ot.variant_title}(${ot.units})`}</p>
            ))}
          </Stack>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <OrderDetailBox row={row} save={handleEdits} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const ProcessOrderTable = ({ orders, name }) => {
  // select orders
  const [selectedOrders, setSelectedOrders] = useState([]);
  // data
  const [data, setData] = useState([]);
  // search
  const [orderSearch, setOrderSearch] = useState("");
  // pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    const filtered = orders.filter((o) =>
      String(o.fulfillment_id).startsWith(orderSearch)
    );
    setData(filtered);
  }, [orderSearch, orders]);
  return (
    <div>
      {/* heading container */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          my: 2,
          py: 1,
          bgcolor: "white",
        }}
      >
        <h2>{name}</h2>
      </Box>
      {/* table container */}
      <TextField
        variant="outlined"
        size="small"
        placeholder="Search Order"
        value={orderSearch}
        onChange={(e) => setOrderSearch(e.target.value)}
      />
      <Box>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <h4>Selected Action : </h4>
          <OrderActionsSelector
            allOrders={data}
            orders={selectedOrders}
            reset={setSelectedOrders}
          />
        </div>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Select</TableCell>
                <TableCell>awb</TableCell>
                <TableCell>fulfillment_id</TableCell>
                <TableCell>packing status</TableCell>
                <TableCell>order type</TableCell>
                <TableCell>shipName</TableCell>
                <TableCell>shipAddress</TableCell>
                <TableCell>shipCity</TableCell>
                <TableCell>shipPincode</TableCell>
                <TableCell>shipMobileNo</TableCell>
                <TableCell>product info</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? data.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : data
              ).map((row, index) => (
                <Row
                  key={index}
                  index={index}
                  row={row}
                  selectedOrders={selectedOrders}
                  setSelectedOrders={setSelectedOrders}
                />
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  style={{ overflow: "inherit" }}
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};

export default ProcessOrderTable;
