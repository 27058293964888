import Filter from "../viewOrderByStatus/Filter";
import React, { useEffect } from "react";
import { useState } from "react";
import {
  delhiveryOrderStatus,
  shiprocketStatus,
  xbOrderStatus,
} from "../../utils/helperArrays";
import {
  getForwardOrdersByStatus,
  getOrdersByMultipleStatus,
} from "../../actions/ordersActions";
import OrderTable from "../viewOrderByStatus/OrderTable";
import SearchBar from "../viewOrderByStatus/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { allInventory } from "../../actions/inventoryActions";

const courier = ["Delhivery", "Shiprocket"];

const ViewOrdersByStatus = () => {
  const [loading, setLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [status, setStatus] = useState("");
  const [date, setDate] = useState({ startDate: "", endDate: "" });
  const [orders, setOrders] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [courierSelected, setCourierSelected] = useState("");
  const { stock } = useSelector((state) => state.inventoryDetails);
  const dispatch = useDispatch();
  useEffect(() => {
    if (stock.length === 0) dispatch(allInventory());
  }, []);
  const handleSearch = async () => {
    setIsEmpty(false);
    setLoading(true);
    console.log("date", date);
    const statuses = orderStatus.find((os) => os.label === status);
    const data = await getOrdersByMultipleStatus(statuses.value, date);
    if (data.statusCode === 200) {
      setOrders(data.data);
      if (data.data.length <= 0) {
        setIsEmpty(true);
      }
    }
    setLoading(false);
  };

  const handleCourierChange = (value) => {
    setCourierSelected(value);
    if (value === "XpressBees") {
      setOrderStatus(xbOrderStatus);
    } else if (value === "Shiprocket") {
      setOrderStatus(shiprocketStatus);
    } else {
      setOrderStatus(delhiveryOrderStatus);
    }
  };

  return (
    <div>
      <SearchBar
        handleCourierChange={handleCourierChange}
        orderStatus={orderStatus}
        courier={courier}
        courierSelected={courierSelected}
        setDate={setDate}
        handleSearch={handleSearch}
        loading={loading}
        setStatus={setStatus}
        status={status}
        ordersLength={orders.length}
      />
      {isEmpty && (
        <div>
          <h3>No orders Found</h3>
        </div>
      )}
      {orders.length > 0 && stock.length > 0 && (
        <div>
          <Filter orders={orders} setTableData={setTableData} />
          <OrderTable tableData={tableData} shippingPartner={courierSelected} />
        </div>
      )}
    </div>
  );
};

export default ViewOrdersByStatus;
