import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  TableSortLabel,
  Paper,
  Box
} from "@mui/material";

const tableHeadings = [
  {
    headingName: "sl no",
    value: "none"
  },
  {
    headingName: "SKU",
    value: "sku"
  },
  {
    headingName: "Size",
    value: "none"
  },
  {
    headingName: "Opening",
    value: "openingStockAmt"
  },
  {
    headingName: "RTO",
    value: "RTOAmt"
  },
  {
    headingName: "R/E",
    value: "REAmt"
  },
  {
    headingName: "PO",
    value: "poAmt"
  },
  {
    headingName: "stock added",
    value: "stockAdded"
  },
  {
    headingName: "damaged",
    value: "DamagedAmt"
  },
  {
    headingName: "Dispatched",
    value: "dispatchedAmt"
  },
  {
    headingName: "closing",
    value: "closing"
  },
  {
    headingName: "Real Inventory",
    value: "inventoryAmt"
  },

]

const TableHeadings = ({ orderBy, order, handleSort }) => {
  const createTableCell = (input) => {
    console.log(input)
    if (input.value === "none") {
      return (<div> {input.headingName}</div>)
    } else {
      return (<TableSortLabel
        active={orderBy === input.value}
        direction={orderBy === input.value ? order : "asc"}
        onClick={() => handleSort(input.value)}
      >
        {input.headingName}
      </TableSortLabel>)
    }
  }

  return (<TableHead>
    <TableRow>
      {
        tableHeadings.map((th, i) => (
          <TableCell key={i}>
            {
              createTableCell(th)
            }
          </TableCell>
        ))
      }
    </TableRow>
  </TableHead>)
}
const Row = ({ row, index, page, rowsPerPage }) => {
  return (
    <TableRow key={index} sx={{
      background : row.isMatched ? "white" : "#faaaaa"
    }}>
      <TableCell>{page * rowsPerPage + index + 1}</TableCell>
      <TableCell>{row.sku}</TableCell>
      <TableCell>{row.size}</TableCell>
      <TableCell>{row.openingStockAmt}</TableCell>
      <TableCell>{row.RTOAmt}</TableCell>
      <TableCell>{row.REAmt}</TableCell>
      <TableCell>{row.poAmt}</TableCell>
      <TableCell>{row.stockAdded}</TableCell>
      <TableCell>{row.DamagedAmt}</TableCell>
      <TableCell>{row.dispatchedAmt}</TableCell>
      <TableCell>{row.closing}</TableCell>
      <TableCell>{row.inventoryAmt}</TableCell>
    </TableRow>
  )
}

const InventoryTable = ({ filteredData }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("sku")
  const [order, setOrder] = useState("asc")

  const [tableData, setTableData] = useState([])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (property) => {
    const isAsc = order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

  };

  useEffect(() => {
    setPage(0)
    const sortedData = [...filteredData].sort((a, b) => {
      if (order === "asc") {
        return a[orderBy] - b[orderBy]
      } else {
        return b[orderBy] - a[orderBy]
      }
    });
    console.log(
      "sorted data", sortedData
    )
    setTableData(sortedData);
  }, [filteredData, orderBy, order])

  return (
    <div>
      <Box sx={{ paddingTop: 4 }}></Box>
      <TableContainer component={Paper} elevation={3} sx={{ padding: 2 }}>
        <Table>
          <TableHeadings order={order} orderBy={orderBy} handleSort={handleSort} />
          <TableBody>
            {tableData.length > 0 ? (
              (rowsPerPage > 0 ? tableData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : tableData
              ).map((row, index) => <Row key={index} row={row} index={index} page={page} rowsPerPage={rowsPerPage} />))
              : (
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    No data available.
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Box />
    </div>
  )
}

export default InventoryTable