import React, { useEffect, useState } from 'react'
import { getOrdersByProcessedAtByDateRange } from '../../actions/ordersActions';
import { getTodayStockOpeningDetails } from '../../actions/inventoryActions';
import { getCurrentDateReconDetails } from '../../actions/reconActions';
import { useSelector, useDispatch } from "react-redux";
import { allInventory } from "../../actions/inventoryActions";
import InventoryManagement from '../inventoryManagement/InventoryManagement';
import { Box, Skeleton, Typography } from '@mui/material';
// const moment = require('moment-timezone');
import moment from 'moment-timezone';
import { getPoSnapshotByDate, getStockSnapshotByDate } from '../../actions/POActions';


// get the start and end date(date starts from 4am to 4am)
const getDateRange = (dateObj) => {
  const timeOnly = moment(dateObj).tz("Asia/Kolkata").format("HH:mm:ss")
  const wholeDate = moment(dateObj).tz("Asia/Kolkata").format()
  const startBuffer = moment("00:00:00", "HH:mm:ss");
  const endBuffer = moment("04:00:00", "HH:mm:ss");
  if (moment(timeOnly, "HH:mm:ss").isBetween(startBuffer, endBuffer)) {
    const oldStartDate = moment(dateObj).tz("Asia/Kolkata").hour(4).minute(0).second(0).format();
    const newStartDate = moment(oldStartDate).subtract(1, "days").format()
    const endDate = moment(dateObj).tz("Asia/Kolkata").hour(4).minute(0).second(0).format();
    // console.log("startDate ...", newStartDate)
    // console.log("endDate ...", endDate)
    return {startDate : newStartDate, endDate}
  }
  const startDate = moment(dateObj).tz("Asia/Kolkata").hour(4).minute(0).second(0).format();
  const endDate = moment(startDate).add(1, "days").format()
  // console.log("startDate", startDate)
  // console.log("endDate", endDate)
  return {startDate, endDate}
}

const InventorySnapshot = () => {
  const {startDate, endDate} = getDateRange(new Date(2025, 2, 13, 11, 0, 0))

  const { stock } = useSelector((state) => state.inventoryDetails);
  const [dispatchedOrders, setDispatchedOrders] = useState([])
  const [openingStock, setOpeningStock] = useState([])
  const [reconDetails, setReconDetails] = useState([])
  const [poStockHistory, setPoStockHistory] = useState([]);
  const [stockHistory , setStockHistory] = useState([])

  const[loading, setLoading] = useState(false)
  const [isError , setIsError] = useState(false)

  const dispatch = useDispatch()

  
  // fetch all the required data Response (PO, DispatchedOrders, ReconDetails, OpeningStock, Inventory)
  const fetchAllData = async () => {
    setLoading(true)
    const todayDispatchedOrdersRes  = await getOrdersByProcessedAtByDateRange(startDate,endDate)
    const openingStockRes = await getTodayStockOpeningDetails();
    const reconDetailsRes = await getCurrentDateReconDetails();
    const poStockHistoryRes = await getPoSnapshotByDate(new Date().toISOString().slice(0, 10))
    const todayStockHistoryRes = await getStockSnapshotByDate(new Date().toISOString().slice(0, 10))
    console.log("reconDetailsRes", reconDetailsRes)

    if(todayStockHistoryRes.statusCode === 500 || todayDispatchedOrdersRes.statusCode === 500 || openingStockRes.statusCode === 500 || reconDetailsRes.statusCode === 500 || poStockHistoryRes.statusCode === 500){
      setIsError(true)
    }else{
      // add data to use state
      setDispatchedOrders(todayDispatchedOrdersRes.data || [])
      setOpeningStock(openingStockRes.body || [])
      setReconDetails(reconDetailsRes.body || [])
      setPoStockHistory(poStockHistoryRes.body || [])
      setStockHistory(todayStockHistoryRes.body || [])
    }

    setLoading(false)
  }

  useEffect(()=> {
    fetchAllData()
    if(stock.length  === 0){
      dispatch(allInventory())
    }
  },[])

  return (
    <div>
      {/* skeleton loader  not only loading when everything is fetched then show table*/}
      {/* if error , show error message */}
      {/* <InventoryManagement inventory = {stock} dispatchedOrders =
      {dispatchedOrders} reconOrders = {reconDetails} openingStock = {openingStock}/> */}

       {/* Skeleton Loader while fetching */}
       {loading && (
        <>
          <Skeleton variant="rectangular" height={50} sx={{ marginBottom: 2 }} />
          <Skeleton variant="rectangular" height={300} />
        </>
      )}

      {/* Error Message */}
      {isError && !loading && (
        <Box textAlign="center" p={3} border={1} borderColor="error.main" borderRadius={2}>
          <Typography color="error" variant="h6">Error fetching data. Please try again later.</Typography>
        </Box>
      )}

      {/* Show table only when data is loaded */}
      {!loading && !isError && (
        <InventoryManagement 
          inventory={stock} 
          dispatchedOrders={dispatchedOrders} 
          reconOrders={reconDetails} 
          openingStock={openingStock} 
          poStockHistory = {poStockHistory}
          stockHistory = {stockHistory}
        />
      )}
    </div>
  )
}

export default InventorySnapshot