import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route } from "react-router-dom";
import LoginRoutes from "./utils/LoginRoutes";
import { loadUser } from "./actions/authActions";

import Layout from "./components/layout/Layout";

import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import About from "./components/About";
import ForwardShipmentSync from "./components/pages/ForwardShipmentSync";
import AnalyticsSync from "./components/pages/AnalyticsSync";
import ReverseShipmentSync from "./components/pages/ReverseShipmentSync";
import ReverseMain from "./components/reverse/ReverseMain";
import ReverseDelhivery from "./components/reverse/ReverseDelhivery";
import ReverseDelivered from "./components/reverse/ReverseDelivered";

import Accounts from "./components/pages/Accounts";
import Products from "./components/pages/Products";
import Orders from "./components/pages/Orders";
import UnfulfilledOrderProducts from "./components/pages/UnfulfilledOrderProducts";
import Sales from "./components/pages/Sales";
import NewProductSales from "./components/pages/NewProductSales";
import AddProduct from "./components/pages/AddProduct";
import AddProductFromVendor from "./components/pages/AddProductFromVendor";
import AddStock from "./components/pages/AddStock";
import ChangeStock from "./components/pages/ChangeStock";
import Dispatch from "./components/pages/DispatchOrder";
import RevertFulfillment from "./components/pages/RevertFulfillment";
import XpressBeesDispatch from "./components/pages/XpressBeesDispatch";
import ViewOrderList from "./components/pages/AllOrderList";
import NDROrders from "./components/pages/NDROrders";
import ReadyToShipOrders from "./components/pages/ReadyToShipOrders";
import PacketReadyOrders from "./components/pages/PacketReadyOrders";
import ManualDispatch from "./components/pages/ManualDispatch";
import PoProductList from "./components/pages/PoProductList";
import GST from "./components/pages/GST";
import SyncShiprocketDelivered from "./components/pages/SyncShiprocketDelivered";
import RtoOrders from "./components/pages/RtoOrders";
import AwbNotReady from "./components/pages/AwbNotReady";
import NonDeliveryReport from "./components/pages/NonDeliveryReport";
import AllQuery from "./components/pages/AllQuery";
import ExchangeReturn from "./components/pages/ExchangeReturn";
import ApproveReturns from "./components/pages/ApproveReturns";
import DispatchedRE from "./components/pages/DispatchedRE";
import RejectedRE from "./components/pages/RejectedRE";
import ReportsRE from "./components/pages/ReportsRE";
import SingleOrderDispatch from "./components/pages/SingleOrderDispatch";
import MisReportSync from "./components/pages/MisReportSync";
import NdrOnHold from "./components/pages/NdrOnHold";
import { getShiprocketToken } from "./actions/ordersActions";
import "./App.css";
import NdrMisReport from "./components/pages/NdrMisReport";
import AssignRE from "./components/pages/AssignRE";
import Pincodes from "./components/pages/Pincodes";
import AssignNDR from "./components/pages/AssignNDR";
import EmailReport from "./components/pages/EmailReport";
import ShipRocketSync from "./components/pages/ShipRocketSync";
// import RtoMisReport from "./components/pages/RtoMisReportTesting";
import RtoMisReportSync from "./components/pages/RtoMisReportSync";
import CSV2XLSXTesting from "./components/pages/CSV2XLSXTesting";
import RtoXB from "./components/pages/RtoXB";
import CustomerReport from "./components/pages/CustomerReport";
import UnreliableOrders from "./components/pages/UnreliableOrders";
import MobileUReport from "./components/pages/MobileUReport";
import ReverseMisReportSync from "./components/pages/ReverseMisReportSync";
import ReturnXBDelivered from "./components/pages/ReturnXBDelivered";
import NonPickupReport from "./components/pages/NonPickupReport";
import AssignTicket from "./components/ticketWidgets/AssignTicket";
import ReadTicket from "./components/pages/ReadTicket";
import XbGST from "./components/pages/XbGST";
import CreatePO from "./components/pages/CreatePO";
import CreatePOManual from "./components/pages/CreatePOManual";
import DynamoBackup from "./components/pages/DynamoBackup";
import ReverseDetails from "./components/reverse/ReverseDetails";
import TicketsReport from "./components/pages/TicketsReport";
import ReturnReport from "./components/reverse/ReturnReport";
import RtoDelivered from "./components/rto/RtoDelivered";
import EditProducts from "./components/productsWidgets/EditProducts";
import BulkOrderCancelMail from "./components/pages/BulkOrderCancelMail";
import ProductMaterial from "./components/pages/ProductMaterial";
import ShowPO from "./components/pages/ShowPO";
import ProcessedOrders from "./components/pages/ProcessedOrders";
import UpdateDesignPicks from "./components/pages/UpdateDesignPicks";
import GetOrderStatus from "./components/pages/GetOrderStatus";
import ShiprocketReverse from "./components/pages/ShiprocketReverse";
import ViewOrdersByStatus from "./components/pages/ViewOrdersByStatus";
import XBGstByAwbRange from "./components/pages/XBGstByAwbRange";
import MaterialDashboard from "./components/pages/MaterialDashboard";
import ManualExchange from "./components/pages/ManualExchange";
import ShiprocketGst from "./components/pages/ShiprocketGst";
import RawMaterialCalculator from "./components/pages/RawMaterialCalculator";
import DelhiveryDispatch from "./components/pages/DispatchInDelhivery";
import DispatchInDelhivery from "./components/pages/DispatchInDelhivery";
import CancelDelhiveryShipment from "./components/pages/CancelDelhiveryShipment";
import DelhiveryShipmentSync from "./components/pages/DelhiveryShipmentSync";
import DelhiveryGst from "./components/pages/DelhiveryGst";
import CreateRefund from "./components/pages/CreateRefund";
import CreateInvoice from "./components/pages/CreateInvoice";
import StateWiseSales from "./components/pages/StateWiseSales";
import RepeatingProducts from "./components/pages/RepeatingProducts";
import CustomerOrderHistory from "./components/pages/CustomerOrderHistory";
import OrderHistory from "./components/ordersWidgets/customerorderhistory/OrderHistory";
import FabricPO from "./components/pages/FabricPO";
import ReverseReconcilation from "./components/pages/ReverseReconcilation";
import ScanRTO from "./components/reverseRecon/ScanRTO";
import DispatchInShiprocket from "./components/pages/DispatchInShiprocket";
import ShiprocketOrders from "./components/pages/ShiprocketOrders";
import OrderDispatchability from "./components/pages/orderDispatchability";
import InvestorReport from "./components/pages/InvestorReport";
import QuickScan from "./components/reverseRecon/quickScan";
import ManageRto from "./components/rto/ManageRto";
import InventorySnapshot from "./components/pages/InventorySnapshot";
import DispatchesTracker from "./components/pages/DispatchesTracker";

function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadUser());
        dispatch(getShiprocketToken());
    }, [dispatch]);

    return (
        // <Layout>
        <Routes>
            <Route element={<LoginRoutes />}>
                <Route path="/login" element={<Login />} />
            </Route>
            <Route element={<Layout />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/forwardSync" element={<ForwardShipmentSync />} />
                <Route
                    path="/delhiverySync"
                    element={<DelhiveryShipmentSync />}
                />
                <Route path="/reverseSync" element={<ReverseShipmentSync />} />
                <Route path="/analyticsSync" element={<AnalyticsSync />} />
                <Route path="/reverseDetails" element={<ReverseDetails />} />
                <Route path="/reverseMain" element={<ReverseMain />} />
                <Route
                    path="/reverseDelhivery"
                    element={<ReverseDelhivery />}
                />
                <Route
                    path="/reverseDelivered"
                    element={<ReverseDelivered />}
                />
                <Route path="/reverseReport" element={<ReturnReport />} />
                <Route path="/about" element={<About />} exact />
                <Route path="/addProduct" element={<AddProduct />} />
                <Route
                    path="/addProductFromVendor"
                    element={<AddProductFromVendor />}
                />
                <Route path="/orders" element={<Orders />} />
                <Route
                    path="/unfulfilledOrderProducts"
                    element={<UnfulfilledOrderProducts />}
                />
                <Route path="/dispatch" element={<Dispatch />} />
                <Route
                    path="/revertFulfillment"
                    element={<RevertFulfillment />}
                />
                <Route path="/xbDispatch" element={<XpressBeesDispatch />} />
                <Route
                    path="/dispatchInDelhivery"
                    element={<DispatchInDelhivery />}
                />
                <Route
                    path="/dispatchInShiprocket"
                    element={<DispatchInShiprocket />}
                />
                <Route
                    path="/shiprocketOrders"
                    element={<ShiprocketOrders />}
                />
                <Route path="/manualDispatch" element={<ManualDispatch />} />
                <Route
                    path="/shiprocketDispatch"
                    element={<PacketReadyOrders />}
                />
                <Route path="/temp" element={<ManageRto />} />
                <Route path="/editProduct" element={<EditProducts />} />
                <Route path="/syncNdrData" element={<NdrMisReport />} />
                <Route path="/assignNdr" element={<AssignNDR />} />
                <Route
                    path="/nonDeliveryReport"
                    element={<NonDeliveryReport />}
                />

                <Route path="/products" element={<Products />} />
                <Route path="/addStock" element={<AddStock />} />
                <Route path="/updateStock" element={<ChangeStock />} />
                <Route path="/viewOrders" element={<ViewOrderList />} />
                <Route path="/pincodes" element={<Pincodes />} />
                <Route
                    path="/readyToShipOrders"
                    element={<ReadyToShipOrders />}
                />
                <Route path="/ndrOrders" element={<NDROrders />} />
                <Route path="/sales" element={<Sales />} />
                <Route path="/newproductsales" element={<NewProductSales />} />
                <Route path="accounts" element={<Accounts />} />
                <Route path="/poProducts" element={<PoProductList />} />
                <Route path="/gstReport" element={<GST />} />
                <Route path="/xbGst" element={<XbGST />} />
                <Route path="/delhiveryGst" element={<DelhiveryGst />} />
                <Route path="/shiprocketGst" element={<ShiprocketGst />} />
                <Route
                    path="/syncDelivered"
                    element={<SyncShiprocketDelivered />}
                />
                <Route path="/ndrOnHold" element={<NdrOnHold />} />
                <Route path="/rtoOrders" element={<RtoOrders />} />
                <Route path="/awbNotReady" element={<AwbNotReady />} />
                <Route path="/readticket" element={<ReadTicket />} />
                <Route path="/allQuery" element={<AllQuery />} />
                <Route path="/ticketsreport" element={<TicketsReport />} />
                <Route path="/assignticket" element={<AssignTicket />} />
                <Route path="/returnExchange" element={<ExchangeReturn />} />
                <Route
                    path="/singleDispatch"
                    element={<SingleOrderDispatch />}
                />
                <Route
                    path="/approveReturnExchange"
                    element={<ApproveReturns />}
                />
                <Route
                    path="/rejectedReturnExchange"
                    element={<RejectedRE />}
                />
                <Route
                    path="/dispatchedReturnExchange"
                    element={<DispatchedRE />}
                />
                <Route path="/assignReturnExchange" element={<AssignRE />} />
                {/* <Route path="/ReturnExchangeReport" element={<ReportsRE />} /> */}
                <Route
                    path="/ReturnExchangeReport"
                    element={<ReturnReport />}
                />
                <Route path="/syncData" element={<MisReportSync />} />
                <Route path="/syncshiprocket" element={<ShipRocketSync />} />
                <Route path="/emailreport" element={<EmailReport />} />
                <Route path="/customerreport" element={<CustomerReport />} />
                <Route
                    path="/unreliableorders"
                    element={<UnreliableOrders />}
                />
                <Route path="/testing" element={<CSV2XLSXTesting />} />
                <Route path="/syncRtoData" element={<RtoMisReportSync />} />
                <Route path="/xbRto" element={<RtoXB />} />
                <Route
                    path="/mobileunavailablityreport"
                    element={<MobileUReport />}
                />
                <Route
                    path="/syncReverseData"
                    element={<ReverseMisReportSync />}
                />
                <Route
                    path="/xbReturnDelivered"
                    element={<ReturnXBDelivered />}
                />
                <Route path="/nprActions" element={<NonPickupReport />} />
                <Route path="/showPO" element={<ShowPO />} />
                <Route path="/createPO" element={<CreatePO />} />
                <Route path="/createPOManual" element={<CreatePOManual />} />
                <Route path="/backup" element={<DynamoBackup />} />
                <Route
                    path="/bulkordermail"
                    element={<BulkOrderCancelMail />}
                />
                <Route path="/productmaterial" element={<ProductMaterial />} />
                <Route path="/processorders" element={<ProcessedOrders />} />
                <Route
                    path="/updatedesignpicks"
                    element={<UpdateDesignPicks />}
                />
                <Route path="/getorderstatus" element={<GetOrderStatus />} />
                <Route
                    path="/shiprocketreverse"
                    element={<ShiprocketReverse />}
                />
                <Route
                    path="/vieworderbystatus"
                    element={<ViewOrdersByStatus />}
                />
                <Route path="/xbgstbyawbrange" element={<XBGstByAwbRange />} />
                <Route
                    path="/materialdashboard"
                    element={<MaterialDashboard />}
                />
                <Route path="/manualexchange" element={<ManualExchange />} />
                <Route
                    path="/rawmaterialcalculator"
                    element={<RawMaterialCalculator />}
                />
                <Route
                    path="/canceldelhiveryshipment"
                    element={<CancelDelhiveryShipment />}
                />
                <Route
                    path="/canceldelhiveryshipment"
                    element={<CancelDelhiveryShipment />}
                />
                <Route path="/createrefund" element={<CreateRefund />} />
                <Route path="/statewisesales" element={<StateWiseSales />} />
                <Route path="/createinvoice" element={<CreateInvoice />} />
                <Route
                    path="/repeatingproducts"
                    element={<RepeatingProducts />}
                />
                <Route
                    path="/customerorderhistory"
                    element={<CustomerOrderHistory />}
                />
                <Route path="/fabricpo" element={<FabricPO />} />
                <Route
                    path="/reversereconcilation"
                    element={<ReverseReconcilation />}
                />
                <Route path="/scanrto" element={<QuickScan />} />
                <Route path="/markdamaged" element={<ScanRTO />} />
                <Route
                    path="/order-dispatchability"
                    element={<OrderDispatchability />}
                />
                <Route
                    path="/inventorySnapshot"
                    element={<InventorySnapshot />}
                />
                <Route
                    path="/dispatchestracker"
                    element={<DispatchesTracker />}
                />
                <Route path="/investor-report" element={<InvestorReport />} />
            </Route>
        </Routes>
        // </Layout>
    );
}
export default App;
