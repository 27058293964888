import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Backdrop,
  CircularProgress,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Chip,
  TableFooter,
  TablePagination,
  IconButton,
  Avatar,
} from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { toast } from "react-toastify";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import {
  getAllScanSingleDate,
  submitAwbScan,
  submitAwbSuccess,
} from "../../actions/reconActions";
import { updateReconProcessedAt } from "../../actions/reverseDeliveryActions";
import {
  replenishRTOorder,
  updateRtoDeliveredOrders,
} from "../../actions/ordersActions"; //updateShopifyInventory and then replenishRTOorder(orderId)
import { searchAWB } from "../../actions/ordersActions";
import { useTheme } from "@emotion/react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";

const showToastMessage = (msg, isError) => {
  if (isError) {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.success(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <ArrowUpwardIcon />
        ) : (
          <ArrowDownwardIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeftIcon />
        ) : (
          <KeyboardArrowRightIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const QuickScan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();
  const orderData = useSelector((state) => state.recon.reconDetails);
  const { shopify_product_variants } = useSelector(
    (state) => state.shopifyDetails
  );

  const [searchAwbValue, setSearchAwbValue] = useState("");
  const [awbSearchLoading, setAwbSearchLoading] = useState(false);
  const [totalNumScanOrder, setTotalNumScanOrder] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
  const [isFocussed, setIdFocussed] = useState(true);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderData.length) : 0;

  // Ensure that the page is within the valid range
  useEffect(() => {
    const maxPage = Math.max(0, Math.ceil(orderData.length / rowsPerPage) - 1);
    if (page > maxPage) {
      setPage(maxPage);
    }
  }, [orderData.length, rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [orderData]);

  useEffect(() => {
    setTotalNumScanOrder(orderData.length);
  }, [orderData]);

  useEffect(() => {
    const fetchData = () => {
      try {
        // Dispatch the Redux action
        const today = new Date();
        const formattedDate = `${today.getFullYear()}-${String(
          today.getMonth() + 1
        ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;
        dispatch(getAllScanSingleDate(formattedDate));
      } catch (err) {
        console.log("Error", err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (orderData && orderData.length > 0) {
      console.log("scanData", orderData);
    } else {
      showToastMessage("No data available for the given date.", true);
    }
  }, [orderData]);

  // const handleSort = (key) => {
  //   let direction = "asc";
  //   if (sortConfig.key === key && sortConfig.direction === "asc") {
  //     direction = "desc";
  //   }
  //   setSortConfig({ key, direction });
  // };

  // const sortedData = [...orderData].sort((a, b) => {
  //   const valueA = a[sortConfig.key] || "N/A";
  //   const valueB = b[sortConfig.key] || "N/A";
  //   const dateA =
  //     valueA === "N/A" ? Number.MAX_SAFE_INTEGER : new Date(valueA).getTime();
  //   const dateB =
  //     valueB === "N/A" ? Number.MAX_SAFE_INTEGER : new Date(valueB).getTime();

  //   if (sortConfig.direction === "asc") {
  //     return dateA - dateB;
  //   }
  //   return dateB - dateA;
  // });

  const handleScanSubmit = async (e) => {
    e.preventDefault();
    const timeStamp = new Date().toISOString();
    setAwbSearchLoading(true);
    try {
      const order = await submitAwbScan(searchAwbValue, timeStamp);
      // showToastMessage("AWB scanned successfully", false);
      if (!order) {
        return;
      }
      // Dispatch this data to redux
      dispatch(submitAwbSuccess(order));
      console.log("order", order);
      // Increase inventory counts
      dispatch(replenishRTOorder({ id: order?.fulfillment_id }));
      // Re-fetch updated data after submission not required
    } catch (error) {
      showToastMessage("Failed to submit AWB scan", true);
      console.log("Error", error);
    } finally {
      setSearchAwbValue("");
      setAwbSearchLoading(false);
      // ref?.current?.focus();
      setIdFocussed(false);
    }
  };

  useEffect(() => {
    if (!isFocussed) {
      setIdFocussed(true);
      ref?.current?.focus();
    }
  }, [isFocussed]);

  const exportToExcel = async (type) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Scan RTO Data");

    worksheet.columns = [
      { header: "Sl No", key: "sl", width: 10 },
      { header: "AWB", key: "awb", width: 20 },
      { header: "Fulfillment ID", key: "fulfillment_id", width: 20 },
      { header: "Inscan Time", key: "sync_date", width: 20 },
    ];
    const RtoData = orderData.filter((order) => order.typ === type);
    RtoData.forEach((order, index) => {
      worksheet.addRow({
        sl: index + 1,
        awb: order.awb,
        fulfillment_id: order.fulfillment_id,
        sync_date: order.sync_date,
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "ScanRTOData.xlsx");

    showToastMessage("Data exported to Excel successfully", false);
  };

  return (
    <>
      <h2
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        RTO Inscan
      </h2>
      {awbSearchLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={awbSearchLoading}
        >
          <CircularProgress size={100} color="inherit" />
        </Backdrop>
      )}

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mb={3}
        gap={2}
      >
        <form
          onSubmit={handleScanSubmit}
          style={{ display: "flex", alignItems: "center" }}
        >
          <TextField
            placeholder="Scan AWB Here"
            value={searchAwbValue}
            onChange={(e) => setSearchAwbValue(e.target.value)}
            disabled={awbSearchLoading}
            variant="outlined"
            style={{ width: 300, marginRight: "10px" }}
            inputProps={{ autoFocus: true }}
            inputRef={ref}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={awbSearchLoading}
          >
            Submit
          </Button>
        </form>

        <Button
          variant="contained"
          color="error"
          onClick={() => navigate("/temp")}
        >
          Back
        </Button>

        <Paper
          elevation={3}
          style={{
            padding: "10px 20px",
            gap: "8px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Chip
            label={new Date().toLocaleDateString("en-GB")}
            style={{
              textAlign: "center",
              backgroundColor: "red",
              color: "white",
              border: "1px solid",
            }}
          />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TaskAltIcon sx={{ mr: 1 }} />
            <h5>Total Number of Scanned Order: {totalNumScanOrder}</h5>
          </Box>
        </Paper>
      </Box>

      <Paper elevation={3} style={{ gap: "8px" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sl No</TableCell>
                <TableCell>AWB</TableCell>
                <TableCell>Fulfillment ID</TableCell>
                <TableCell>
                  Inscan Time
                  {/* <IconButton onClick={() => handleSort("rto_delivered_on")}>
                    {sortConfig.key === "rto_delivered_on" &&
                    sortConfig.direction === "asc" ? (
                      <ArrowUpwardIcon />
                    ) : (
                      <ArrowDownwardIcon />
                    )}
                  </IconButton> */}
                </TableCell>
                <TableCell>Orderd Items</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading ? (
                (rowsPerPage > 0
                  ? orderData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : orderData
                ).map((order, index) => (
                  <TableRow key={index}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{order.awb_code}</TableCell>
                    <TableCell>{order.fulfillment_id}</TableCell>
                    <TableCell>
                      <span>
                        {new Date(order.processed_at).toLocaleDateString(
                          "en-IN"
                        )}
                      </span>
                      <br />
                      <span>
                        {new Date(order.processed_at).toLocaleTimeString(
                          "en-IN",
                          { timeZone: "Asia/Kolkata" }
                        )}
                      </span>
                    </TableCell>
                    <TableCell
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 0.5,
                      }}
                    >
                      {order?.line_items?.map((row, i) => (
                        <div
                          key={i}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Avatar
                            src={row.url}
                            sx={{
                              width: 60,
                              height: 60,
                              objectFit: "cover",
                            }}
                            variant="square"
                          />
                          <span style={{ marginLeft: "15px" }}>{row.sku}</span>
                          {/* <span>{row.product_id}</span> */}
                        </div>
                      ))}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <CircularProgress />
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  style={{ overflow: "inherit" }}
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={orderData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>

      <Box display="flex" justifyContent="flex-end" mt={2} gap={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => exportToExcel("RTO")}
        >
          Export to Excel (RTO)
        </Button>
        {/* <Button
          variant="contained"
          color="secondary"
          onClick={() => exportToExcel("R/E")}
        >
          Export to Excel (R/E)
        </Button> */}
      </Box>
    </>
  );
};

export default QuickScan;
