import { getSizesByType } from "./helperArrays";

export const footwearInitials = ["DMU", "PL", "FL", "FF", "HL", "BT", "MS"];
const clothingInitials = ["DR", "ST", "TP", "TR", "TS", "HD", "CT"];

const typeRegex = /^.*?(?=\d)/;

const isOfCategory = (p, category) => {
  const type = p.sku.match(typeRegex)[0];
  const found = category.find((i) => i === type);
  return !!found;
};

const getFlatsWeightBySKU = (sku) => {
  const skuNumber = Number(sku.slice(2));
  return skuNumber < 1290 ? 0.56 : 0.89;
};

const getPlatformsWeightBySKU = (sku) => {
  const skuNumber = Number(sku.slice(2));
  return skuNumber < 1126 ? 0.89 : 1.2;
};

const getWeightBySKU = (sku) => {
  // Regex to split first x characters

  const regex = /^.*?(?=\d)/;
  //   console.log("sku regex", sku, sku.match(regex));
  const type = sku.match(regex)[0];
  // Use a standard to comapre and put weights
  switch (type) {
    case "FL":
      return getFlatsWeightBySKU(sku);
    case "PL":
      return getPlatformsWeightBySKU(sku);
    case "DMU":
      return 0.89;
    case "HL":
      return 0.89;
    case "FF":
      return 0.89;
    case "BT":
      return 1.2;
    case "TP":
      return 0.2;
    case "CT":
      return 0.2;
    case "DR":
      return 0.2;
    case "TR":
      return 0.2;
    case "ST":
      return 0.3;
    case "TS":
      return 0.2;
    case "MS":
      return 1.2;
    case "HD":
      return 0.3;
    default:
      return 0;
  }
};

export const getOrderWeight = (products) => {
  //Single item in order
  //   if (products === null || products.length === 0) {
  //     console.log(products);
  //   }
  if (products.length === 1 && products[0].units === 1) {
    const weightOfSingelItem = getWeightBySKU(products[0].sku.split(".")[0]);
    return weightOfSingelItem;
  }

  //Multiple item in order

  //If special order with more than 3 items,
  if (products.reduce((sum, i) => sum + i.units, 0) > 3) {
    // Return null so that our team can populate manually
    return null;
  }

  const clothingItems = products.filter((p) =>
    isOfCategory(p, clothingInitials)
  );
  const footwearItems = products.filter((p) =>
    isOfCategory(p, footwearInitials)
  );
  // Sets weigh more than others, its the anomaly
  const totalSets = clothingItems.reduce((sum, i) => {
    const type = i.sku.match(typeRegex)[0];
    return type === "ST" ? sum + i.units : sum;
  }, 0);

  const totalBox3Items = footwearItems.reduce((sum, i) => {
    const sku = i.sku.split(".")[0];
    const type = sku.match(typeRegex)[0];
    const eligible =
      type === "MS" || (type === "PL" && getPlatformsWeightBySKU(sku) === 1.2);
    return eligible ? sum + i.units : sum;
  }, 0);

  const totalBox1Items = footwearItems.reduce((sum, i) => {
    const sku = i.sku.split(".")[0];
    const type = sku.match(typeRegex)[0];
    return type === "FL" && getFlatsWeightBySKU(sku) === 0.56
      ? sum + i.units
      : sum;
  }, 0);

  if (footwearItems.length === 0) {
    // Only clothing in the order
    const totalUnits = clothingItems.reduce((sum, i) => sum + i.units, 0);
    // Total either 2 or 3
    if (totalUnits === 2) {
      return totalSets === 2 ? 0.6 : totalSets === 1 ? 0.4 : 0.3;
    } else {
      return totalSets === 3
        ? 0.9
        : totalSets === 2
        ? 0.8
        : totalSets === 1
        ? 0.6
        : 0.4;
    }
  } else if (clothingItems.length === 0) {
    // Only footwear in the order
    const totalUnits = footwearItems.reduce((sum, i) => sum + i.units, 0);

    // Total either 2 or 3
    if (totalUnits === 2) {
      // All box1
      if (totalBox1Items === 2) return 0.89;
      // All box 3
      if (totalBox3Items === 2) return 2.4;
      // All box 2
      if (totalBox1Items + totalBox3Items === 0) return 1.4;
      // box 1 + box2
      if (totalBox1Items === 1 && totalBox3Items === 0) return 1.8;
      // box 1 + box3
      if (totalBox1Items === 1 && totalBox3Items === 1) return 2.3;
      // box2 + box3
      if (totalBox1Items === 0 && totalBox3Items === 1) return 2.3;
    } else {
      // All box1
      if (totalBox1Items === 3) return 1.2;
      // All box 3
      if (totalBox3Items === 3) return 3.3;
      // All box 2
      if (totalBox1Items + totalBox3Items === 0) return 2.9;
      // box 2 + box2
      if (totalBox1Items === 2 && totalBox3Items === 0) return 1.4;
      // box 2 + box3
      if (totalBox1Items === 2 && totalBox3Items === 1) return 2.3;
      // box3 + box3 + box1
      if (totalBox3Items === 2 && totalBox1Items === 1) return 2.9;
      // box3 + box3 + box2
      if (totalBox3Items === 2 && totalBox1Items === 0) return 3.3;
      // box1 + box 2 + box 2
      if (totalBox1Items === 1 && totalBox3Items === 0) return 2.6;
      // box 1 + box 2 + box 3
      if (totalBox1Items === 1 && totalBox3Items === 1) return 1.4;
      // box 3 + box2 + box2
      if (totalBox3Items === 1 && totalBox1Items === 0) return 3.3;
    }
  } else {
    // Both clothing and Footwear in the order
    if (clothingItems.reduce((sum, i) => sum + i.units, 0) === 2) {
      // Two clothes one footwear
      if (totalSets === 2) {
        // box2 + cloth2 + cloth2
        if (totalBox1Items + totalBox3Items === 0) return 1.1;
        // box1 + cloth2 + cloth2
        if (totalBox1Items === 1) return 1.1;
        // box3 + cloth 2 + cloth2
        if (totalBox3Items === 1) return 1.6;
      } else if (totalSets === 1) {
        // box2 + cloth1 + cloth2
        if (totalBox1Items + totalBox3Items === 0) return 1.2;
        // box1 + cloth1  + cloth2
        if (totalBox1Items === 1) return 0.95;
        // box3  + cloth1 + cloth 2
        if (totalBox3Items === 1) return 1.4;
      } else {
        // box2 + cloth2
        if (totalBox1Items + totalBox3Items === 0) return 1.1;
        // box1 + cloth2
        if (totalBox1Items === 1) return 0.8;
        // box3 + cloth 2
        if (totalBox3Items === 1) return 1.3;
      }
    } else if (footwearItems.reduce((sum, i) => sum + i.units, 0) === 2) {
      // Two footwear one cloth
      if (totalSets === 1) {
        // 2 box1 and cloth2
        if (totalBox1Items === 2) return 1.1;
        // 2 box 3  and cloth2
        if (totalBox3Items === 2) return 2.5;
        // 2 box 2  and cloth2
        if (totalBox1Items + totalBox3Items === 0) return 1.9;
        // box 1 + box2  and cloth2
        if (totalBox1Items === 1 && totalBox3Items === 0) return 1.7;
        // box 1 + box3  and cloth2
        if (totalBox1Items === 1 && totalBox3Items === 1) return 2.4;
        // box2 + box3 and cloth2
        if (totalBox1Items === 0 && totalBox3Items === 1) return 2.5;
      } else {
        // 2 box1 and cloth1
        if (totalBox1Items === 2) return 0.9;
        // 2 box 3  and cloth1
        if (totalBox3Items === 2) return 2.4;
        // 2 box 2  and cloth1
        if (totalBox1Items + totalBox3Items === 0) return 1.8;
        // box 1 + box2  and cloth1
        if (totalBox1Items === 1 && totalBox3Items === 0) return 1.6;
        // box 1 + box3  and cloth1
        if (totalBox1Items === 1 && totalBox3Items === 1) return 2.3;
        // box2 + box3 and cloth1
        if (totalBox1Items === 0 && totalBox3Items === 1) return 2.4;
      }
    } else {
      // 2 items 1 clothing 1 footwear
      if (totalSets === 1) {
        // box2 + cloth2
        if (totalBox1Items + totalBox3Items === 0) return 1.1;
        // box1 + cloth2
        if (totalBox1Items === 1) return 0.8;
        // box3 + cloth 2
        if (totalBox3Items === 1) return 1.3;
      } else {
        // box2 + cloth1
        if (totalBox1Items + totalBox3Items === 0) return 0.9;
        // box1 + cloth1
        if (totalBox1Items === 1) return 0.7;
        // box3 + cloth 1
        if (totalBox3Items === 1) return 1.2;
      }
    }
  }
  // In case all logic misses return the maximum amount
  return 3.3;
};

// export const getWeightFromInventory = (products, inventory) => {
//     let checkIfMaterialAbsent = false;
//     products.forEach((p) => {
//         const foundInventory = inventory.find(
//             (i) => i.SKU === p.sku.split(".")[0]
//         );
//         if (foundInventory) {
//             if (
//                 !("material" in foundInventory) ||
//                 foundInventory.material.length === 0
//             ) {
//                 checkIfMaterialAbsent = true;
//             }
//         } else {
//             checkIfMaterialAbsent = true;
//         }
//     });
//     if (checkIfMaterialAbsent) {
//         return 0;
//     } else {
//         const totalWeight = products.reduce((acc, item) => {
//             const sku = item.sku.split(".")[0];
//             const foundInventory = inventory.find((i) => i.SKU === sku);
//             if (foundInventory) {
//                 if (
//                     "material" in foundInventory &&
//                     foundInventory.material.length > 0
//                 ) {
//                     const totalAmt = foundInventory.material.reduce(
//                         (mcc, mItem) => mcc + parseFloat(mItem.amount),
//                         0
//                     );
//                     return acc + totalAmt;
//                 } else {
//                     return acc;
//                 }
//             } else {
//                 return acc;
//             }
//         }, 0);
//         const totalWeightFixed = parseFloat(totalWeight).toFixed(2);

//         return parseFloat(totalWeightFixed);
//     }
// };

export const getWeightFromInventory = (products, inventory) => {
  let totalWeight = 0;
  products.forEach((p) => {
    const foundInInventory = inventory.find(
      (iv) => iv.SKU === p.sku.split(".")[0]
    );
    if (foundInInventory) {
      const type = foundInInventory.type;
      const firstStep = parseFloat(foundInInventory.XSDeadWeight);
      const lastStep = parseFloat(foundInInventory.XLDeadWeight);

      const productSize = p.variant_title;
      const sizes = getSizesByType(type);
      const pos = sizes.indexOf(productSize);

      const stepVal = (lastStep - firstStep) / (sizes.length - 1);
      const toAdd = stepVal * pos;
      totalWeight = totalWeight + firstStep + toAdd;

      // console.log("first step", firstStep, lastStep, toAdd, p.sku, totalWeight);
    }
  });
  const val = parseFloat(parseFloat(totalWeight).toFixed(2));
  return val;
};
