const initialState = {
  reconDetails: [],
};

export const reconReducer = (state = initialState, action) => {
  switch (action.type) {
    case "fetchScanSingleDateSuccess":
      return {
        ...state,
        reconDetails: action.payload,
      };
    case "submitAwbSuccess":
      return {
        ...state,
        reconDetails: [...state.reconDetails, action.payload],
      };
    case "DELETESINGLE":
      return {
        ...state,
        reconDetails: state.reconDetails.filter(
          (i) => String(i.fulfillment_id) !== action.payload
        ),
      };

    default:
      return state;
  }
};
