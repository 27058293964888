import { toast } from "react-toastify";

export const getOrderDetails = async (customerId, orderId) => {
    let res;
    try {
        const response = await fetch(
            `https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/Shopifygetorderdetailsbycustomer`,
            {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify({
                    customerId,
                    orderId,
                }),
            }
        );

        const data = await response.json();
        console.log("DATA", data);
        if (data.statusCode === 404) {
            return { data: "unauthorized", success: false };
        } else if (data.statusCode === 500) {
            return { data: "unauthorized", sucess: false };
        }
        res = { data, success: true };
    } catch (err) {
        res = { data: err, success: false };
    }

    return res;
};

export const getCustomerOrders = async (customerId) => {
    let res;
    try {
        const response = await fetch(
            `https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/tickets/customerorders`,
            {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify({
                    customerId,
                }),
            }
        );

        const data = await response.json();
        if (data.statusCode === 404) {
            return { data: "unauthorized", success: false };
        } else if (data.statusCode === 500) {
            return { data: "unauthorized", sucess: false };
        }
        res = { data, success: true };
    } catch (err) {
        res = { data: err, success: false };
    }

    return res;
};

export const uploadImagesToGoogleDocs = async (files) => {};

const sendEmail = async (email) => {
    try {
        const response = await fetch(
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/returnexchangerequestreceived",
            {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify({ status: "Approved", email }),
            }
        );

        // const data = await response.json();
        // if (data.statusCode === 400) {
        //   return { data: "Request Already Submitted", success: false };
        // } else if (data.statusCode === 500) {
        //   return {
        //     data: "Error Submitting, Please Try Again",
        //     success: false,
        //   };
        // }
        // res = { data, success: true };
    } catch (err) {
        console.log("");
    }
};

export const updateCustomerMobileNumber = async (mobileNo, customer) => {
    console.log("PAYLOAD", mobileNo);
};

export const checkEmail = async (email) => {
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return 404;
    }
    try {
        const response = await fetch(
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/checkblocklist",
            {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify({ email }),
            }
        );
        const data = await response.json();
        console.log(data);
        return data.statusCode;
    } catch (err) {
        console.log(err);
        return 500;
    }
};

export const sendOrderUpdateToShopify = async (
    orderId,
    newOrder,
    oldOrders
) => {
    try {
        const value = JSON.stringify(oldOrders.concat(newOrder));
        console.log(value);
        const response = await fetch(
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/orders/shopifyremetafiled",
            {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify({ orderId, value }),
            }
        );
        console.log(JSON.stringify({ orderId, value }));

        const data = await response.json();
        return data.statusCode;
        console.log("data", data);
    } catch (err) {
        console.log("");
        return 500;
    }
};

export const submitData = async (
    payload,
    email,
    orderId,
    newOrderItems,
    exchangeHistory
) => {
    let res;
    try {
        const metafield_response = await sendOrderUpdateToShopify(
            orderId,
            newOrderItems,
            exchangeHistory
        );
        console.log({
            ...payload,
            metaifeldUpdated: metafield_response,
        });

        const response = await fetch(
            `https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/Shopifyuploadreturnorexchange`,
            {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify({
                    ...payload,
                    metaifeldUpdated: metafield_response,
                }),
            }
        );

        const data = await response.json();
        if (data.statusCode === 400) {
            toast.error("Request alraeay submitted");
            return { data: "Request Already Submitted", success: false };
        } else if (data.statusCode === 500) {
            toast.error("failed to create exchange");
            return {
                data: "Error Submitting, Please Try Again",
                success: false,
            };
        }
        res = { data, success: true };
        toast.success("Exchange created successfully");
        if (email) {
            await sendEmail(email);
        }
    } catch (err) {
        res = { data: "Server Error, Please Try Again", success: false };
        toast.error("Server Error");
    }

    return res;
};

export const uploadToDrive = async (files) => {
    const file_array = files;
    const promise = file_array.map((file) => {
        return fetch(
            "https://script.google.com/macros/s/AKfycbwp81ZEBoRMSp_GSdibRwqPOLnTqZF_3u-E4UR3jmh2Fo4Hz-0o2wmQ21UqiQw6vuQ8/exec",
            { method: "POST", body: JSON.stringify(file) }
        );
    });
    try {
        const response = await Promise.all(promise).then(async (res) =>
            Promise.all(res.map(async (data) => await data.json()))
        );
        console.log("response", response);
        return {
            status: 200,
            data: response,
        };
    } catch (error) {
        console.log("error", error);
        return {
            status: 500,
            error: error,
        };
    }
};
