import { Box, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { productTypes, skuTypes } from "../utils/helperArrays";

const InventoryValue = () => {
    const { stock } = useSelector((state) => state.inventoryDetails);
    const [inventoryTotal, setInventoryTotal] = useState("0");
    const [totalClothingCost, setTotalClothingCost] = useState("0");
    const [totalFootwearCost, setTotalFootwearCost] = useState("0");
    const [notUpdatedItems, setNotUpdatedItems] = useState([]);

    // find category based on SKU
    const getCategoryFromSKU = (sku) => {
        if (!sku) return null;

        const regex = new RegExp("([a-zA-Z]+)", "g");
        const splitSKU = sku.match(regex)?.[0];

        // Use skuTypes array to find the correct category
        const matchedType = skuTypes.find((type) => type.value === splitSKU);

        return matchedType ? matchedType.label : null;
    };

    useEffect(() => {
        if (stock.length > 0) {
            console.log(
                "STOCK",
                stock.map((i) => ({
                    URL: i.imageUrl,
                    SKU: i.SKU,
                    SIZE: i.size,
                    CATAGORY: i.category,
                    COSTPRICE: i.costPrice || "0.00",
                }))
            );
            const unprocessedItems = [];
            const inventoryValue = stock.reduce(
                (sum, item) => {
                    const category = getCategoryFromSKU(item?.SKU);

                    if (
                        isNaN(parseFloat(item?.costPrice)) ||
                        !("count" in item) ||
                        !category
                    ) {
                        unprocessedItems.push(item);
                        return sum;
                    } else {
                        const itemTotal =
                            parseFloat(item.costPrice) * item.count;

                        if (
                            [
                                "Clothing",
                                "Men's Clothing",
                                "Plus-Curve",
                                "Lingerie and Athleisure",
                            ].includes(category)
                        ) {
                            sum.clothing += itemTotal;
                        } else if (category === "Footwear") {
                            sum.footwear += itemTotal;
                        }
                        sum.total += itemTotal;

                        return sum;
                    }
                },
                { total: 0, clothing: 0, footwear: 0 }
            );

            console.log("Final Totals:", inventoryValue);

            setInventoryTotal(inventoryValue.total.toFixed(2));
            setTotalClothingCost(inventoryValue.clothing.toFixed(2));
            setTotalFootwearCost(inventoryValue.footwear.toFixed(2));
            setNotUpdatedItems(unprocessedItems);
            console.log("Not Updated Items:", unprocessedItems);
        }
    }, [stock]);

    return (
        <Box my={2}>
            <Paper
                sx={{
                    px: 4,
                    py: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", marginBottom: 2 }}
                >
                    Present Inventory Value
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                    Total: ₹{inventoryTotal}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                    Total Clothing Cost Price: ₹{totalClothingCost}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                    Total Footwear Cost Price: ₹{totalFootwearCost}
                </Typography>
            </Paper>
        </Box>
    );
};

export default InventoryValue;
