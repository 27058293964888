import {
  comboSKUs,
  cuttingVendors,
  findReverseName,
  finishingVendors,
  POVendors,
  stitchingVendors,
} from "./helperArrays";
import { getLBHWforReverse } from "./lbhCalculator";
// export const BACKEND_BASE_URL = "http://lbwarehousebackend.goodtribe.io";
// export const SOCKET_IO_URL = "http://lbwarehousebackend.goodtribe.io:8000";

export const BACKEND_BASE_URL = "http://localhost:8001";
export const SOCKET_IO_URL = "http://localhost:8000";

export const createUnfulfilledOrdersTableData = (data) => {
  const reducedData = data.reduce((acc, i) => {
    const newProduct = i.line_items.map((x) => ({
      ...x,
      orderId: i.order_number,
      created_at: i.created_at,
    }));
    acc.push(...newProduct);
    return acc;
  }, []);

  const filteredData = reducedData
    .filter((item) => item.fulfillable_quantity > 0)
    .reduce(lineReduce, []);

  return filteredData.map((p) => {
    const { sku, id, name, total, orderId, tags, orderWithSizes, ...sizes } = p;
    //find unfulfilled greater than 8 days
    const upper = new Date(
      new Date(new Date().setHours(0, 0, 0, 0)).setDate(
        new Date().getDate() - 6
      )
    );
    const dateFiltered = reducedData.filter((x) => {
      const orderDate = new Date(x.created_at);
      return new Date(orderDate) < new Date(upper);
    });
    const totalLateUnfulfilled = dateFiltered
      .filter(
        (item) =>
          item.sku.split("_")[0].toUpperCase() === sku &&
          item.fulfillable_quantity > 0
      )
      .reduce((acc, item) => acc + item.quantity, 0);
    return {
      sku,
      id,
      name,
      orderId,
      orderWithSizes,
      total,
      totalLateUnfulfilled,
      sizes,
    };
  });
};

export const createUnfulfilledProductsTableData = (data) =>
  data.map((p) => ({
    ...p,
    total: Object.values(p.sizes).reduce((sum, i) => i + sum, 0),
  }));

const lineReduce = (acc, item) => {
  // console.log("item",item)
  const row = acc.find(
    (p) =>
      p.sku.split("_")[0].toUpperCase() === item.sku.split("_")[0].toUpperCase()
  );
  if (row) {
    row[item.variant_title] = row[item.variant_title]
      ? row[item.variant_title] + item.quantity
      : item.quantity;
    row.total = row.total + item.quantity;
    row.orderId.push(item.orderId);
    // row.orderWithSizes.push({
    //   orderId: item.orderId,
    //   size: item.variant_title,
    // });
    // row.orderWithSizes[item.variant_title] =
    //   item.variant_title in row.orderWithSizes
    //     ? [...row.orderWithSizes[item.variant_title], item.orderId]
    //     : [item.orderId];
    row.orderWithSizes[item.variant_title] = [
      ...(row.orderWithSizes[item.variant_title] || []),
      item.orderId,
    ];
  } else {
    const newProduct = {
      sku: item.sku.split("_")[0].toUpperCase(),
      id: item.product_id,
      orderId: [item.orderId],
      orderWithSizes: { [item.variant_title]: [item.orderId] },
      name: item.title,
      [item.variant_title]: item.quantity,
      total: item.quantity,
    };
    acc.push(newProduct);
  }
  return acc;
};

export const createAllOrdersTableData = (data, number) => {
  // console.log(`DATA no ${number}`, data);
  const reducedData = data.reduce((acc, i) => {
    const newProduct = i.line_items.map((x) => ({
      ...x,
      tags: i.tags,
    }));
    acc.push(...newProduct);
    return acc;
  }, []);

  // const reducedData = data.reduce((acc, i) => {
  //     acc.push(...i.line_items);
  //     return acc;
  // }, []);

  const filteredData = reducedData.reduce(allOrderlineReduce, []);

  return filteredData.map((p) => {
    const { sku, id, name, total, tags, ...sizes } = p;
    return {
      sku,
      id,
      name,
      total,
      sizes,
      number,
      tags,
    };
  });
};

const allOrderlineReduce = (acc, item) => {
  const row = acc.find(
    (p) =>
      p.sku.split("_")[0].toUpperCase() === item.sku.split("_")[0].toUpperCase()
  );
  if (row) {
    row[item.variant_title] = row[item.variant_title]
      ? row[item.variant_title] + item.quantity
      : item.quantity;
    row.total = row.total + item.quantity;
  } else {
    const newProduct = {
      sku: item.sku.split("_")[0].toUpperCase(),
      id: item.product_id,
      name: item.title,
      [item.variant_title]: item.quantity,
      total: item.quantity,
      tags: item.tags,
    };
    acc.push(newProduct);
  }
  return acc;
};

export const createProductsTableData = (data, type) => {
  const filteredData = data.filter((i) => i.type === type);
  const productData = filteredData.reduce((acc, item) => {
    const row = acc.find((p) => p.SKU === item.SKU);
    if (row) {
      row[item.size] = item.count;
      row.total += item.count;
    } else {
      const newProductData = {
        SKU: item.SKU,
        imageUrl: item.imageUrl,
        costPrice: item.costPrice,
        total: item.count,
        [item.size]: item.count,
        type: item.type,
      };
      acc.push(newProductData);
    }
    return acc;
  }, []);

  return productData;
};
export const createProductsTableDataWithDrafts = (data, shopifyData, type) => {
  const shopifyInfo = shopifyData.map((p) => ({
    SKU: p.variants[0].sku,
    status: p.status,
    id: p.id,
  }));
  const filteredData = data.filter((i) => i.type === type);
  const productData = filteredData.reduce((acc, item) => {
    const row = acc.find((p) => p.SKU === item.SKU);
    if (row) {
      row[item.size] = item.count;
      row.total += item.count;
    } else {
      if ("length" in item) {
        const newProductData = {
          SKU: item.SKU,
          imageUrl: item.imageUrl,
          costPrice: item.costPrice,
          total: item.count,
          [item.size]: item.count,
          type: item.type,
          material: "material" in item ? item.material : [],
          status: shopifyInfo.find((p) => p.SKU === item.SKU)?.status,
          id: shopifyInfo.find((p) => p.SKU === item.SKU)?.id,
          length: item.length,
          breadth: item.breadth,
          height: item.height,
        };
        acc.push(newProductData);
      } else {
        const newProductData = {
          SKU: item.SKU,
          imageUrl: item.imageUrl,
          costPrice: item.costPrice,
          total: item.count,
          [item.size]: item.count,
          type: item.type,
          material: "material" in item ? item.material : [],
          status: shopifyInfo.find((p) => p.SKU === item.SKU)?.status,
          id: shopifyInfo.find((p) => p.SKU === item.SKU)?.id,
        };
        acc.push(newProductData);
      }
    }
    return acc;
  }, []);

  return productData;
};

export const getShipRocketOrderFormat = (order) => {
  return {
    fulfillment_id: order.fulfillment_id,
    order_id: order.fulfillment_id,
    order_date: order.order_date,
    billing_customer_name: order.billing_customer_name,
    billing_last_name: order.billing_last_name,
    billing_address: order.billing_address,
    billing_address_2: order.billing_address_2,
    billing_city: order.billing_city,
    billing_pincode: order.billing_pincode,
    billing_state: order.billing_state,
    billing_country: order.billing_country,
    billing_email: order.billing_email,
    billing_phone: order.billing_phone,
    shipping_is_billing: order.shipping_is_billing,
    shipping_customer_name: order.shipping_customer_name,
    shipping_last_name: order.shipping_last_name,
    shipping_address: order.shipping_address,
    shipping_address_2: order.shipping_address_2,
    shipping_city: order.shipping_city,
    shipping_pincode: order.shipping_pincode,
    shipping_country: order.shipping_country,
    shipping_state: order.shipping_state,
    shipping_email: order.shipping_email,
    shipping_phone: order.shipping_phone,
    order_items: order.order_items.map((p) => ({
      name: p.name,
      sku: p.sku,
      units: p.units,
      selling_price: p.selling_price,
      discount: p.discount,
    })),
    payment_method: order.payment_method,
    shipping_charges: order.shipping_charges,
    sub_total: order.sub_total,
    length: order.length,
    breadth: order.breadth,
    height: order.height,
    weight: order.weight,
    pickup_location: "GOOD TRIBE PRIVATE LIMITED",
    print_label: true,
    generate_manifest: true,
  };
};

export const getXpressBeesOrderFormat = (orders) =>
  orders.map((order) => {
    return {
      fulfillment_id: order.fulfillment_id,
      order_id: order.fulfillment_id,
      order_date: order.order_date,
      billing_customer_name: order.billing_customer_name,
      billing_last_name: order.billing_last_name,
      billing_address: order.billing_address,
      billing_address_2: order.billing_address_2,
      billing_city: order.billing_city,
      billing_pincode: order.billing_pincode,
      billing_state: order.billing_state,
      billing_country: order.billing_country,
      billing_email: order.billing_email,
      billing_phone: order.billing_phone,
      shipping_is_billing: order.shipping_is_billing,
      shipping_customer_name: order.shipping_customer_name,
      shipping_last_name: order.shipping_last_name,
      shipping_address: order.shipping_address,
      shipping_address_2: order.shipping_address_2,
      shipping_city: order.shipping_city,
      shipping_pincode: order.shipping_pincode,
      shipping_country: order.shipping_country,
      shipping_state: order.shipping_state,
      shipping_email: order.shipping_email,
      shipping_phone: order.shipping_phone,
      order_items: order.order_items.map((p) => ({
        name: p.name,
        sku: p.sku,
        units: p.units,
        selling_price: p.selling_price,
        discount: p.discount,
      })),
      payment_method: order.payment_method,
      shipping_charges: order.shipping_charges,
      sub_total: order.sub_total,
      length: order.length,
      breadth: order.breadth,
      height: order.height,
      weight: order.weight,
      pickup_location: "GOOD TRIBE PRIVATE LIMITED",
      print_label: true,
      generate_manifest: true,
    };
  });

export const createXBReverseOrderPayload = (order, awb) => ({
  AirWayBillNO: awb.toString(),
  OrderNo: `R_${String(order.fulfillment_id)}`,
  BusinessAccountName: "LBI",
  ProductID: order.products.map((p) => p.sku).join(", "),
  Quantity: order.products.length,
  ProductName: order.products.map((p) => findReverseName(p.sku)).join(", "),
  ProductMRP: order.products.reduce((sum, i) => parseFloat(i.mrp) + sum, 0),
  DropDetails: {
    Addresses: [
      {
        Type: "Primary",
        Name: "LittleBoxIndia",
        Address:
          "GOOD TRIBE PRIVATE LIMITED Park, SF, D-200, Madipur Main Village Road, Madipur",
        City: "West Delhi",
        State: "Delhi",
        PinCode: "110063",
        EmailID: "",
        Landmark: "",
      },
    ],
    ContactDetails: [
      {
        Type: "Primary",
        PhoneNo: "8114118119",
      },
    ],
  },
  PickupDetails: {
    Addresses: [
      {
        Type: "Primary",
        Name: `${order.address?.first_name || ""} ${
          order.address?.last_name || ""
        }`,
        Address: `${order.address?.address1 || ""} ${
          order.address?.address2 || ""
        }`,
        City: order.address?.city,
        State: order.address?.province,
        PinCode: order.address?.zip,
        EmailID: "",
        Landmark: "",
      },
    ],
    ContactDetails: [
      {
        Type: "Primary",
        VirtualNumber: order.alternatePhone
          ?.split("")
          .filter((i) => i !== " ")
          .filter((i) => i !== "  ")
          .filter((i) => i !== "-")
          .join("")
          .slice(-10),
        PhoneNo: order.address.phone
          ?.split("")
          .filter((i) => i !== " ")
          .filter((i) => i !== "  ")
          .filter((i) => i !== "-")
          .join("")
          .slice(-10),
      },
    ],
    IsPickupPriority: "1",
  },
});

export const createDelhiveryReverseOrderPayload = (order, stock) => {
  // CALCULATE WHL
  const { length, breadth, height, weight } = getLBHWforReverse(
    order.products,
    stock
  );
  /*
    // .replaceAll(String.fromCharCode(92),"a") backslash
    // .replaceAll("%", "")
    // .replaceAll("&", "")
    //  .replaceAll("#", "")
    // .replaceAll(";", "")
    // .replace(/blue/g, "red")
    \a	Bell (alert)
\b	Backspace
\f	Form feed
\n	New line
\r	Carriage return
\t	Horizontal tab
\v	Vertical tab
\'	Single quotation mark
\"	Double quotation mark
\\	Backslash
\?	Literal question mark
str = str.replace(/\\([ntrbfv\\])/g, function(match, p1) {
    switch (p1) {
        case 'n': return '\n';
        case 't': return '\t';
        case 'r': return '\r';
        case 'b': return '\b';
        case 'f': return '\f';
        case 'v': return '\v';
        case '\\': return '\\';
        default: return match;
    }
});

console.log(str);
    */

  const uncodedAddress = `${order.address?.address1 || ""} ${
    order.address?.address2 || ""
  }`;

  const uncodedName = `${order.address?.first_name || ""} ${
    order.address?.last_name || ""
  }`;

  const str = uncodedAddress
    .replace(/\\([ntrbfv\\])/g, function (match, p1) {
      switch (p1) {
        case "n":
          return "\n";
        case "t":
          return "\t";
        case "r":
          return "\r";
        case "b":
          return "\b";
        case "f":
          return "\f";
        case "v":
          return "\v";
        case "\\":
          return "\\";
        default:
          return match;
      }
    })
    .replaceAll('"', "")
    .replaceAll("%", "")
    .replaceAll("&", "")
    .replaceAll("#", "")
    .replaceAll(";", "")
    .trim();

  const nameStr = uncodedName
    .replace(/\\([ntrbfv\\])/g, function (match, p1) {
      switch (p1) {
        case "n":
          return "\n";
        case "t":
          return "\t";
        case "r":
          return "\r";
        case "b":
          return "\b";
        case "f":
          return "\f";
        case "v":
          return "\v";
        case "\\":
          return "\\";
        default:
          return match;
      }
    })
    .replaceAll('"', "")
    .replaceAll("%", "")
    .replaceAll("&", "")
    .replaceAll("#", "")
    .replaceAll(";", "")
    .trim();

  console.log(`Prev=${uncodedAddress}, changed=${str}`);

  return {
    shipments: [
      {
        name: nameStr,
        add: str,
        pin: order.address?.zip,
        city: order.address?.city,
        state: order.address?.province,
        country: "India",
        phone: order.address.phone
          ?.split("")
          .filter((i) => i !== " ")
          .filter((i) => i !== "  ")
          .filter((i) => i !== "-")
          .join("")
          .slice(-10),
        order: `R_${String(order.fulfillment_id)}`,
        payment_mode: "Pickup",
        return_pin: "",
        return_city: "",
        return_phone: "",
        return_add: "",
        return_state: "",
        return_country: "",
        products_desc:
          order.products.map((p) => findReverseName(p.sku)).join(", ") +
          (order.reattempts || ""),
        hsn_code: "",
        cod_amount: "0",
        order_date: new Date().toLocaleDateString(),
        total_amount: order.products.reduce(
          (sum, i) => parseFloat(i.mrp) + sum,
          0
        ),
        seller_add: "",
        seller_name: "",
        seller_inv: "",
        quantity: order.products.reduce((acc, p) => acc + p.quantity, 0),
        waybill: "",
        shipment_length: length,
        shipment_breadth: breadth,
        shipment_height: height,
        weight: weight,
        seller_gst_tin: "",
        shipping_mode: "Surface",
        address_type: "home",
      },
    ],
    pickup_location: {
      name: "LITTLE BOX SURFACE",
      add: "KH.NO-382, F/F, Front Side 100 Phoota Road Village Ghitorni, New Delhi-110030, India",
      city: "New Delhi",
      pin_code: "110030",
      country: "India",
      phone: "9999999999",
    },
  };
};

export const createInventoryWithSalesData = (inventory, order) => {
  const reducedData = order.reduce((acc, i) => {
    acc.push(...i.line_items);
    return acc;
  }, []);

  const filteredData = reducedData.reduce(allOrderlineReduce, []);

  console.log(filteredData);

  const inventoryWithSales = inventoryReduce(inventory, filteredData);

  return inventoryWithSales;
};

const inventoryReduce = (inventory, salesdata) => {
  const reducedinventorywithsales = inventory.reduce((acc, item) => {
    const row = acc.find((p) => p.SKU === item.SKU);
    const productsale = salesdata.find((i) => i.sku === item.SKU);
    if (row) {
      row.stock = { [item.size]: item.count, ...row.stock };
      row.totalstock = row.totalstock + item.count;
      row.sales = {
        [item.size]:
          productsale && productsale[item.size] ? productsale[item.size] : 0,
        ...row.sales,
      };
    } else {
      const newProduct = {
        SKU: item.SKU,
        barcode: item.barcode,
        date: item.date,
        imageUrl: item.imageUrl,
        type: item.type,
        vendor: item.vendor,
        category: item.category,
        stock: { [item.size]: item.count },
        totalstock: item.count,
        sales: {
          [item.size]:
            productsale && productsale[item.size] ? productsale[item.size] : 0,
        },
        totalsales: productsale ? productsale.total : 0,
      };
      acc.push(newProduct);
    }

    return acc;
  }, []);

  return reducedinventorywithsales;
};

export const sortInventoryWithSales = (inventory) => {
  //doubling sales of product 10 days old
  const newInventory = inventory.map((row) => {
    const datelimit = new Date(new Date().setDate(new Date().getDate() - 10));
    if (new Date(row.date) > datelimit) {
      row.expectedsale = row.totalsales * 2;
      return row;
    } else {
      row.expectedsale = row.totalsales;
      return row;
    }
  });
  //sort based on sales
  return newInventory.sort((a, b) => b.expectedsale - a.expectedsale);
};

export const delay = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

export const createPOAnalyticsData = (
  inventory,
  weekWise,
  categoryWise,
  unfulfilled,
  returned,
  pendingPO
) => {
  // CONSOLIDATE WITH REQUIRED FIELDS
  // 1. sku, type, warehouse stock
  // 2. weekly sales and name
  // 3. 90 days sales (for category wise % calc)
  // 4. Unfulfilled
  // 5. RTO/RE
  // 6. pending
  const reducedInventory = sizesReduce(inventory); //STEP 1
  const withWeekly = reducedInventory.map((p) => {
    //STEP 2
    const found = weekWise.find((i) => i.sku === p.sku);
    if (found) {
      return {
        ...p,
        name: found.name,
        1: found["1"] || {},
        2: found["2"] || {},
        3: found["3"] || {},
        4: found["4"] || {},
        total4weeks: found.total,
        percent: {},
        poQuantity: {},
        predictedSales: {},
        predictedRMS: 0,
      };
    } else {
      return {
        ...p,
        name: "Not found",
        1: {},
        2: {},
        3: {},
        4: {},
        total4weeks: 0,
        percent: {},
        poQuantity: {},
        predictedSales: {},
        predictedRMS: 0,
      };
    }
  });
  const catPercent = withWeekly.map((p) => {
    //STEP 3 (NEEDS IMPROVEMENT)
    const found = categoryWise.find((i) => i.sku === p.sku);
    if (found) {
      return {
        ...p,
        sales: found.sizes,
      };
    } else {
      return {
        ...p,
        sales: {},
      };
    }
  });
  const withUnfulfilled = catPercent.map((p) => {
    //STEP 4
    const found = unfulfilled.find((i) => i.sku === p.sku);
    if (found) {
      return {
        ...p,
        unfulfilled: found.sizes,
        totalUnfulfilled: Object.values(found.sizes).reduce(
          (sum, item) => item + sum,
          0
        ),
      };
    } else {
      return {
        ...p,
        unfulfilled: {},
        totalUnfulfilled: 0,
      };
    }
  });
  const withReturns = withUnfulfilled.map((p) => {
    //STEP 5
    const found = returned.find((i) => i.sku === p.sku);
    if (found) {
      return {
        ...p,
        returned: found.sizes,
      };
    } else {
      return {
        ...p,
        returned: {},
      };
    }
  });
  const withPendingPO = withReturns.map((p) => {
    //STEP 6
    const found = pendingPO.find((i) => i.sku === p.sku);
    if (found) {
      return {
        ...p,
        pending: found.sizes,
      };
    } else {
      return {
        ...p,
        pending: {},
      };
    }
  });
  return withPendingPO;
};

export const calculatePOQuantity = (product, sizes) => {
  //THE MAIN LOGIC PART
  //1. rms of four weeks
  const sum =
    Math.pow(product["1"].total || 0, 2) +
    Math.pow(product["2"].total || 0, 2) +
    Math.pow(product["3"].total || 0, 2) +
    Math.pow(product["4"].total || 0, 2);
  const avg = sum / 4;
  const rms = Math.sqrt(avg);
  const predictedRMS =
    product["1"].total > 0
      ? product["1"].total > rms
        ? 0.9 * product["1"].total
        : 1.1 * product["1"].total
      : rms;
  const pSales = {};
  const result = sizes
    .map((s) => {
      //2. Divide according to percentage
      const predictedSales = predictedRMS * (product.percent[s] / 100);
      pSales[s] = predictedSales.toFixed(2);
      //3. Add unfulfilled
      const withUnfulfilled = predictedSales + (product.unfulfilled[s] || 0);
      //4. Subtract stock
      const adjustStock = withUnfulfilled - (product.sizes[s] || 0);
      //5.Subtract RTO/RE
      const adjustReturned = adjustStock - (product.returned[s] || 0);
      //6. Subtract active/pending POs
      const adjustPO = adjustReturned - (product.pending[s] || 0);
      return {
        [s]: adjustPO > 0 ? Math.ceil(adjustPO) : 0,
      };
    })
    .reduce(
      (acc, i) => ({
        ...acc,
        ...i,
      }),
      {}
    );
  return {
    po: result,
    predictedSales: pSales,
    predictedRMS: Math.ceil(predictedRMS),
  };
};

export const servicablePincodes = [];

const sizesReduce = (products) =>
  products.reduce((agg, product) => {
    const size = product.size;
    const found = agg.find((i) => i.sku === product.SKU);
    if (found) {
      const rest = agg.filter((p) => p.sku !== product.SKU);
      //add quantity
      let newSizes = {
        ...found.sizes,
        [size]: product.count,
      };

      return [
        ...rest,
        {
          sku: product.SKU,
          sizes: newSizes,
          type: product.type,
          src: product.imageUrl,
          vendor: product.vendor,
          material: product.material || [],
        },
      ];
    } else {
      const entry = {
        sku: product.SKU,
        type: product.type,
        src: product.imageUrl,
        vendor: product.vendor,
        material: product.material || [],
        sizes: {
          [size]: product.count,
        },
      };
      return [...agg, entry];
    }
  }, []);

//  return exchange report

export const REcreateOrdersTableData = (exchangeData, unfulfilledData) => {
  const unfulilledReducedData = unfulfilledData.reduce((acc, i) => {
    const newProduct = i.line_items.map((x) => ({
      ...x,
      orderId: i.order_number,
    }));
    acc.push(...newProduct);
    return acc;
  }, []);
  const unfulfilledFilteredData = unfulilledReducedData.filter(
    (item) => item.fulfillable_quantity > 0
  );

  const finalData = exchangeData
    .map((item) => {
      const findUnfulfilledOrders = unfulfilledFilteredData
        .filter(
          (p) => p.sku.split("_")[0].toUpperCase() === item.sku.toUpperCase()
        )
        .map((i) => ({
          ...i,
          variant_title:
            i.variant_title === "Free Size (Fits XS to L)"
              ? "FS"
              : i.variant_title,
        }));

      const unfulfilledReduce = findUnfulfilledOrders.reduce((acc, uo) => {
        acc.orderId =
          "orderId" in acc ? [...acc.orderId, uo.orderId] : [uo.orderId];
        if ("orderWithSizes" in acc) {
          acc.orderWithSizes[uo.variant_title] = [
            ...(acc.orderWithSizes[uo.variant_title] || []),
            uo.orderId,
          ];
        } else {
          acc.orderWithSizes = {
            [uo.variant_title]: [uo.orderId],
          };
        }
        acc[uo.variant_title] = acc[uo.variant_title]
          ? acc[uo.variant_title] + uo.quantity
          : uo.quantity;
        acc.total = "total" in acc ? acc.total + uo.quantity : uo.quantity;
        return acc;
      }, {});
      const { orderWithSizes, orderId, total, ...sizes } = unfulfilledReduce;
      const finalObj = {
        sku: item.sku.toUpperCase(),
        id: item.productId,
        orderId: orderId === undefined ? [] : orderId,
        orderWithSizes: orderWithSizes === undefined ? {} : orderWithSizes,
        sizes,
        total: total === undefined ? 0 : total,
        ...item,
      };
      return finalObj;
    })
    .filter((o) => o.total > 0);
  console.log("final obj", finalData);
  return finalData;
};

export function getRandomNumberOfSeconds() {
  // Generate a random index for the series
  const randomIndex = Math.floor(Math.random() * 5);

  // Map the index to the corresponding value in the series
  const seriesValues = [1, 1.5, 2, 2.5, 3];
  const randomValue = seriesValues[randomIndex];

  return randomValue;
}

export function waitForSeconds(seconds) {
  // Convert seconds to milliseconds
  const milliseconds = seconds * 1000;

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, milliseconds);
  });
}
const findProductQuantity = (sku, inventory, shopify_products) => {
  //check inventory if combo
  const inventoryProduct = inventory.find((i) => i.SKU === sku);
  if (inventoryProduct && "combo" in inventoryProduct && inventory.combo) {
    return inventoryProduct.comboAmount;
  }
  //check name for combo or set
  const shopifyProductsSimplified = shopify_products.map((sp) => ({
    title: sp.title,
    sku: sp.variants.find((v) => "sku" in v)?.sku,
  }));
  const shopifyProduct = shopifyProductsSimplified.find((sp) => sp.sku === sku);
  if (shopifyProduct) {
    const titleWords = String(shopifyProduct.title).toLowerCase().split(" ");
    const titleMatch =
      titleWords.includes("set") ||
      titleWords.includes("combo") ||
      titleWords.includes("combo:");
    const comboSkuMatch = comboSKUs.includes(shopifyProduct.sku);
    if (titleMatch || comboSkuMatch) {
      return 2;
    }
  }
  return 1;
};

export const addQuantityToReverseOrder = (
  reverseOrders,
  stock,
  shopify_products
) => {
  console.log("stock", stock, shopify_products);
  const reverseOrdersWithQnty = reverseOrders.map((ro) => {
    const productsWithQuantity = ro.products.map((p) => {
      const sku = p.sku;
      const quantity = findProductQuantity(sku, stock, shopify_products);
      return {
        ...p,
        quantity,
      };
    });

    return {
      ...ro,
      products: productsWithQuantity,
    };
  });

  return reverseOrdersWithQnty;
};

//cod, prepaid, replacement and products amount
const getDetailedOrderAmount = (orders) => {
  const total = orders.length;
  const COD = orders.filter((o) => o.payment_method === "COD").length;
  const Prepaid = total - COD;
  const replacement = orders.filter((o) =>
    String(o.tags).toLowerCase().includes("replacement")
  ).length;
  const products = orders.reduce((acc, o) => acc + o.order_items.length, 0);
  return {
    total,
    COD,
    Prepaid,
    replacement,
    products,
  };
};

//per day order amount analytics
export const getOrderAmountAnalytics = (dispatchOrders) => {
  //   <=3
  // >3 and <= 7
  // >7 and <=15
  // >15
  //find total orders
  const orders = dispatchOrders.map((o) => {
    const differenceTime =
      new Date().getTime() - new Date(o.order_date).getTime();
    const differenceInDays = Math.round(differenceTime / (1000 * 3600 * 24));
    return {
      ...o,
      orderAge: differenceInDays,
    };
  });
  const ordersAgeLessThanOrEqual3 = orders.filter(
    (order) => order.orderAge <= 3
  );
  const ordersAgeBetween4And7 = orders.filter(
    (order) => order.orderAge > 3 && order.orderAge <= 7
  );
  const ordersAgeBetween8And15 = orders.filter(
    (order) => order.orderAge > 7 && order.orderAge <= 15
  );
  const ordersAgeGreaterThan15 = orders.filter((order) => order.orderAge > 15);
  return {
    ordersAgeLessThanOrEqual3: getDetailedOrderAmount(
      ordersAgeLessThanOrEqual3
    ),
    ordersAgeBetween4And7: getDetailedOrderAmount(ordersAgeBetween4And7),
    ordersAgeBetween8And15: getDetailedOrderAmount(ordersAgeBetween8And15),
    ordersAgeGreaterThan15: getDetailedOrderAmount(ordersAgeGreaterThan15),
  };
};

const createProcessingVendorObj = (vendors, vendorValues, sizes) => {
  const emptyQnty = sizes.reduce((acc, s) => ({ ...acc, [s]: 0 }), {
    updated_at: "",
  });
  const data = vendors.reduce((acc, v) => {
    if (vendorValues[v.code]) {
      acc[v.code] = {
        ...vendorValues[v.code],
        updated_at: vendorValues[v.code].updated_at
          ? new Date(vendorValues[v.code].updated_at).toLocaleDateString(
              "en-in"
            )
          : "",
      };
    } else {
      acc[v.code] = emptyQnty;
    }
    return acc;
  }, {});
  return data;
};

export const getProcessedQntyForSku = (processedQntyList, sku, sizes) => {
  const emptyQnty = sizes.reduce((acc, s) => ({ ...acc, [s]: 0 }), {});
  const emptyVendorQnty = sizes.reduce((acc, s) => ({ ...acc, [s]: 0 }), {
    updated_at: "",
  });
  const emptyCuttingVendor = cuttingVendors.reduce(
    (acc, v) => ({ ...acc, [v.code]: emptyVendorQnty }),
    {}
  );
  const emptyFinishingVendor = finishingVendors.reduce(
    (acc, v) => ({ ...acc, [v.code]: emptyVendorQnty }),
    {}
  );
  const emptyStitchingVendor = stitchingVendors.reduce(
    (acc, v) => ({ ...acc, [v.code]: emptyVendorQnty }),
    {}
  );
  const emptyPOVendor = POVendors.reduce(
    (acc, v) => ({ ...acc, [v.code]: emptyVendorQnty }),
    {}
  );
  const found = processedQntyList.find((q) => q.barcode === sku);
  // console.log("found it", found);
  if (found) {
    if ("cutting" in found) {
      const totalPerSize = sizes.reduce((acc, s) => {
        const amt = found.cutting[s] + found.stitching[s] + found.finishing[s];
        return {
          ...acc,
          [s]: amt,
        };
      }, {});
      const total = Object.values(totalPerSize).reduce((acc, x) => acc + x, 0);
      const totalPO = Object.values(found.PO).reduce((acc, x) => acc + x, 0);
      //for vendors
      if ("cuttingVendor" in found) {
        if (found.barcode === "ST1769") {
          console.log("found", found);
        }
        return {
          ...found,
          POVendor: createProcessingVendorObj(POVendors, found.POVendor, sizes),
          cuttingVendor: createProcessingVendorObj(
            cuttingVendors,
            found.cuttingVendor,
            sizes
          ),
          stitchingVendor: createProcessingVendorObj(
            stitchingVendors,
            found.stitchingVendor,
            sizes
          ),
          finishingVendor: createProcessingVendorObj(
            finishingVendors,
            found.finishingVendor,
            sizes
          ),
          totalPerSize,
          total,
          totalPO,
        };
      } else {
        // console.log("did not find cutting vendor", found);
        return {
          ...found,
          POVendor: emptyPOVendor,
          cuttingVendor: emptyCuttingVendor,
          stitchingVendor: emptyStitchingVendor,
          finishingVendor: emptyFinishingVendor,
          totalPerSize,
          total,
          totalPO,
        };
      }
    } else {
      // console.log("did not find cutting");
      return {
        barcode: sku,
        note: "",
        cutting: emptyQnty,
        stitching: emptyQnty,
        finishing: emptyQnty,
        PO: emptyQnty,
        POVendor: emptyPOVendor,
        cuttingVendor: emptyCuttingVendor,
        stitchingVendor: emptyStitchingVendor,
        finishingVendor: emptyFinishingVendor,
        total: 0,
        totalPO: 0,
        totalPerSize: emptyQnty,
      };
    }
  } else {
    // console.log("did not find the sku");
    return {
      barcode: sku,
      note: "",
      cutting: emptyQnty,
      stitching: emptyQnty,
      finishing: emptyQnty,
      PO: emptyQnty,
      POVendor: emptyPOVendor,
      cuttingVendor: emptyCuttingVendor,
      stitchingVendor: emptyStitchingVendor,
      finishingVendor: emptyFinishingVendor,
      total: 0,
      totalPO: 0,
      totalPerSize: emptyQnty,
    };
  }
};

const addSalesForForcasting = (days, saleData, sizes) => {
  // need size wise sale
  const sizeWiseSale = sizes.reduce((acc, size) => {
    //find total sale of day range per size
    const sizeDaySale = days.reduce((dAcc, day) => {
      //find if day is present
      if (saleData[day]) {
        const amt = saleData[day][size] ? saleData[day][size] : 0;
        return dAcc + amt;
      } else {
        //cant find anything
        return dAcc;
      }
    }, 0);
    return {
      ...acc,
      [size]: sizeDaySale,
    };
  }, {});
  return sizeWiseSale;
};

export const getForcastedSalesDataFor15days = (sku, sizes, salesData) => {
  //Forecasted sales for the next 15 days  (  F*.5 + G*.3 +H*.2 )
  // F = per day sales as per sales of the last 3 days  ( = last three days/3)
  // G = per day sales as per sales of the last 7 days ( = last 7 days/7)
  // H = per day sales as per sales for the last 10 days  ( = last 10 days/10)
  const salesDataOfSku = salesData.find((s) => s.sku === sku);

  if (salesDataOfSku) {
    const last3Days = ["1", "2", "3"];
    const last7Days = ["1", "2", "3", "4", "5", "6", "7"];
    const last15Days = [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
    ];

    const last3DaysForcasted = addSalesForForcasting(
      last3Days,
      salesDataOfSku,
      sizes
    );
    const last7DaysForcasted = addSalesForForcasting(
      last7Days,
      salesDataOfSku,
      sizes
    );
    const last15DaysForcasted = addSalesForForcasting(
      last15Days,
      salesDataOfSku,
      sizes
    );

    return sizes.reduce((acc, s) => {
      const forcastedAmtForcasted =
        last3DaysForcasted[s] * 5 * 0.5 +
        last7DaysForcasted[s] * 2.15 * 0.3 +
        last15DaysForcasted[s] * 1.5 * 0.2;

      return {
        ...acc,
        [s]: Math.ceil(forcastedAmtForcasted),
      };
    }, {});
  } else {
    return sizes.reduce((acc, s) => {
      return { ...acc, [s]: 0 };
    }, {});
  }
};

export const fillMissingShiprocketFields = (originalOrder) => {
  const order = originalOrder;
  if (!order.billing_customer_name) {
    order.billing_customer_name = order.shipping_customer_name;
  }
  if (!order.billing_city) {
    order.billing_city = order.shipping_city;
  }
  if (!order.billing_state) {
    order.billing_state = order.shipping_state;
  }
  if (!order.billing_country) {
    order.billing_country = order.shipping_country;
  }
  if (!order.billing_pincode) {
    order.billing_pincode = order.shipping_pincode;
  }
  if (!order.billing_email) {
    order.billing_email = order.shipping_email;
  }
  if (!order.billing_phone) {
    order.billing_phone = order.shipping_phone;
  }
  return order;
};

export const generateInvoiceNumbers = (lastUsedId, number) => {
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  let invoices = [];
  let lastGeneratedId = null;
  const date = new Date();
  const monthPos = date.getMonth();
  const monthName = months[monthPos];
  const year = date.getFullYear() % 100;
  const prevYear = year - 1;
  const nextYear = year + 1;
  const yearPart = monthPos > 2 ? `${year}${nextYear}` : `${prevYear}${year}`;
  //   generate
  for (let i = 1; i <= number; i++) {
    lastGeneratedId = lastUsedId + i;
    const numberPart = String(lastGeneratedId).padStart(6, "0");
    const invoiceNumber = "LBI" + yearPart + monthName + numberPart;
    invoices.push(invoiceNumber);
  }
  console.log("invoices", invoices);
  return { invoices, lastGeneratedId };
};
