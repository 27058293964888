import { useEffect, useState } from "react";
import {
  productTypes,
  getSizesByType,
  categoryTypes,
  vendorTypes,
  statusArray,
  templateSuffixArray,
  tagsCategoriesArray,
  materialFabricArray,
  materialColorArray,
  findMaterialId,
  accessories,
  materialSoleFabricArray,
  materialSolePlaceArray,
} from "../../utils/helperArrays";
import ProductModal from "./ProductModal";
import DragAndDrop from "./DragAndDrop";
import {
  TextField,
  MenuItem,
  Chip,
  Button,
  Avatar,
  TextareaAutosize,
  Container,
  Paper,
  Box,
  Collapse,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  postNewProducts,
  removeS3Images,
  addProductToShopify,
  updateLastSkuId,
  showLoading,
  hideLoading,
} from "../../actions/productsActions";
import { setImagesinInventory } from "../../actions/inventoryActions";
import ProgressBar from "../../ProgressBar";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const AddProductForm = ({ setSelected }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.productDetails.loading);
  // const inventoryLoading = useSelector(state => state.inventoryDetails.loading)
  // const responseBody = useSelector(state => state.productDetails.responseBody)
  const shopifyResponse = useSelector(
    (state) => state.productDetails.shopifyResponseBody
  );
  const SkuIds = useSelector((state) => state.productDetails.SkuIds);
  const S3Images = useSelector((state) => state.productDetails.skuImages);
  const { stock } = useSelector((state) => state.inventoryDetails);

  const [productType, setProductType] = useState("");
  const [categoriesArray, setCategoriesArray] = useState([]);
  const [categoryType, setCategoryType] = useState("");
  const [vendorsArray, setVendorsArray] = useState([]);
  const [vendorType, setVendorType] = useState("");
  const [sku, setSku] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [costPrice, setCostPrice] = useState("");
  const [barcodes, setBarcodes] = useState([]);
  const [newProductData, setNewProductData] = useState([]);
  const [modalData, setModalData] = useState([]);
  //for shopify image upload
  const [shopifyImages, setShopifyImages] = useState([]);

  const [shopifyTitle, setShopifyTitle] = useState("");
  const [shopifyDescription, setShopifyDescription] = useState("");
  const [shopifyComparePrice, setShopifyComparePrice] = useState("");
  const [tagArray, setTagArray] = useState([]);
  const [shopifyStatusType, setShopifyStatusType] = useState("");
  const [templateSuffix, setTemplateSuffix] = useState("");
  const [editSku, setEditSku] = useState(false);
  const [sizeQuantity, setSizeQuantity] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [errorResponse, setErrorResponse] = useState(false);

  const [materialType, setMaterialType] = useState("");
  const [materialColor, setMaterialColor] = useState("");
  const [materialAmount, setMaterialAmount] = useState("");
  const [materialObj, setMaterialObj] = useState([]);
  const [openAddMaterial, setOpenAddMaterial] = useState(false);
  const [fabricArr, setFabricArr] = useState([]);
  const [materialPlace, setMaterialPlace] = useState("");
  const [materialWeight, setMaterialWeight] = useState("");
  const [materialFormula, setMaterialFormula] = useState("");

  const [isCombo, setIsCombo] = useState(false);
  const [comboAmount, setComboAmount] = useState(2);

  const [selectedAccessory, setSelectedAccessory] = useState();

  const [length, setLength] = useState(0);
  const [breadth, setBreadth] = useState(0);
  const [height, setHeight] = useState(0);
  const [consumption, setConsumption] = useState("");

  useEffect(() => {
    if (productType) {
      const sizes = getSizesByType(productType);
      const quantityArray = sizes.map((x) => {
        const newObj = {
          size: x,
          quantity: 0,
        };
        return newObj;
      });
      // console.log(quantityArray)
      setSizeQuantity(quantityArray);
    }
  }, [productType]);

  useEffect(() => {
    if (categoryType) {
      console.log(SkuIds, categoryType);
      const filteredSkuId = SkuIds.filter((x) => x.name === categoryType);
      const sku = parseInt(filteredSkuId[0]?.last_id);
      if (isNaN(sku)) {
        // handle error
        console.log("last sku id not found");
        setSku(filteredSkuId[0].id + "0000");
      } else {
        setSku(filteredSkuId[0].id + String(sku + 1));
      }
    }
  }, [categoryType]);

  useEffect(() => {
    if (productType) {
      const categories = categoryTypes(productType);
      const vendors = vendorTypes(productType);
      setCategoriesArray(categories);
      setVendorsArray(vendors);
    }
  }, [productType]);

  useEffect(() => {
    console.log("product type", productType);
    if (productType === "Shoes" || productType === "Men Shoes") {
      setFabricArr(materialSoleFabricArray);
    } else {
      setFabricArr(materialFabricArray);
    }
  }, [productType]);

  useEffect(() => {
    if (sku && productType) {
      const sizes = getSizesByType(productType);
      const generatedBarcodes = sizes.map((i) => {
        if (i === "Free Size (Fits XS to L)") {
          const barcodeGenerated = "LB" + sku + "FS";
          return barcodeGenerated;
        } else {
          const barcodeGenerated = "LB" + sku + i;
          return barcodeGenerated;
        }
      });
      setBarcodes(generatedBarcodes);
    }
  }, [productType, sku]);

  useEffect(() => {
    if (shopifyResponse.statusCode === 200) {
      const sizes = getSizesByType(productType);
      const imageUrl = shopifyResponse.body.product.image.src;
      const newObj = sizes.map((x) => ({
        barcode: `LB${sku}${x}`,
        url: imageUrl,
      }));
      const newModalObj = newProductData.map((x) => ({
        ...x,
        imageUrl: shopifyResponse.body.product.image.src,
      }));
      dispatch(setImagesinInventory(newObj));
      dispatch(hideLoading());
      setModalData(newModalObj);
    }
  }, [shopifyResponse]);

  useEffect(() => {
    if (sku !== "") {
      const filterSku = stock.filter((x) => x.SKU === sku);
      if (filterSku.length > 0) {
        setErrorResponse(true);
      } else {
        setErrorResponse(false);
      }
    }
  }, [sku]);

  const handleModalClose = () => {
    setProductType("");
    setCategoryType("");
    setSku("");
    setSellingPrice("");
    setCostPrice("");
    setBarcodes([]);
    dispatch(removeS3Images());
    setEditSku(false);
    setVendorsArray([]);
    setVendorType("");

    setShopifyStatusType("");
    setShopifyTitle("");
    setShopifyDescription("");
    setShopifyComparePrice("");
    setTagArray([]);
    setTemplateSuffix("");
    setSizeQuantity([]);
    setMaterialType("");
    setMaterialColor("");
    setMaterialAmount("");
    setMaterialObj([]);
    setOpenAddMaterial(false);
    setIsCombo(false);
    setComboAmount(2);
    setSelectedAccessory(undefined);
    setLength(0);
    setBreadth(0);
    setHeight(0);
    setConsumption("");
  };

  const handleTagAdd = (tag) => {
    const oldArray = tagArray;
    setTagArray([...oldArray, { key: tagArray.length, label: tag }]);
  };

  const handleTagDelete = (chipToDelete) => () => {
    setTagArray((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const handleSubmit = async () => {
    const sizes = getSizesByType(productType);
    // const SKUInfo = SkuIds.filter((x) => x.name === categoryType);
    // const newSkuId = parseInt(SKUInfo[0].last_id) + 1;
    // const skuCategory = SKUInfo[0].id;

    const regex = /^([A-Za-z]+)(\d+)$/;
    const newLastSkuIdArray = sku.match(regex);
    const newSkuId = newLastSkuIdArray[2];
    const skuCategory = newLastSkuIdArray[1];
    const newSkuBody = {
      id: skuCategory,
      sku_id: newSkuId,
    };

    const newProduct = sizes.reduce((acc, i) => {
      const product = {
        company: "LB",
        SKU: sku,
        date: new Date(),
        count: 0,
        category: categoryType,
        type: productType,
        barcode:
          i === "Free Size (Fits XS to L)" ? "LB" + sku + "FS" : "LB" + sku + i,
        vendorType,
        imageUrl: "",
        sellingPrice: parseFloat(sellingPrice).toFixed(2),
        costPrice: parseFloat(costPrice).toFixed(2),
        size: i,
        material: materialObj,
        combo: isCombo,
        comboAmount: isCombo ? comboAmount : undefined,
        accessory: selectedAccessory ? JSON.parse(selectedAccessory) : {},
        length,
        breadth,
        height,
        consumption,
      };
      acc.push(product);
      return acc;
    }, []);
    const shopifySizes = sizes.map((s) => {
      if (s === "FS") {
        return "Free Size (Fits XS to L)";
      } else {
        return s;
      }
    });
    const newShopifyProduct = {
      product: {
        title: shopifyTitle,
        body_html: shopifyDescription,
        vendor: vendorType,
        product_type: productType,
        published_scope: "web",
        status: shopifyStatusType,
        template_suffix: templateSuffix,
        variants: shopifySizes.map((i) => ({
          option1: i,
          price: parseFloat(sellingPrice).toFixed(2),
          sku,
          taxable: false,
          compare_at_price: parseFloat(shopifyComparePrice).toFixed(2),
          inventory_management: "shopify",
          inventory_quantity: sizeQuantity.find((x) => {
            console.log(x);
            if (i === "Free Size (Fits XS to L)") {
              return x.size === "FS";
            } else {
              return x.size === i;
            }
          }).quantity,
        })),
        tags: tagArray.map((x) => x.label),
        options: [
          {
            name: "Size",
            values: shopifySizes,
          },
        ],
        images: shopifyImages,
      },
    };
    // console.log("shopify", newShopifyProduct);
    // console.log("product", newProduct);
    setNewProductData(newProduct);
    dispatch(showLoading());
    dispatch(postNewProducts(newProduct));
    dispatch(addProductToShopify(newShopifyProduct));
    if (!editSku) dispatch(updateLastSkuId(newSkuBody));
  };

  const handleMaterialObj = () => {
    // material object
    // add id
    const newObj = {
      type: materialType,
      color: materialColor,
      amount: materialAmount,
      name: String(materialType) + " " + String(materialColor),
      place: materialPlace,
      materialFormula,
      materialWeight,
      id: findMaterialId(materialType, materialColor),
    };
    setMaterialObj((prev) => [...prev, newObj]);
    setMaterialType("");
    setMaterialColor("");
    setMaterialAmount("");
    setMaterialWeight("");
    setMaterialFormula("");
    setOpenAddMaterial(false);
  };

  const handleNumberInput = (input) => {
    const val = parseInt(input);
    if (isNaN(val)) {
      return 0;
    } else {
      return val;
    }
  };

  return (
    <div>
      {!loading ? (
        <>
          <Button
            variant="contained"
            color="error"
            onClick={() => setSelected("")}
          >
            Back
          </Button>
          <Container>
            <br />
            <br />
            <Paper sx={{ padding: "25px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                  style={{ width: "500px" }}
                  select
                  label="Select Product Type"
                  value={productType}
                  onChange={(e) => setProductType(e.target.value)}
                  variant="outlined"
                  className="select"
                  // fullWidth
                >
                  {productTypes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              {productType && (
                <>
                  <br />
                  <div>
                    <TextField
                      style={{ width: "500px" }}
                      select
                      label="Select Product Category"
                      value={categoryType}
                      onChange={(e) => {
                        setCategoryType(e.target.value);
                      }}
                      variant="outlined"
                      className="select"
                    >
                      {categoriesArray.map((option) => (
                        <MenuItem key={option.value} value={option.label}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <br />
                  <div style={{ display: "flex" }}>
                    <br />
                    <TextField
                      label="SKU"
                      value={sku}
                      disabled
                      // onChange={(e) => setSku(e.target.value)}
                      variant="outlined"
                      className="select"
                    />
                    {editSku ? (
                      <>
                        <TextField
                          style={{ left: "50px" }}
                          label="Enter Custom SKU"
                          // value={sku}
                          onChange={(e) =>
                            setSku(String(e.target.value).toUpperCase())
                          }
                          variant="outlined"
                          className="select"
                        />
                      </>
                    ) : (
                      ""
                    )}
                    <Button
                      style={{ left: "100px" }}
                      variant="contained"
                      color="secondary"
                      onClick={() => setEditSku(true)}
                    >
                      Enter Custom SKU
                    </Button>
                    <Button
                      style={{ left: "120px" }}
                      variant="contained"
                      color="error"
                      onClick={() => {
                        const filteredSkuId = SkuIds.filter(
                          (x) => x.name === categoryType
                        );
                        setSku(filteredSkuId[0].id + filteredSkuId[0].last_id);
                        setEditSku(false);
                      }}
                    >
                      Reset To Origin SKU
                    </Button>
                  </div>
                  <div>
                    {errorResponse ? (
                      <h4 style={{ color: "red" }}>
                        SKU "<b style={{ color: "black" }}>{sku}</b>" Already
                        Exists. Please Enter Another SKU
                      </h4>
                    ) : (
                      ""
                    )}
                  </div>
                  <br />
                  <Stack direction={"row"} sx={{ my: 2 }} gap={2}>
                    <TextField
                      style={{ maxWidth: "400px" }}
                      label="Consumption"
                      value={consumption}
                      onChange={(e) => setConsumption(e.target.value)}
                      variant="outlined"
                      className="select"
                      size="small"
                    />
                    {/* length */}
                    <TextField
                      style={{ maxWidth: "400px" }}
                      label="Length"
                      value={length}
                      onChange={(e) =>
                        setLength(handleNumberInput(e.target.value))
                      }
                      variant="outlined"
                      className="select"
                      size="small"
                    />
                    {/* breadth */}
                    <TextField
                      style={{ maxWidth: "400px" }}
                      label="Breadth"
                      value={breadth}
                      onChange={(e) =>
                        setBreadth(handleNumberInput(e.target.value))
                      }
                      variant="outlined"
                      className="select"
                      size="small"
                    />
                    {/* height */}
                    <TextField
                      style={{ maxWidth: "400px" }}
                      label="Height"
                      value={height}
                      onChange={(e) =>
                        setHeight(handleNumberInput(e.target.value))
                      }
                      variant="outlined"
                      className="select"
                      size="small"
                    />
                  </Stack>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <h4>Add Material Details</h4>
                    <Box style={{ paddingLeft: "10px" }}>
                      <AddIcon
                        onClick={() => setOpenAddMaterial((prev) => !prev)}
                        fontSize="large"
                        style={{
                          color: "white",
                          background: "black",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  </Box>
                  <Collapse in={true}>
                    <Paper sx={{ bgcolor: "whitesmoke" }}>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          padding: "20px",
                          gap: 2,
                        }}
                      >
                        <TextField
                          style={{ width: "250px" }}
                          select
                          label="Product Material"
                          value={materialType}
                          onChange={(e) => setMaterialType(e.target.value)}
                          variant="outlined"
                          className="select"
                          size="small"
                        >
                          {fabricArr.map((option) => (
                            <MenuItem key={option.code} value={option.name}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          style={{ width: "250px" }}
                          select
                          label="Material Color"
                          value={materialColor}
                          onChange={(e) => setMaterialColor(e.target.value)}
                          variant="outlined"
                          className="select"
                          size="small"
                        >
                          {materialColorArray.map((option) => (
                            <MenuItem key={option.code} value={option.name}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        {(productType === "Shoes" ||
                          productType === "Men Shoes") && (
                          <TextField
                            style={{ width: "250px" }}
                            select
                            label="Select Place"
                            value={materialPlace}
                            onChange={(e) => setMaterialPlace(e.target.value)}
                            variant="outlined"
                            className="select"
                            size="small"
                          >
                            {materialSolePlaceArray.map((option) => (
                              <MenuItem key={option.code} value={option.name}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                        <TextField
                          style={{ width: "150px" }}
                          label="Courier Weight"
                          value={materialAmount}
                          onChange={(e) => setMaterialAmount(e.target.value)}
                          variant="outlined"
                          className="select"
                          size="small"
                        />
                        {/* consumption fields */}
                        <TextField
                          style={{ width: "200px" }}
                          size="small"
                          value={materialWeight}
                          onChange={(e) => setMaterialWeight(e.target.value)}
                          label={"material weight"}
                        />
                        <TextField
                          style={{ width: "200px" }}
                          select
                          label="Formula"
                          value={materialFormula}
                          onChange={(e) => setMaterialFormula(e.target.value)}
                          variant="outlined"
                          className="select"
                          size="small"
                        >
                          {[
                            { label: "in rolls", value: "IR" },
                            { label: "in meters", value: "IM" },
                          ].map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleMaterialObj}
                        >
                          Add
                        </Button>
                      </Box>
                    </Paper>
                  </Collapse>
                  {materialObj.length > 0 ? (
                    <>
                      <br />
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">Type</TableCell>
                              <TableCell align="left">Color</TableCell>
                              <TableCell align="left">Amount</TableCell>
                              <TableCell align="left">Name</TableCell>
                              <TableCell align="left">Place</TableCell>
                              <TableCell align="left">Id</TableCell>
                              <TableCell align="left">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {materialObj.map((row, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">{row.type}</TableCell>
                                <TableCell align="left">{row.color}</TableCell>
                                <TableCell align="left">{row.amount}</TableCell>
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="left">{row.place}</TableCell>
                                <TableCell align="left">{row.id}</TableCell>
                                <TableCell align="left">
                                  <DeleteIcon
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => {
                                      setMaterialObj(() => {
                                        const toBeDeleted = materialObj.find(
                                          (x) => x.color === row.color
                                        );
                                        if (toBeDeleted)
                                          return materialObj.filter(
                                            (x) => x !== toBeDeleted
                                          );
                                        else return materialObj;
                                      });
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ) : (
                    ""
                  )}
                  <h4>Upload One or More Images ( .JPG only )</h4>
                  <DragAndDrop
                    sku={sku}
                    setShopifyImages={setShopifyImages}
                    setDisableSubmit={setDisableSubmit}
                  />
                  <br />
                  <TextField
                    style={{ width: "300px" }}
                    select
                    label="Select Accessories"
                    value={selectedAccessory || ""}
                    onChange={(e) => {
                      setSelectedAccessory(e.target.value);
                    }}
                    defaultValue={""}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="select"
                  >
                    {accessories.map((option) => (
                      <MenuItem key={option.id} value={JSON.stringify(option)}>
                        {option.zip}
                      </MenuItem>
                    ))}
                  </TextField>
                  <br />
                  <br />
                  <div style={{ display: "flex" }}>
                    <TextField
                      style={{ width: "300px" }}
                      select
                      label="Select Vendor"
                      value={vendorType}
                      onChange={(e) => {
                        setVendorType(e.target.value);
                      }}
                      variant="outlined"
                      className="select"
                    >
                      {vendorsArray.map((option) => (
                        <MenuItem key={option.value} value={option.label}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      style={{ left: "100px" }}
                      label="Selling Price"
                      value={sellingPrice}
                      onChange={(e) => setSellingPrice(e.target.value)}
                      variant="outlined"
                      className="select"
                    />
                    <TextField
                      style={{ left: "120px" }}
                      label="Cost Price"
                      value={costPrice}
                      onChange={(e) => setCostPrice(e.target.value)}
                      variant="outlined"
                      className="select"
                    />
                  </div>
                  <br />
                  <>
                    <TextField
                      style={{ width: "300px" }}
                      select
                      label="Select Product Status"
                      value={shopifyStatusType}
                      onChange={(e) => {
                        setShopifyStatusType(e.target.value);
                      }}
                      variant="outlined"
                      className="select"
                    >
                      {statusArray.map((option) => (
                        <MenuItem key={option.value} value={option.label}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                    <br />
                    <br />
                    <TextField
                      style={{ width: "520px", background: "white" }}
                      label="Title of Product"
                      value={shopifyTitle}
                      onChange={(e) => setShopifyTitle(e.target.value)}
                      variant="outlined"
                      className="select"
                    />
                    <br />
                    <h3>Product Description</h3>
                    <TextareaAutosize
                      style={{ width: "700px", height: "100px" }}
                      value={shopifyDescription}
                      onChange={(e) => setShopifyDescription(e.target.value)}
                      variant="outlined"
                      className="select"
                    />
                    <br />
                    <br />
                    <TextField
                      style={{ background: "white" }}
                      label="Compare Price"
                      value={shopifyComparePrice}
                      onChange={(e) => setShopifyComparePrice(e.target.value)}
                      variant="outlined"
                      className="select"
                    />
                    <div>
                      <br />
                      <Stack direction={"row"} gap={2}>
                        <FormControlLabel
                          sx={{
                            border: "1px solid black",
                            borderRadius: 2,
                            py: 0.8,
                            px: 2,
                            mx: 0,
                          }}
                          label={
                            <Typography variant="h6" color="textSecondary">
                              Combo ?
                            </Typography>
                          }
                          control={
                            <Checkbox
                              value={isCombo}
                              onChange={(e) => setIsCombo(e.target.checked)}
                            />
                          }
                        />
                        {isCombo && (
                          <TextField
                            type="number"
                            style={{ width: "200px" }}
                            value={comboAmount}
                            onChange={(e) => setComboAmount(e.target.value)}
                            variant="outlined"
                            label="Combo Amount"
                          />
                        )}
                      </Stack>
                      <br />
                      <TextField
                        style={{ width: "300px" }}
                        select
                        label="Add Tags..."
                        onChange={(e) => {
                          handleTagAdd(e.target.value);
                        }}
                        variant="outlined"
                        className="select"
                      >
                        {tagsCategoriesArray.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                      <br />
                      <div style={{ display: "flex" }}>
                        {tagArray.map((i) => (
                          <Chip
                            key={i.key}
                            variant="filled"
                            label={i.label}
                            onDelete={handleTagDelete(i)}
                            style={{ margin: "10px", color: "blue" }}
                          />
                        ))}
                      </div>
                      <br />
                      <TextField
                        style={{ width: "300px" }}
                        select
                        label="Select Template Suffix"
                        value={templateSuffix}
                        onChange={(e) => {
                          setTemplateSuffix(e.target.value);
                        }}
                        variant="outlined"
                        className="select"
                      >
                        {templateSuffixArray.map((option) => (
                          <MenuItem key={option.value} value={option.label}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      <br />
                      <h3>Set Shopify Inventory Stock</h3>
                      <Box style={{ display: "flex", flexWrap: "wrap" }}>
                        {getSizesByType(productType).map((item, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              padding: "10px",
                              paddingLeft: "20px",
                            }}
                          >
                            <h4>{item} : </h4>
                            <TextField
                              key={index}
                              style={{ width: "100px", left: "10px" }}
                              onChange={(e) => {
                                const editedArray = sizeQuantity.map((x) => {
                                  if (x.size === item) {
                                    return {
                                      ...x,
                                      quantity: parseInt(e.target.value),
                                    };
                                  } else {
                                    return x;
                                  }
                                });
                                setSizeQuantity(editedArray);
                              }}
                              variant="outlined"
                              className="select"
                            />
                          </div>
                        ))}
                      </Box>
                    </div>
                  </>

                  <h3>Barcodes Generated : </h3>
                  <div style={{ display: "flex" }}>
                    {barcodes.map((i) => (
                      <Chip
                        key={i}
                        variant="filled"
                        label={i}
                        style={{ margin: "10px", color: "blue" }}
                      />
                    ))}
                  </div>
                  <br />
                  <Button
                    disabled={disableSubmit || errorResponse}
                    variant="contained"
                    color="secondary"
                    onClick={handleSubmit}
                  >
                    ADD TO SHOPIFY & INVENTORY
                  </Button>
                </>
              )}
            </Paper>
          </Container>
        </>
      ) : (
        <ProgressBar />
      )}
      <>
        {modalData.length > 0 && (
          <ProductModal data={modalData} handleModalClose={handleModalClose} />
        )}
      </>
    </div>
  );
};

export default AddProductForm;
